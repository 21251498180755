import React from "react";
import { Component } from "../../components/Component81";
import { ImgWrapper } from "../../components/ImgWrapper81";
import Rec104 from '../../mobImages/imagesMiningBeneficationM/rectangle-104.svg'
import Rec99 from '../../mobImages/imagesMiningBeneficationM/rectangle-99.svg'
import firstBaraban from '../../mobImages/imagesMiningBeneficationM/16400-2300-2-73103-0-1.png'
import secondBaraban from '../../mobImages/imagesMiningBeneficationM/image-6487327-1-1.png'
import opora from '../../mobImages/imagesMiningBeneficationM/viber-2023-02-16-12-49-32-041-1.png'
import val from '../../mobImages/imagesMiningBeneficationM/0-02-05-4b553acd14b9bf197695f62c341b0e4befadc45196fe364764730cbf.png'
import greyfer from '../../mobImages/imagesMiningBeneficationM/1000x800-image2-1.png'
import threeBaraban from '../../mobImages/imagesMiningBeneficationM/photo-2023-04-22-21-28-33-1.png'
import pitatel from '../../mobImages/imagesMiningBeneficationM/1-2.png'
import vtulkaCilindr from '../../mobImages/imagesMiningBeneficationM/1000x800-vtulka-cilindricheskaya-2-1.png'
import Line17 from '../../mobImages/imagesMiningBeneficationM/line-17.svg'
import OneSvg from '../../mobImages/imagesMiningBeneficationM/1.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./miningBeneficationM.css";

export const MiningBeneficationM = () => {
  return (
    <div className="beneficationM">
      <div className="div">
        <div className="overlap">
          <img className="rectangle" alt="Rectangle" src={Rec104} />
        <a href='/contacts'><div className="text-wrapper-2">Контакти</div></a>  
        <a href='/services'><div className="text-wrapper-3">Послуги</div></a>
        <a href='/'><div className="text-wrapper-4">Продукція</div></a>  
        <a href='/about'> <div className="text-wrapper-5">Про компанію</div></a> 
          {/* <Component GR="/img/gr.png" className="component-1" /> */}
          <a href='/'><img className="component-1" src={LogoDark}></img></a>
          <div className="text-wrapper-6">ПРОДУКЦІЯ</div>
          <div className="rectangle-2" />
          <div className="text-wrapper-7">
            <br />
            Гірничо-збагачувальне обладнання
          </div>
        </div>
        <div className="text-wrapper-8">
          ТОВ ЛВК
          ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ПРОДУКЦІЯ
        </div>
        <p className="element">
          Значну частину виробленої продукції займає гірничо-збагачувальне обладнання. В основному це виготовлення нових
          вузлів або деталей для стрічкових конвеєрів та інших (з шириною стрічки від 400 мм до 2500 мм), а також інших
          передавальних і живильних пристроїв.
          <br />
          <br />
          Нижче представлений загальний ілюстративний список з основною виробляємою нами продукцією категорії
          ГІРНИЧО-ЗБАГАЧУВАЛЬНЕ ОБЛАДНАННЯ.
        </p>
        <div className="overlap-2">
          <div className="rectangle-3" />
          <img className="img" alt="Element" src={firstBaraban} />
          <div className="rectangle-4" />
          <div className="text-wrapper-9">+різноманітних модифікацій</div>
          <div className="text-wrapper-10">Барабани приводні</div>
          <div className="view">
            <div className="div-wrapper">
              <div className="text-wrapper-11">+ Каталог барабанів</div>
            </div>
          </div>
          <p className="p">
            Основні характеристики
            <br />
            (детальніше в каталозі барабанів):
            <br />
            <br />
            Вага: від 250 до 16000 кг
            <br />
            Ширина стрічки: від 400 до 2500 мм
            <br />
            Підшипниковий вузол: імпортного <br />
            або власного виробництва
            <br />
            Підшипники: SKF, FAG, Timken, інші
            <br />
            Футеровка: методом горячої вулканізації
            <br />
            <br />
            Термін виготовлення: 60-90 днів
          </p>
        </div>
        <div className="overlap-3">
          <div className="rectangle-3" />
          <img className="image" alt="Image" src={secondBaraban} />
          <div className="rectangle-4" />
          <div className="text-wrapper-9">+різноманітних комплектацій</div>
          <div className="text-wrapper-10">Барабани не приводні</div>
          <div className="overlap-wrapper">
            <div className="div-wrapper">
              <div className="text-wrapper-11">+ Каталог барабанів</div>
            </div>
          </div>
          <p className="element-2">
            Основні характеристики
            <br />
            (детальніше в каталозі барабанів):
            <br />
            <br />
            Вага: від 250 до 16000 кг
            <br />
            Ширина стрічки: від 400 до 2500 мм
            <br />
            Підшипниковий вузол: імпортного <br />
            або власного виробництва
            <br />
            Підшипники: SKF, FAG, Timken, інші
            <br />
            Футеровка: методом горячої вулканізації
            <br />
            <br />
            Термін виготовлення: 60-90 днів
          </p>
        </div>
        <div className="overlap-4">
          <div className="overlap-5">
            <div className="rectangle-3" />
            <img className="viber" alt="Viber" src={opora} />
            <div className="rectangle-4" />
            <div className="text-wrapper-12">+ різноманітних модифікацій</div>
            <div className="text-wrapper-13">Храпові останови</div>
          </div>
          <p className="element-3">
            Основні характеристики:
            <br />
            Діаметр коронки: від 1200 мм до 1600 мм
            <br />
            Розміри: від 2025 х 1720 х 320 мм <br />
            до 2605 х 3080 х 500 мм
            <br />
            Вага: від 845 кг до 2900 кг
            <br />
            <br />
            Термін виготовлення: від 90 днів <br />
            до 150 днів
          </p>
        </div>
        <div className="overlap-6">
          <div className="overlap-5">
            <div className="rectangle-3" />
            <img
              className="element-4"
              alt="Element"
              src={val}
            />
            <div className="rectangle-4" />
            <div className="text-wrapper-10">Вали ванни мечової</div>
            <p className="text-wrapper-14">+ вали ванни комбінованої з колесом ковшовим</p>
          </div>
          <p className="element-5">
            Основні характеристики:
            <br />
            Вага: не більше 11 200 кг
            <br />
            Довжина: 6389,5 мм
            <br />
            Ширина: 3200 мм
            <br />
            Висота: 3200 мм
            <br />
            Особливості: <br />
            Елементи підвищеної абразивності - <br />
            матеріал Ст110Г13Л
            <br />
            <br />
            Термін виготовлення: 180 днів
          </p>
        </div>
        <div className="overlap-7">
          <div className="overlap-5">
            <div className="rectangle-3" />
            <img className="element-x-image" alt="Element" src={greyfer} />
            <div className="rectangle-4" />
            <div className="text-wrapper-15">імпортного виробництва</div>
            <div className="text-wrapper-16">Грохот та вібратори</div>
          </div>
          <p className="element-6">
            Основні характеристики екрану та вібратора:
            <br />
            Опис екрана: 5650 х 1820 х 2000 мм, <br />
            розмір вібратора: 900 х 940 х 630 мм
            <br />
            Вага екрану: 4456 кг, <br />
            вага вібратору: 1040 кг
            <br />
            <br />
            Грохот: 5650 x 1800 мм
            <br />
            Амплітуда вібрації: 9 мм
            <br />
            Частота моргання: 1010 1/min
            <br />
            <br />
            Термін виготовлення: 180 днів
            <br />
            після повного проектування
          </p>
        </div>
        <div className="overlap-8">
          <div className="overlap-9">
            <div className="rectangle-3" />
            <img className="photo" alt="Photo" src={threeBaraban} />
            <div className="rectangle-4" />
            <div className="text-wrapper-9">+різноманітних комплектацій</div>
            <div className="text-wrapper-10">Барабани натяжні</div>
            <div className="overlap-group-wrapper">
              <div className="div-wrapper">
                <div className="text-wrapper-17">+ Каталог барабанів</div>
              </div>
            </div>
          </div>
          <p className="element-3">
            Основні характеристики
            <br />
            (детальніше в каталозі барабанів):
            <br />
            <br />
            Вага: від 250 до 16000 кг
            <br />
            Ширина стрічки: від 400 до 2500 мм
            <br />
            Підшипниковий вузол: імпортного <br />
            або власного виробництва
            <br />
            Підшипники: SKF, FAG, Timken, інші
            <br />
            Футеровка: методом горячої вулканізації
            <br />
            <br />
            Термін виготовлення: 60-90 днів
          </p>
        </div>
        <div className="overlap-10">
          <div className="overlap-5">
            <div className="rectangle-3" />
            <img className="element-7" alt="Element" src={pitatel} />
            <div className="rectangle-4" />
            <div className="text-wrapper-15">+інших типорозмірів</div>
            <div className="text-wrapper-18">Живильники ПП2-15-30</div>
          </div>
          <p className="element-8">
            Основні характеристики:
            <br />
            Вага: 17560 кг,
            <br />
            Ширина полотна: 1500 мм,
            <br />
            Продуктивність: 270/400/540/800 м.куб на год,
            <br />
            Довжина живильника з приводом: 4740 мм,
            <br />
            Висота живильника <br />
            (до стрічки живильника): 1530 мм
            <br />
            Редуктор: ЦТ-1450,
            <br />
            Електродвигун: АИР 200 М4, 37 кВт.
            <br />
            <br />
            Термін виготовлення: 180 днів
          </p>
        </div>
        <div className="overlap-11">
          <div className="overlap-12">
            <div className="rectangle-3" />
            <img className="element-vtulka" alt="Element vtulka" src={vtulkaCilindr} />
            <div className="rectangle-4" />
            <p className="text-wrapper-19">Втулка циліндрична для КМД-2000: КСД-2200</p>
            <div className="text-wrapper-20">+інших типорозмірів</div>
          </div>
          <p className="element-8">
            Основні характеристики:
            <br />
            Вага: 17560 кг,
            <br />
            Ширина полотна: 1500 мм,
            <br />
            Продуктивність: 270/400/540/800 м.куб на год,
            <br />
            Довжина живильника з приводом: 4740 мм,
            <br />
            Висота живильника <br />
            (до стрічки живильника): 1530 мм
            <br />
            Редуктор: ЦТ-1450,
            <br />
            Електродвигун: АИР 200 М4, 37 кВт.
            <br />
            <br />
            Термін виготовлення: 180 днів
          </p>
        </div>
        <p className="text-wrapper-21">
          Ми також можемо виготовити наступне обладнання для механізмів: <br />
          х&nbsp;&nbsp; приводний, натяжний і дефлекторний барабани;
          <br />
          х&nbsp;&nbsp; верхній і нижній ролики;
          <br />
          х&nbsp;&nbsp; сідла барабанні та роликові;
          <br />
          х&nbsp;&nbsp; машини для загортання та розпакування;
          <br />
          х&nbsp;&nbsp; машина для намотування та розмотування стрічки;
          <br />
          х&nbsp;&nbsp; муфти диференційні;
          <br />
          х&nbsp;&nbsp; затискний механізм та інші.
          <br />
          Ми також можемо виготовляти та ремонтувати деталі та вузли кранів, самоскидів, вантажівок та ін.
        </p>
        <div className="overlap-13">
          <img className="rectangle-5" alt="Rectangle" src={Rec99}/>
          <p className="element-9">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-6" />
          <div className="text-wrapper-22">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-23">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="span">Г</span>
            <span className="text-wrapper-24">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-25">promgrupp.pc@gmail.com</div>
          <p className="element-10">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line" alt="Line" src={Line17} />
          <ImgWrapper className="component-169" img={OneSvg} />
        </div>
      </div>
    </div>
  );
};
