import React from "react";
import { Component } from "../../components/Component85";
import { ImgWrapper } from "../../components/ImgWrapper85";
import Rec13 from '../../mobImages/imagesPlasmaCutting/rectangle-13.svg'
import Plazmenaya from '../../mobImages/imagesPlasmaCutting/plazmennaya-rezka-metalla-123-1.png'
import Rec99 from '../../mobImages/imagesPlasmaCutting/rectangle-99.svg'
import Line17 from '../../mobImages/imagesPlasmaCutting/line-17.svg'
import One from '../../mobImages/imagesPlasmaCutting/1.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./plasmaCuttingM.css";

export const PlasmaCuttingM = () => {
  return (
    <div className="plasmam">
      <div className="div">
        <div className="overlap">
          <img className="rectangle" alt="Rectangle" src={Rec13} />
          <div className="rectangle-2" />
        <a href='/contacts'><div className="text-wrapper-2">Контакти</div></a> 
        <a href="/services"><div className="text-wrapper-3">Послуги</div></a> 
        <a href='/'></a><div className="text-wrapper-4">Продукція</div>
        <a href='/about'></a> <div className="text-wrapper-5">Про компанію</div>
          {/* <Component className="component-1" /> */}
          <a href='/'><img style={{width:85, height:35, marginTop:60, marginLeft:20}} className="component-1" src={LogoDark}></img></a>
          <div className="text-wrapper-6">ПОСЛУГИ</div>
          <div className="text-wrapper-7">Плазмове різання</div>
        </div>
        <div className="text-wrapper-8">
          ТОВ ЛВК
          ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ПОСЛУГИ
        </div>
        <p className="element">
          ТОВ «ЛВК «ПРОМГРУП» пропонує послуги з плазмового різання металу на верстаті плазмового різання з ЧПК, як для
          серійного виробництва , так і для одиничних виробів.
          <br />
          Лазерне різання – це економічно ефективний метод виробництва деталей из листового металу. Без необхідності в
          оснастці він забезпечує швидке виробництво деталей відповідно до креслень з високою точністю (до 0,5 мм).
        </p>
        <div className="overlap-2">
          <p className="p">
            плазмове різання вуглецевої сталі товщиною від 0,5 до 32 мм;
            <br />
            плазмове різання нержавіючої сталі товщиною від 0,5 до 32 мм;
            <br />
            газову різку вуглецевої сталі товщиною від 32 мм до 50 мм.
            <br />
            Максимальний розмір листа, що оброблюється – 2000х6000 мм.
          </p>
          <div className="text-wrapper-9">Ми виконуємо:</div>
        </div>
        <img className="plazmennaya-rezka" alt="Plazmennaya rezka" src={Plazmenaya} />
        <div className="text-wrapper-10">Прайс на порізку</div>
        <p className="element-2">
          <span className="span">Орієнтована вартість плазмового різання для </span>
          <span className="text-wrapper-11">вуглецевої сталі</span>
          <span className="span">
            {" "}
            на верстаті з ЧПК вказана в таблиці. Ціни вказані без ПДВ та є дійсними на 1 квартал 2023р. <br />
            Для точного прорахування вартості Ваших виробів просимо зв’язатись з нами.
            <br />
            <br />
            Точність деталей після порізки по 14 квалітету без врахування конусності різу.
            <br />
          </span>
          <span className="text-wrapper-12">Мінімальна вартість замовлення - 500 грн. без ПДВ.</span>
        </p>
        <div className="overlap-3">
          <div className="view">
            <div className="div-wrapper">
              <div className="text-wrapper-13">Метод різання</div>
            </div>
            <div className="overlap-4">
              <div className="text-wrapper-14">Плазмова</div>
            </div>
            <div className="overlap-5">
              <div className="text-wrapper-15">Газополум&#39;яна</div>
            </div>
            <div className="overlap-6">
              <div className="text-wrapper-16">
                Товщина
                <br />
                листа, мм
              </div>
            </div>
            <div className="rectangle-3" />
            <div className="overlap-7">
              <div className="text-wrapper-17">
                Вартість пробивки,
                <br />
                грн. за п/м
              </div>
            </div>
            <div className="rectangle-4" />
            <div className="rectangle-5" />
            <div className="rectangle-6" />
            <div className="rectangle-7" />
            <div className="rectangle-8" />
            <div className="rectangle-9" />
            <div className="rectangle-10" />
            <div className="rectangle-11" />
            <div className="rectangle-12" />
            <div className="rectangle-13" />
            <div className="rectangle-14" />
            <div className="rectangle-15" />
            <div className="rectangle-16" />
            <div className="rectangle-17" />
            <div className="rectangle-18" />
            <div className="rectangle-19" />
            <div className="rectangle-20" />
            <div className="rectangle-21" />
            <div className="rectangle-22" />
            <div className="rectangle-23" />
            <div className="rectangle-24" />
            <div className="rectangle-25" />
            <div className="rectangle-26" />
            <div className="rectangle-27" />
            <div className="rectangle-28" />
            <div className="rectangle-29" />
            <div className="rectangle-30" />
            <div className="rectangle-31" />
            <div className="rectangle-32" />
            <div className="rectangle-33" />
            <div className="rectangle-34" />
            <div className="rectangle-35" />
            <div className="rectangle-36" />
            <div className="rectangle-37" />
            <div className="rectangle-38" />
            <div className="rectangle-39" />
            <div className="rectangle-40" />
            <div className="rectangle-41" />
            <div className="rectangle-42" />
            <div className="rectangle-43" />
            <div className="rectangle-44" />
            <div className="rectangle-45" />
            <div className="rectangle-46" />
            <div className="rectangle-47" />
            <div className="rectangle-48" />
            <div className="rectangle-49" />
            <div className="rectangle-50" />
            <div className="rectangle-51" />
            <div className="rectangle-52" />
            <div className="rectangle-53" />
            <div className="rectangle-54" />
            <div className="rectangle-55" />
            <div className="rectangle-56" />
            <div className="rectangle-57" />
            <div className="rectangle-58" />
            <div className="rectangle-59" />
            <div className="rectangle-60" />
            <div className="rectangle-61" />
            <div className="rectangle-62" />
            <div className="rectangle-63" />
          </div>
          <div className="text-wrapper-18">
            Вартість порізки,
            <br />
            грн. за п/м
          </div>
          <div className="text-wrapper-19">1</div>
          <div className="text-wrapper-20">2</div>
          <div className="text-wrapper-21">3</div>
          <div className="text-wrapper-22">4</div>
          <div className="text-wrapper-23">5</div>
          <div className="text-wrapper-24">6</div>
          <div className="text-wrapper-25">8</div>
          <div className="text-wrapper-26">10</div>
          <div className="text-wrapper-27">12</div>
          <div className="text-wrapper-28">14</div>
          <div className="text-wrapper-29">16</div>
          <div className="text-wrapper-30">18</div>
          <div className="text-wrapper-31">20</div>
          <div className="text-wrapper-32">22</div>
          <div className="text-wrapper-33">25</div>
          <div className="text-wrapper-34">30</div>
          <div className="text-wrapper-35">32</div>
          <div className="text-wrapper-36">36</div>
          <div className="text-wrapper-37">40</div>
          <div className="text-wrapper-38">50</div>
          <div className="text-wrapper-39">12,00</div>
          <div className="text-wrapper-40">13,00</div>
          <div className="text-wrapper-41">14,00</div>
          <div className="text-wrapper-42">15,00</div>
          <div className="text-wrapper-43">16,00</div>
          <div className="text-wrapper-44">18,00</div>
          <div className="text-wrapper-45">20,00</div>
          <div className="text-wrapper-46">23,00</div>
          <div className="text-wrapper-47">27,00</div>
          <div className="text-wrapper-48">30,00</div>
          <div className="text-wrapper-49">34,00</div>
          <div className="text-wrapper-50">38,00</div>
          <div className="text-wrapper-51">45,00</div>
          <div className="text-wrapper-52">72,00</div>
          <div className="text-wrapper-53">85,00</div>
          <div className="text-wrapper-54">100,00</div>
          <div className="text-wrapper-55">105,00</div>
          <div className="text-wrapper-56">120,00</div>
          <div className="text-wrapper-57">135,00</div>
          <div className="text-wrapper-58">165,00</div>
          <div className="text-wrapper-59">1,50</div>
          <div className="text-wrapper-60">2,00</div>
          <div className="text-wrapper-61">2,00</div>
          <div className="text-wrapper-62">2,50</div>
          <div className="text-wrapper-63">3,00</div>
          <div className="text-wrapper-64">3,50</div>
          <div className="text-wrapper-65">4,00</div>
          <div className="text-wrapper-66">4,00</div>
          <div className="text-wrapper-67">6,00</div>
          <div className="text-wrapper-68">8,00</div>
          <div className="text-wrapper-69">10,00</div>
          <div className="text-wrapper-70">12,00</div>
          <div className="text-wrapper-71">15,00</div>
          <div className="text-wrapper-72">30,00</div>
          <div className="text-wrapper-73">30,95</div>
          <div className="text-wrapper-74">37,85</div>
          <div className="text-wrapper-75">49,40</div>
          <div className="text-wrapper-76">62,40</div>
          <div className="text-wrapper-77">90,90</div>
          <div className="text-wrapper-78">113,10</div>
        </div>
        <div className="text-wrapper-79">ВАЖЛИВО!</div>
        <div className="promgrupp-pc-gmail-wrapper">
          <p className="promgrupp-pc-gmail">
            Вартість різання нержавіючої сталі, а також газової різки вираховується індивідуально по заявці, яку ви
            можете відправити нам на електронну адресу 
            <br />
            promgrupp.pc@gmail.com.
          </p>
        </div>
        <div className="overlap-8">
          <img className="img" alt="Rectangle" src={Rec99} />
          <p className="element-3">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-64" />
          <div className="text-wrapper-80">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-81">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="text-wrapper-82">Г</span>
            <span className="text-wrapper-83">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-84">promgrupp.pc@gmail.com</div>
          <p className="element-4">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line" alt="Line" src={Line17} />
          <ImgWrapper className="image" img={One} />
        </div>
      </div>
    </div>
  );
};
