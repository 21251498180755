/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { ImgWrapper } from "../ImgWrapper";
import Logo from '../../images/1.svg'
import LogoLight from '../../images/image.svg'
import "./style.css";

export const Component335 = ({ className }) => {
  return (
    <div className={`component-335 ${className}`}>
     <a href="/"> <ImgWrapper className="component-334" img={LogoLight} /> </a> 
    </div>
  );
};
