import React from "react";
import { ImgWrapper } from "../../components/ImgWrapper86";
import Rec87 from '../../mobImages/imagesServicesM/rectangle-87.png'
import Rec86 from '../../mobImages/imagesServicesM/rectangle-86.png'
import line66 from '../../mobImages/imagesServicesM/line-66.svg'
import Rec99 from '../../mobImages/imagesServicesM/rectangle-99.svg'
import line17 from '../../mobImages/imagesServicesM/line-17.svg'
import img2 from '../../mobImages/imagesServicesM/image.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./servicesM.css";

export const ServicesM = () => {
  return (
    <div className="servi">
      <div className="div">
        <div className="overlap">
          <div className="overlap-group">
            {/* <div className="text-wrapper">LLC FPC</div>
            <div className="UP">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; UP</div>
            <div className="m">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; M</div>
            <img className="PR" alt="Pr" src="PR.png" />
            <img className="GR" alt="Gr" src="GR.png" />
            <img className="mask-group" alt="Mask group" src="mask-group.png" /> */}

            <a href=''><img style={{width:85,height:35, marginTop:20}} src={Logo}></img></a>
            <a href='/about'><div className="text-wrapper-2">Про компанію</div></a>
            <a href="/"><div className="text-wrapper-3">Продукція</div></a>
            <div className="rectangle" />
            <img className="img" alt="Rectangle" src={Rec87} />
            <div className="text-wrapper-4">Послуги</div>
            <img className="rectangle-2" alt="Rectangle" src={Rec86} />
            <div className="rectangle-3" />
            <img className="line" alt="Line" src={line66} />
            <div className="text-wrapper-5">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослугиВиготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
          </div>
          <a href="/services"> <div className="text-wrapper-6">Послуги</div></a>
          <a href="/contacts"><div className="text-wrapper-7">Контакти</div></a>
        </div>
        <p className="p">Ми знаємо про обробку металів все.</p>
        <p className="text-wrapper-8">
          ТОВ ЛВК ПРОМГРУП надає широкий спектр послуг - від стадії проектування до етапу монтажу, а також виконує
          послуги, такі як: механообробка, плазмове різання, шліфувальна обробка та термообробка, в тому числі СВЧ.{" "}
          <br />
          <br />
          Дізнайтесь більше, перейшовши по активним посиланням нижче.
        </p>
        <div className="text-wrapper-9">
          ___
          <br />
          Виготовлення <br />
          та ремонт
        </div>
        <div className="text-wrapper-10">
          ___
          <br />
          Проектування
          <br />
          та розрахунки
        </div>
        <div className="text-wrapper-11">
          ___
          <br />
          Плазмове
          <br />
          різання
        </div>
        <p className="text-wrapper-12">
          Однією з основних спеціалізацій нашої компанії є виготовлення або ремонт різноманітних вузлів, машин або
          окремих частин нестандартного обладнання.
        </p>
        <p className="text-wrapper-13">
          Висококваліфіковані спеціалісти ТОВ ЛВК ПРОМГРУП мають великий практичний досвід та володіють сучасними
          методиками і новітніми програмними продуктами.
        </p>
        <p className="text-wrapper-14">
          Застосовуємо сучасний технологічний процес металообробки, при якому в якості різця використовується потік
          плазми.
        </p>
        <div className="overlap-group-wrapper">
          <div className="div-wrapper">
          <a href="/manufacturing"> <div className="text-wrapper-15">Детальніше</div></a> 
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="div-wrapper">
           <a href='/mechanical'> <div className="text-wrapper-15">Детальніше</div></a>
          </div>
        </div>
        <div className="overlap-wrapper-2">
          <div className="div-wrapper">
          <a href="/heatreatment"><div className="text-wrapper-15">Детальніше</div></a>  
          </div>
        </div>
        <div className="overlap-wrapper-3">
          <div className="div-wrapper">
           <a href="/gritreatment"><div className="text-wrapper-15">Детальніше</div></a> 
          </div>
        </div>
        <div className="overlap-wrapper-4">
          <div className="div-wrapper">
           <a href='/design'><div className="text-wrapper-15">Детальніше</div></a> 
          </div>
        </div>
        <div className="overlap-wrapper-5">
          <div className="div-wrapper">
          <a href="/plasma"><div className="text-wrapper-15">Детальніше</div></a>  
          </div>
        </div>
        <div className="text-wrapper-16">
          ___
          <br />
          Механічна
          <br />
          обробка
        </div>
        <div className="text-wrapper-17">
          ___
          <br />
          Термообробка
          <br />
          та СВЧ
        </div>
        <div className="text-wrapper-18">
          ___
          <br />
          Шліфувальна
          <br />
          обробка
        </div>
        <p className="text-wrapper-19">
          Ми готові прийняти замовлення на різні види обробки. Маса деталей для обробки - до 20 тонн з точністю 6-7
          квалітету.
        </p>
        <p className="text-wrapper-20">
          Виконуємо на замовлення основні види термообробки - відпуск, відпал, гартування, нормалізація, цементація, а
          також виконуємо СВЧ деталей.
        </p>
        <p className="text-wrapper-21">
          Кругло-шліфувальна та плоско-шліфувальна обробка для деталей як серійного, так і одиничного виробництва.
        </p>
        <div className="overlap-2">
          <img className="rectangle-4" alt="Rectangle" src={Rec99} />
          <p className="element">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-5" />
          <div className="text-wrapper-22">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-23">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="span">Г</span>
            <span className="text-wrapper-24">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-25">promgrupp.pc@gmail.com</div>
          <p className="element-2">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line-2" alt="Line" src={line17} />
          <ImgWrapper className="image" img={img2} />
        </div>
      </div>
    </div>
  );
};
