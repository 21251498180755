/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ImgWrapper } from "../ImgWrapper";
import "./style.css";

export const DivWrapper = ({ className, imgWrapperImg = "/img/1.svg" }) => {
  return (
    <div className={`wrap ${className}`}>
      <ImgWrapper className="component-181" img={imgWrapperImg} />
    </div>
  );
};

DivWrapper.propTypes = {
  imgWrapperImg: PropTypes.string,
};
