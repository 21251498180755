import React from "react";
import { ImgWrapper } from "../../components/ImgWrapper82";
import Line66 from '../../mobImages/imagesSertificationM/mask-group.png'
import mask from '../../mobImages/imagesSertificationM/mask-group.png'
import iso from '../../mobImages/imagesSertificationM/iso-2.png'
import iso1 from '../../mobImages/imagesSertificationM/iso-1.png'
import image14 from '../../mobImages/imagesSertificationM/image-14.png'
import image15 from '../../mobImages/imagesSertificationM/image-15.png'
import image16 from '../../mobImages/imagesSertificationM/image-16.png'
import image17 from '../../mobImages/imagesSertificationM/image-17.png'
import Rec99 from '../../mobImages/imagesSertificationM/rectangle-99.svg'
import Line17 from '../../mobImages/imagesSertificationM/line-17.svg'
import One from '../../mobImages/imagesSertificationM/1.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./sertificationM.css";

export const SertificationM = () => {
  return (
    <div className="serviM">
      <div className="div">
        <div className="overlap">
          <div className="overlap-group">
            {/* <div className="text-wrapper">LLC FPC</div>
            <div className="UP">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; UP</div>
            <div className="m">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; M</div>
            <img className="PR" alt="Pr" src="/img/pr.png" />
            <img className="GR" alt="Gr" src="/img/gr.png" />
            <img className="mask-group" alt="Mask group" src={mask} /> */}
            <a href="/"><img className="mask-group" src={Logo}></img></a>
           <a href="/about"><div className="text-wrapper-2">Про компанію</div></a> 
           <a href='/'><div className="text-wrapper-3">Продукція</div></a> 
            <div className="rectangle" />
            <img className="line" alt="Line" src={Line66}/>
            <div className="text-wrapper-4">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослугиВиготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
            <img className="iso" alt="Iso" src={iso} />
            <div className="rectangle-2" />
            <div className="text-wrapper-5">
              ТОВ ЛВК
              ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ЯКІСТЬ
            </div>
            <div className="text-wrapper-6">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
            <div className="rectangle-3" />
            <img className="img" alt="Iso" src={iso1} />
            <div className="text-wrapper-7">Сертифікація</div>
          </div>
        <a href="/services"></a>  <div className="text-wrapper-8">Послуги</div>
        <a href="/contacts"></a>  <div className="text-wrapper-9">Контакти</div>
        </div>
        <p className="p">Якість наших виробів перевірена досвідом.</p>
        <p className="ISO">
          Замовляючи нашу продукцію, клієнти хочуть бути впевнені, що вона відповідає всім встановленим стандартам
          якості. Тому наша компанія завжди приділяє велику увагу якості всієї продукції, що випускається. Один з
          показників нашої турботи про клієнтів - це високий рівень нашої продукції, який забезпечується діючою на
          підприємстві системою управління якістю. Ця система відповідає вимогам міжнародного стандарту ISO 9001.
          <br />
          <br />
          На підтвердження високої якості на кожній виготовленій або відремонтованій компанією продукції є клеймо
          відділу технічного контролю. Ми надаємо сертифікат якості на всю продукцію нашої компанії, зразок такої
          довідки наведено нижче.
          <br />
          <br />
          Також ми надаємо гарантію безвідмовної роботи всієї продукції на термін від 1 до 36 місяців (в залежності від
          виду товару, умов виробництва та інших факторів). При необхідності наша компанія може надати послугу
          післягарантійного обслуговування, відновлення та ремонту виготовленої продукції.
        </p>
        <div className="overlap-group-2">
          <div className="rectangle-4" />
          <img className="image" alt="Image" src={image14} />
          <img className="image-2" alt="Image" src={image15} />
        </div>
        <div className="overlap-2">
          <div className="rectangle-5" />
          <img className="image-3" alt="Image" src={image16} />
          <img className="image-4" alt="Image" src={image17} />
        </div>
        <div className="overlap-3">
          <img className="rectangle-6" alt="Rectangle" src={Rec99} />
          <p className="element">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-7" />
          <div className="text-wrapper-10">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-11">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="span">Г</span>
            <span className="text-wrapper-12">
              оловна
              <br />
              <br />
            <a></a>  Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-13">promgrupp.pc@gmail.com</div>
          <p className="element-2">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line-2" alt="Line" src={Line17} />
          <ImgWrapper className="component-70" img={One}/>
        </div>
      </div>
    </div>
  );
};
