import React from 'react'
import Rectangle13 from '../imagesMining/rectangle-13.svg'
import LogoLight from '../imagesMining/3.svg'
import LogoDark from '../imagesMining/2.svg'
import Vagon from '../imagesMining/1-6-3-2.png'
import Vagon2 from '../imagesMining/4-5-1.png'
import Square from '../imagesMining/rectangle-91.svg'
import VPSH18 from '../imagesMining/photo-2023-02-19-21-05-34-2.png'
import Oprokid from '../imagesMining/1-0-600-1.png'
import OprokidSec from '../imagesMining/photo-2021-11-05-13-57-06-3.png'
import Bur from '../imagesMining/image-19.png'
import Machine from '../imagesMining/image-20.png'
import MachineNK1 from '../imagesMining/image-21.png'
import PKSH from '../imagesMining/image-22.png'
import VPKN7B from '../imagesMining/img-1430-1.png'
import Line15 from '../imagesMining/line-15.svg'
import Line16 from '../imagesMining/line-16.svg'

import { DivWrapper } from "../components/DivWrapper2";
import { ImgWrapper } from "../components/ImgWrapper2";
import "./miningEq.css";
import Footer from './Footer'
import {Header} from './Header/Header'
import { useTranslation } from 'react-i18next'
const MiningEquipment = () =>{
  const {t} = useTranslation()
    return(

    <div className="div-wrapper-screen">
      <div className="div">
        <div className="div-2">
          <div className="div-2">
            <div className="overlap-group">
              <img className="rectangle" alt="Rectangle" src={Rectangle13} />
              <div className="text-wrapper-2">
                <br />
                {t("MINING")} {t("EQUIPMENT")}
              </div>
              <div className="text-wrapper-3">{t("PRODUCTSUPPER")}</div>
              <div className="rectangle-2" />
              <div className="text-wrapper-4">
                {/* ТОВ ЛВК
                ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ПРОДУКЦІЯ */}
                {t("LLC FPC PROMGROUP PRODUCTS")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("PRODUCTSUPPER")}
              </div>
            </div>
            <p className="p">
              {/* Серед основних продуктів ТОВ ЛВК ПРОМГРУП — гірничошахтне обладнання, незамінне в багатьох галузях
              промисловості. Наш продукт зарекомендував себе як надійний, високопродуктивний і простий у використанні. З
              його допомогою можна забезпечити дуже високий показник продуктивності. */}
              {t("Among the main")}
              <br />
              <br />
              {/* Нижче представлений загальний ілюстративний список з основною виробляємою нами продукцією категорії
              ГІРНИЧО-ШАХТНЕ ОБЛАДНАННЯ. */}
              {t("Below is a general illustrative list")}
            </p>
          </div>
         <a href="/"> <DivWrapper className="view" imgWrapperImg={LogoLight} /> </a> 
          {/* <img className="image" alt="Image" src="/img/2x.png" /> */}
          <Header cls='wrp2' divClassName='light'/>
        </div>
        <div className="element">
          <div className="overlap">
            <div className="overlap-2">
              <div className="rectangle-3" />
              <img className="img" alt="Element" src={Vagon} />
              <div className="rectangle-4" />
              <p className="div-3">
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}</span>
              </p>
              <p className="element-2">
                <span className="text-wrapper-6">
                  {/* Вагонетка ВГ - 1,0, 1,6 -3,3  */}
                  {t("Mining Car ВГ-1,0")}
                  <br />
                </span>
                <span className="text-wrapper-7">+ {t("repair kits for them in various configurations")}</span>
              </p>
            </div>
            <p className="element-3">
              {/* Основні характеристики: */}
              {t("Main specifications vg-1.0")}
              <br />
              {/* Вага: 620 - 1270 кг */}
              {t("Masa: 620 - 1270gk")}
              <br />
              {/* Максимальна несуча здатність: 1500-6000 кг */}
              {t("Maximum nesy zdatnist")}
              <br />
              {/* Колія: 600-900 мм */}
              {t("Kolia 600-900mm")}
              <br />
              {/* Довжина: 1500-3410 мм */}
              {t("Length: 1500-3410 мм")}
              <br />
              {/* Ширина: 860-1320 мм */}
              {t("Ширина: 860-1320 мм")}
              <br />
              <br />
              {/* Термін виготовлення: 90 днів */}
              {t("Manufacturing lead time: 90 days")}
            </p>
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-3">
            <div className="overlap-2">
              <div className="rectangle-3" />
              <img className="img" alt="Element" src={Vagon2} />
              <div className="rectangle-5" />
              <p className="element-4">
                <span className="text-wrapper-6">
                  {/* Вагонетка ВГ-4,5 */}
                  {t("Mining Car ВГ-4.5")}
                  <br />
                </span>
                <span className="text-wrapper-7">
                  {/* + посилена версія */}
                  + {t("Reinforced version")}
                  </span>
              </p>
              <p className="div-3">
                {/* <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span> */}
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}  </span>
                <span className="span">{t("PROMGRUPP")}  </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
              </p>
            </div>
            <p className="element-5">
              {/* Основні характеристики: */}
              {t("Main specifications vg-4.5")}
              <br />
              {/* Вага: 3770 кг */}
              {t("Masa: 3770 кг")}
              <br />
              {/* Максимальна несуча здатність: 13.5 т */}
              {t("Maximum nesycha zdatnist: 13.5 т")}
              <br />
              {/* Колія: 600-900 см */}
              {t("kolia: 600-900 см")}
              <br />
              {/* Довжина: 3950 мм */}
              {t("Length: 3950 мм")}
              <br />
              {/* Ширина: 1350 мм */}
              {t("Width: 1350 мм")}
              <br />
              {/* Виробнича версія: база - s16 мм, 526 кг, */}
              {t("Manufacturing version: base - s16 мм, 526 кг,")}
               <br />
              {/* стінка - s12 мм, 285 кг, */}
              {t("wall - s12 мм, 285 кг,")}
               <br />
              {/* кут- 200 x 200 x 16 мм, 160 кг */}
              {t("angle- 200 x 200 x 16 мм, 160 кг")}
              <br />
              <br />
              {/* Термін виготовлення: 90 днів */}
              {t("Manufacturing lead time: 90 days")}
            </p>
          </div>
        </div>
        <div className="overlap-group-wrapper">
          <div className="overlap-4">
            <div className="overlap-5">
              <img className="rectangle-6" alt="Rectangle" src={Square} />
              <img className="photo" alt="Photo" src={VPSH18} />
              <div className="rectangle-7" />
              <div className="element-6">
                {/* Вагон пасажирський */}
                {t("Passenger wagon")}
                <br />
                {/* ВПШ-18 */}
                {t("VPSH-18")}
              </div>
              <p className="div-4">
                {/* <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span> */}
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}</span>
              </p>
            </div>
            <p className="element-7">
              {/* Основні характеристики: */}
              {t("Main specifications vps")}
              <br />
              {/* Кількість посадочних місць: 18 шт */}
              {t("Number of seats: 18")}
              <br />
              {/* Вага: не більше 2520 кг */}
              {t("Weight: no more 2520 кг")}
              <br />
              {/* Колія: 750/900 мм */}
              {t("Koliya: 750/900 мм")}
              <br />
              {/* Довжина: 4870 мм */}
              {t("Length: 4870 мм")}
              <br />
              {/* Ширина: 1350 мм */}
              {t("Width 1350")}
              <br />
              {/* Висота: 1550 мм */}
              {t("Height: 1550 мм")}
              <br />
              <br />
              {/* Термін виготовлення: 120 днів */}
              {t("Manufacturing lead time: 120 days")}
            </p>
          </div>
        </div>
        <div className="view-5">
          <div className="overlap-6">
            <div className="overlap-7">
              <div className="rectangle-3" />
              <img className="img" alt="Element" src={Oprokid} />
              <div className="rectangle-8" />
              <div className="element-8">
                {/* Перекидач вагонеток */}
                {t("Wagon tipping machine")}
                <br />
                {/* ОК1-1,0-600А */}
                {t("OK1-1,0,600A")}
              </div>
              <p className="div-5">
                {/* <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span> */}
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}</span>
              </p>
            </div>
            <p className="element-9">
              {/* Основні характеристики: */}
              {t("Main specifications ok")}
              <br />
              {/* Вага: не більше 12 000 кг */}
              {t("Masa: no more 12 000 kg")}
               <br />
              {/* (без електрообладнання) */}
              {t("without electrical")}
              <br />
              {/* Параметри барабану: ф2800 х L1950 мм */}
              {t("Parametres baraban: ф2800 х L1950 мм")}
              <br />
              {/* Потужність приводу: 11 кВт */}
              {t("Power privody: 11 кВт")}
              <br />
              {/* Колія: 600 мм */}
              {t("Koliya: 600 мм")}
              <br />
              {/* Довжина: 6243 мм */}
              {t("Length: 6243мм")}
              <br />
              {/* Ширина: 3882 мм */}
              {t("Width: 3882 мм ")}
              <br />
              {/* Висота: 3493,5 мм */}
              {t("Height: 3493,5 мм")}
              <br />
              <br />
              {/* Термін виготовлення: 150 днів */}
              {t("Manufacturing lead time: 150 days")}
            </p>
          </div>
        </div>
        <div className="view-6">
          <div className="overlap-8">
            <div className="overlap-7">
              <div className="rectangle-3" />
              <img className="img" alt="Photo" src={OprokidSec} />
              <div className="rectangle-9" />
              <p className="element-10">
                <span className="text-wrapper-6">
                  {/* Перекидач вагонеток */}
                  {t("Wagon tipping machine oke")}
                  <br />
                  {/* одновагонний ОКЕ1-4,5 */}
                  {t("OKE1-4,5")}
                  <br />
                </span>
                <span className="text-wrapper-7">+ {t("Two-carriage OKE2-4.5")}</span>
              </p>
              <p className="div-3">
                {/* <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span> */}
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}</span>
              </p>
            </div>
            <p className="element-11">
              {/* Основні характеристики: */}
              {t("Main specifications oke")}
              <br />
              {/* Вага: не більше 40 000 кг  */}
              {t("Masa: no more 40 000 kg")}
              <br />
              {/* Параметри барабану: ф4000 х L4100 мм */}
              {t("Parameters drum: ф4000 х L4100 mm")}
              <br />
              {/* Потужність приводу: 60 кВт */}
              {t("Power privoda: 60 Kv")}
              <br />
              {/* Колія: 750 мм */}
              {t("Kolya: 750 mm")}
              <br />
              {/* Довжина: 12000 мм */}
              {t("Length: 12000 mm")}
              <br />
              {/* Ширина: 6500 мм */}
              {t("Width: 6500 mm")}
              <br />
              {/* Висота: 4500 мм */}
              {t("Height: 4500 mm")}
              <br />
              <br />
              {/* Термін виготовлення: 180 днів */}
              {t("Manufacturing lead time: 180 days")}
            </p>
          </div>
        </div>
        <div className="view-7">
          <div className="overlap-8">
            <div className="overlap-7">
              <div className="rectangle-3" />
              <img className="image-2" alt="Image" src={Bur} />
              <div className="rectangle-9" />
              <p className="div-6">
                <span className="text-wrapper-8">
                  {/* Установка бурильна УБШ */}
                  {t("Drilling rig")}
                  <br />
                </span>
                <span className="text-wrapper-7">+ {t("various configurations")}</span>
              </p>
              <p className="div-7">
                {/* <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span> */}
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}</span>
              </p>
            </div>
            <p className="element-11">
              {/* Основні характеристики: */}
              {t("Main specifications ybsh")}
              <br />
              {/* Вага: не більше 23 000 кг  */}
              {t("Masa: no more 23 000 kg")}
              <br />
              {/* Виробнича продуктивність: 2,5 м/хв */}
              {t("Manufactury power: 2,5 м/хв")}
              <br />
              {/* Зона буріння: 8000 х 12 000 мм */}
              {t("Zone drilling: 8000 х 12 000 мм")}
              <br />
              {/* Довжина: 2800 мм */}
              {t("Length: 2800мм")}
              <br />
              {/* Ширина: 2500 мм */}
              {t("Width: 2500 мм")}
              <br />
              {/* Висота: 14500 мм */}
              {t("Height: 14500 мм")}
              <br />
              <br />
              {/* Термін виготовлення: 210 днів */}
              {t("Manufacturing lead time: 210 days")}
            </p>
          </div>
        </div>
        <div className="element-12">
          <div className="overlap-9">
            <div className="overlap-7">
              <div className="rectangle-3" />
              <img className="image-3" alt="Image" src={Machine} />
              <div className="rectangle-9" />
              <div className="text-wrapper-9">
                {/* Машина навантажувальна */}
                {t("Loading machine")}
                <br />
                {/* МПП3 (ППН3М) */}
                {t("МПП3 (ППН3М)")}
              </div>
              <p className="div-5">
                {/* <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="span">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span> */}
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}</span>
              </p>
            </div>
            <p className="element-9">
              {/* Основні характеристики: */}
              {t("Main specifications mmpz")}
              <br />
              {/* Вага: не більше 6 700 кг  */}
              {t("Masa: no more 6 700 kg")}
              <br />
              {/* Продуктивність: 2,0 куб.м */}
              {t("Productivity: 2,0 куб.м")}
              <br />
              {/* Місткість ковша, не менше: 0,6 куб.м */}
              {t("Mistkist kovsha: 0,6 куб.м")}
              <br />
              {/* Ширина захвату, макс: 2700 х 3200 мм */}
              {t("Width zahvata: 2700 х 3200 mm")}
              <br />
              {/* Розмір навантажувальних шматків, до: 600 мм */}
              {t("Size navantashyvalnux, before: 600 mm")}
              <br />
              {/* Довжина: 3300 мм */}
              {t("Length: 3300мм")}
              <br />
              {/* Ширина: 1500 мм */}
              {t("Width: 1500 мм")}
              <br />
              {/* Висота: 1800 мм */}
              {t("height: 1800 мм")}
              <br />
              <br />
              {/* Термін виготовлення: 150 днів */}
              {t("Manufacturing lead time: 150 days")}
            </p>
          </div>
        </div>
        <div className="element-13">
          <div className="overlap-10">
            <div className="overlap-7">
              <div className="rectangle-3" />
              <img className="image-4" alt="Image" src={MachineNK1} />
              <div className="rectangle-9" />
              <div className="text-wrapper-9">
                {/* Машина навантажувальна */}
                {t("Loading machine hk1")}
                <br />
                {/* НК1 (ППН1М) */}
                {t("НК1 (ППН1М)")}
              </div>
              <p className="div-3">
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}</span>
              </p>
            </div>
            <p className="element-9">
              {/* Основні характеристики: */}
              {t("Main specifications hk1")}
              <br />
              {/* Вага: не більше 3 500 кг  */}
              {t("Masa 3 500 кг")}
              <br />
              {/* Продуктивність: 1,25 куб.м */}
              {t("Productivity: 1,25 куб.м")}
              <br />
              {/* Місткість ковша, не менше: 0,26 куб.м */}
              {t("Mistkist: 0,26 куб.м")}
              <br />
              {/* Ширина захвату, макс: 2200 мм */}
              {t("Width zahvata, макс: 2200 мм")}
              <br />
              {/* Розмір навантажувальних шматків, до: 300 мм */}
              {t("Size nagr, до: 300 мм")}
              <br />
              {/* Довжина: 2500 мм */}
              {t("Length: 2500мм")}
              <br />
              {/* Ширина: 1280 мм */}
              {t("Width: 1280 мм")}
              <br />
              {/* Висота: 1580 мм */}
              {t("Height: 1580 мм")}
              <br />
              <br />
              {/* Термін виготовлення: 150 днів */}
              {t("Manufacturing lead time: 150 days")}
            </p>
          </div>
        </div>
        <div className="overlap-wrapper-2">
          <div className="overlap-11">
            <div className="overlap-7">
              <div className="rectangle-3" />
              <img className="img" alt="Image" src={PKSH} />
              <div className="rectangle-9" />
              <div className="text-wrapper-99">
                {/* Навантажувач ковшовий */}
                {t("Front loader pksh")}
                <br />
                {t("ПКШ-М")}
              </div>
              <p className="div-3">
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
                <span className="span">{t("PROMGRUPP")} </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}</span>
              </p>
            </div>
            <p className="element-9">
              {/* Основні характеристики: */}
              {t("Main spacifications pksh-m")}
              <br />
              {/* Вага: не більше 6 700 кг  */}
              {t("Masa  6 700 кг")}
              <br />
              {/* Продуктивність робочого циклу: 32-36 сек */}
              {t("Productivity work cycle: 32-36 сек")}
              <br />
              {/* Місткість ковша: 0,115/0,05 куб.м */}
              {t("Mistkist 0,115/0,05 куб.м")}
              <br />
              {/* Ширина захвату, макс: 2400 х 1700 мм */}
              {t("Width zahvata: 2400 х 1700 мм")}
              <br />
              {/* Висота розвантаження, до: 1550 мм */}
              {t("Hight rozrg: 1550 мм")}
              <br />
              {/* Довжина: 4100 мм */}
              {t("Length: 4100 мм")}
              <br />
              {/* Ширина: 1200 мм */}
              {t("Width: 1200 мм")}
              <br />
              {/* Висота: 1650 мм */}
              {t("Height: 1650 мм")}
              <br />
              <br />
              {/* Термін виготовлення: 180 днів */}
              {t("Manufacturing lead time: 180 days")}
            </p>
          </div>
        </div>
        <div className="overlap-12">
          <div className="element-14">
            <div className="overlap-8">
              <div className="overlap-7">
                <div className="rectangle-3" />
                <img className="img" alt="Img" src={VPKN7B} />
                <div className="rectangle-10" />
                <div className="element-15">
                  {/* Вагон прохідницький */}
                  {t("Shuttle mine car")}
                  <br />
                  {t("ВПКН-7Б")}
                </div>
                <p className="div-7">
                  <span className="span">{t("PROMGRUPP")} </span>
                  <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
                  <span className="span">{t("PROMGRUPP")} </span>
                  <span className="text-wrapper-5">{t("PROMGRUPP")}</span>
                </p>
              </div>
              <p className="element-11">
                {/* Основні характеристики: */}
                {t("Main specifications vpkn")}
                <br />
                {/* Вага: максимум 9000 кг */}
                {t("Masa maximun 9000 кг")}
                <br />
                {/* Максимальна несуча здатність: 23 т */}
                {t("Maximum nesycha zdat: 23 т")}
                <br />
                {/* Місткість: мінімум 7,5 м³ */}
                {t("Mistkist min 7,5 м³")}
                <br />
                {/* Колія: 750 мм */}
                {t("Koliya: 750 мм")}
                <br />
                {/* Довжина: 8900 мм */}
                {t("Length: 8900 мм")}
                <br />
                {/* Ширина: 1350 мм */}
                {t("Width2: 1350 мм")}
                <br />
                {/* Висота: 1650 мм */}
                {t("Height2: 1650 мм")}
                <br />
                <br />
                {/* Термін виготовлення: 120 днів */}
                {t("Manufacturing lead time: 120 days")}
              </p>
            </div>
          </div>
          <div className="view-8">
            <div className="overlap-13">
              <div className="text-wrapper-10">+ {t("Опитувальний лист")}</div>
            </div>
          </div>
        </div>
        <div className="view-9">

          <Footer/>
        </div>
        <div className="view-10">
          <div className="overlap-15">
            <p className="text-wrapper-16">
              {/* Можливості компанії ТОВ ЛВК ПРОМГРУП не обмежуються представленим ілюстративним списком продукції
              категорії ГІРНИЧО-ШАХТНЕ ОБЛАДНАННЯ. */}
              {t("The capabilities")}
              <br />
              {/* Для зручності Ви можете завантажити опитувальний лист на представлене обладнання, заповнити його та
              надіслати нам на електронну пошту. Наші спеціалісти оперативно зв’яжуться з Вами і допоможуть у всіх Ваших
              запитах. */}
              {t("Для зручности")}
              <br />
              <br />
              {/* По будь-яким питанням зв’яжіться з нами в зручний для Вас спосіб. */}
              {t("Please feel free to contact us using your preferred method for any further questions or inquiries")}
            </p>
            <div className="text-wrapper-17">
              {/* ВАЖЛИВО! */}
              {t("IMPORTANT")}
              </div>
          </div>
        </div>
        <p className="element-18">
          {/* Ми також можемо виготовити такі деталі для кар&#39;єрних екскаваторів, грейферних кранів і поворотних систем,
          бурових установок та іншого обладнання: */}
          {t("We can also manufacture")}
          <br />
          <br />
          {/* х&nbsp;&nbsp; балки ручок; */}
          х&nbsp;&nbsp; {t("handle beams")}
          <br />
          {/* х&nbsp;&nbsp; напівмуфти; */}
          х&nbsp;&nbsp; {t("semi-couplings")}
          <br />
          {/* х&nbsp;&nbsp; зубчасті колеса, вінці, шестірні, вал-шестірні, осі механізмів екскаваторів; */}
          х&nbsp;&nbsp; {t("gear wheels, gears, shaft-gears, excavator mechanism shafts")}
          <br />
          {/* х&nbsp;&nbsp; блоки та напівблоки; */}
          х&nbsp;&nbsp; {t("blocks and semi-blocks")}
          <br />
          {/* х&nbsp;&nbsp; колеса натяжні, опорні та ведучі; */}
          х&nbsp;&nbsp; {t("tension wheels, support wheels, and drive wheels")}
          <br />
          {/* х&nbsp;&nbsp; корпуси підшипників; */}
          х&nbsp;&nbsp; {t("bearing housings")}
          <br />
          {/* х&nbsp;&nbsp; роликові опорні круги; */}
          х&nbsp;&nbsp; {t("roller support rings")}
          <br />
          {/* х&nbsp;&nbsp; цапфи центральні, рейки кільцеві; */}
          х&nbsp;&nbsp; {t("central axles, ring rails")}
          <br />
          {/* х&nbsp;&nbsp; ковші ємністю від 4,6 м3 до 15 м3. та деталі до них (зуб&#39;я, днища, стінки); */}
          х&nbsp;&nbsp; {t("buckets with a capacity from 4.6 m3 to 15 m3, and their components (teeth, bottoms, walls)")}
          <br />
          {/* х&nbsp;&nbsp; храпові механізми; */}
          х&nbsp;&nbsp; {t("atchet mechanisms")}
          <br />
          {/* х&nbsp;&nbsp; гуркіти вібраційні та інерційні; */}
          х&nbsp;&nbsp; {t("vibrating and inertial rollers")}
          <br />
          {/* х&nbsp;&nbsp; планки гусеничні та інші металоконструкції. */}
          х&nbsp;&nbsp; {t("track plates and other metal structures")}
        </p>
      </div>
    </div>
    )
}

export default MiningEquipment