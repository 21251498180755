import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './header.css'


const HeaderLight = () =>{

//   const style = {
//     textColor: "black",
//     marginLeft:'540px',
//     height:'100px',
//     position:'absolute',
//     fontWeight:'400',
//     fontSize:'22px',
//     textDecoration:'none',
//     marginTop:'25px'
//   };
const { i18n } = useTranslation();
  
    const changeLanguage = (language) => {
      i18n.changeLanguage(language);
    };
    const {t} = useTranslation()
  return (
    <div className='wrp'>
        <a className='dark' style={{marginLeft:'10px', marginTop:'40px'}} href='/about'>{t("About company")}</a>
        <a className='dark' style={{marginLeft:'14px', marginTop:'40px'}} href='/'>{t("Products")}</a>
        <a className='dark' style={{marginLeft:'14px', marginTop:'40px'}} href='/services'> {t("Services")}</a>
        <a className='dark' style={{marginLeft:'14px', marginTop:'40px'}} href='/contacts'>{t("Contact us")}</a>


          <button className='butLight' style={{marginLeft:'250px', marginTop:'40px'}} onClick={()=>{ changeLanguage('eng')}}>Eng</button>
          <button className='butLight' style={{marginLeft:'15px', marginTop:'40px'}} onClick={()=>{ changeLanguage('ru')}}>Ру</button>
          <button className='butLight' style={{marginLeft:'15px', marginTop:'40px'}} onClick={()=>{ changeLanguage('ua')}}>Укр</button>
    </div>
  );
}

export default HeaderLight





