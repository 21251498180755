import React from "react";

import { ImgWrapper } from "../components/ImgWrapper7";
import Line15 from '../imagesCetrificate/line-15-1.svg'
import Line16 from '../imagesCetrificate/line-16-1.svg'
import Line16P from '../imagesCetrificate/line-16.png'
import Pict from '../imagesCetrificate/1.png'
import Cert14 from '../imagesCetrificate/image-14.png'
import Cert15 from '../imagesCetrificate/image-15.png'
import Cert16 from '../imagesCetrificate/image-16.png'
import Cert17 from '../imagesCetrificate/image-17.png'
import Logo from '../imagesCetrificate/2.svg'
import Iso1 from '../imagesCetrificate/iso-1.png'
import Iso2 from '../imagesCetrificate/iso-2.png'
import LogoDark from '../imagesCetrificate/3.svg'
import Line64 from '../imagesCetrificate/line-64.svg'
import Menu from '../imagesCetrificate/2x.png'
import "./cerificate.css"
import Footer from "./Footer";
import { Header } from "./Header";
import { useTranslation } from 'react-i18next'
import HeaderLight from "./Header/HeaderLight";

// import './certif.css'

const Certification = () =>{
  const {t} = useTranslation()
    return(
            <div className="crf">
              <div className="dive">
                <div className="view">
                  <div className="overlap-group">
                    <div className="rectangle" />
                    <div className="rectangle-2" />
                    <div className="text-wrapper-2">
                      ВиготовленняПроектування
                      <br />
                      МеталообробкаПослугиТермообробка
                      <br />
                      ВиготовленняРемонтПослуги
                      <br />
                      МеталообробкаТермообробкаРемонт
                      <br />
                      МеталообробкаВиготовлення
                      <br />
                      алообробкаПослугиТермообробка
                      <br />
                      ВиготовленняРемонтПослуги
                      <br />
                      МеталообробкаТермообробкаРемонт
                      <br />
                      МеталообробкаВиготовлення
                    </div>
                    <div className="text-wrapper-3">Україна, Кривий Ріг</div>
                    <p className="p">
                     
                      <span className="text-wrapper-4">
                        {t("Main page")}
                        <br />
                        <br />
                        <br />
                        {t("Products")}
                        <br />
                        <br />
                        <br />
                        {t("Services")}
                        <br />
                        <br />
                        <br />
                        {t("Contact us")}
                      </span>
                    </p>
                    <p className="element">
                      +38 067 638 40 80
                      <br />
                      +38 067 569 11 33
                      <br />
                      +38 056 493 80 39
                      <br />
                      +38 056 493 80 38
                    </p>
                    <div className="text-wrapper-5">promgrupp.pc@gmail.com</div>
                    <p className="element-2">
                      {/* Всі права захищені. Використання матеріалів тільки з дозволу власника. */}
                      {t("All rights reserved")}
                      <br />© 2023
                    </p>
                  </div>
                  <img className="line" alt="Line" src={Line16P} />
                  <img className="img" alt="Line" src={Line16P}/>
                  <ImgWrapper className="image" img={Pict} />
                </div>
                <p className="ISO">
                  {/* Замовляючи нашу продукцію, клієнти хочуть бути впевнені, що вона відповідає всім встановленим стандартам
                  якості. Тому наша компанія завжди приділяє велику увагу якості всієї продукції, що випускається. Один з
                  показників нашої турботи про клієнтів - це високий рівень нашої продукції, який забезпечується діючою на
                  підприємстві системою управління якістю. Ця система відповідає вимогам міжнародного стандарту ISO 9001. */}
                  {t("When ordering our")}
                  <br />
                  <br />
                  {/* На підтвердження високої якості на кожній виготовленій або відремонтованій компанією продукції є клеймо
                  відділу технічного контролю. Ми надаємо сертифікат якості на всю продукцію нашої компанії, зразок такої
                  довідки наведено нижче. */}
                  {t("To confirm")}
                  <br />
                  <br />
                  {/* Також ми надаємо гарантію безвідмовної роботи всієї продукції на термін від 1 до 36 місяців (в залежності від
                  виду товару, умов виробництва та інших факторів). При необхідності наша компанія може надати послугу
                  післягарантійного обслуговування, відновлення та ремонту виготовленої продукції. */}
                  {t("Furthermore we offer")}
                </p>
                <div className="overlap">
                  <div className="rectangle-3" />
                  <img className="image-2" alt="Image" src={Cert16} />
                  <img className="image-3" alt="Image" src={Cert17} />
                </div>
                <div className="overlap-2">
                  <div className="rectangle-4" />
                  <img className="image-4" alt="Image" src={Cert14} />
                  <img className="image-5" alt="Image" src={Cert15} />
                </div>
                <div className="overlap-wrapper">
                    <Footer/>
               
                   
                </div>
                <div className="overlap-4">
                  <div className="overlap-4">
                    <img className="iso" alt="Iso" src={Iso2}/>
                    <div className="rectangle-7" />
                    <img className="iso-2" alt="Iso" src={Iso1}/>
                    <a href="/"> <ImgWrapper className="component-92-instance" img={LogoDark} /> </a>
                    <div className="text-wrapper-9">
                      {t("LLC")}{" "}{t("FPC Promgrupp")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("QualityUper")}
                    </div>
                    <div className="text-wrapper-10">{t("Certification")}</div>
                    <div className="rectangle-8" />
                    <div className="rectangle-9" />
                    <img className="line-4" alt="Line" src={Line64} />
                    <div className="text-wrapper-11">
                      Виготовлення
                      <br />
                      Проектування
                      <br />
                      Металообробка
                      <br />
                      Термообробка
                      <br />
                      РемонтПослуги
                      <br />
                      Виготовлення
                      <br />
                      Проектування
                      <br />
                      Металообробка
                      <br />
                      Термообробка
                      <br />
                      РемонтПослуги
                      <br />
                      Виготовлення
                      <br />
                      Проектування
                      <br />
                      Металообробка
                      <br />
                      Термообробка
                      <br />
                      РемонтПослугиВиготовлення
                      <br />
                      Проектування
                      <br />
                      Металообробка
                    </div>
                  </div>
                  {/* <img className="image-6" alt="Image" src={Menu} /> */}
                  {/* <DivWrapper className="component-93" divClassName="component-93-instance" />
                  <DivWrapper
                    className="design-component-instance-node"
                    divClassName="component-93-instance"
                    text="Продукція"
                  />
                  <DivWrapper className="view-2" divClassName="component-93-instance" text="Послуги" />
                  <DivWrapper className="view-3" divClassName="component-93-instance" text="Контакти" /> */}
                  <HeaderLight cls="wrp2" divClassName='dark'/>
                </div>
                <p className="text-wrapper-12">
                  {/* Якість наших виробів перевірена досвідом. */}
                  {t("Verified through experience")}
                  </p>
              </div>
            </div>
          );
        };
        
  

export default Certification