import PropTypes from "prop-types";
import React from "react";
import "./ImgWrapper.css";

export const ImgWrapper = ({ className, img = "/img/image.svg" }) => {
  return <img className={`img-wrapper ${className}`} alt="Img" src={img} />;
};

ImgWrapper.propTypes = {
  img: PropTypes.string,
};