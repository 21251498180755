import React from "react";
import { Component } from "../../components/Component79";
import { ImgWrapper } from "../../components/ImgWrapper79";
import Rec13 from '../../mobImages/imagesSpecializedM/rectangle-13.svg'
import Rec99 from '../../mobImages/imagesSpecializedM/rectangle-99.svg'
import mask from '../../mobImages/imagesSpecializedM/mask-group-1.png'
import zympf from '../../mobImages/imagesSpecializedM/wp-20170618-07-52-11-pro-1-1.png'
import teleshka from '../../mobImages/imagesSpecializedM/photo-2022-01-17-11-16-00-4.png'
import oporu from '../../mobImages/imagesSpecializedM/photo-2023-04-22-21-28-39-1.png'
import oporuSecond from '../../mobImages/imagesSpecializedM/viber-2023-02-16-12-55-42-219-1.png'
import sbsh from '../../mobImages/imagesSpecializedM/06012011074-1.png'
import dscn from '../../mobImages/imagesSpecializedM/dscn0820-1.png'
import Line17 from '../../mobImages/imagesSpecializedM/line-17.svg'
import OneSvg from '../../mobImages/imagesSpecializedM/1.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./specializedM.css";

export const SpecializedM = () => {
  return (
    <div className="specializedM">
      <div className="div">
        <div className="overlap">
          <img className="rectangle" alt="Rectangle" src={Rec13}/>
        <a href='/contacts'><div className="text-wrapper-2">Контакти</div></a>  
        <a href='/services'><div className="text-wrapper-3">Послуги</div></a>  
        <a href='/'><div className="text-wrapper-4">Продукція</div></a>  
        <a href='/about'><div className="text-wrapper-5">Про компанію</div></a>  

          {/* <Component GR="/img/gr-1.png" className="component-1" maskGroup={mask} /> */}
          <a href="/"><img className="component-1" src={LogoDark}></img></a>
          <div className="text-wrapper-6">ПРОДУКЦІЯ</div>
          <div className="rectangle-2" />
          <div className="text-wrapper-7">
            Спеціалізоване
            <br />
            обладанання та
            <br />
            металоконструкції
          </div>
        </div>
        <div className="text-wrapper-8">
          ТОВ ЛВК
          ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ПРОДУКЦІЯ
        </div>
        <p className="p">
          Наша компанія може швидко і якісно виготовити будь-який спеціалізоване та нестандартне обладнання з повним
          проектуванням за наявними аналогами, а також інше промислові, будівельні та побутові металоконструкції.
          <br />
          <br />
          Нижче представлений загальний ілюстративний список з основною виробляємою нами продукцією категорії
          СПЕЦІАЛІЗОВАНЕ ОБЛАДНАННЯ ТА МЕТАЛОКОНСТРУКЦІЇ.
        </p>
        <div className="overlap-2">
          <div className="overlap-3">
            <div className="rectangle-3" />
            <img className="WP" alt="Wp" src={zympf}/>
            <div className="rectangle-4" />
            <div className="v-v">
              Зумпф V=85 Дешламатор
              <br />
              V=600
            </div>
            <div className="text-wrapper-9">(зварна конструкція)</div>
          </div>
          <p className="d-h">
            Основні характеристики Зумпфа:
            <br />
            Загальний розмір: d=6500 мм, H=6900 мм
            <br />
            Вага: 16 490 кг
            <br />
            <br />
            Основні характеристики Дешламатора:
            <br />
            Загальний розмір: d=11700 мм, H=10500 мм
            <br />
            Вага: 37 530 кг
            <br />
            <br />
            Термін виготовлення: 180 днів
          </p>
        </div>
        <div className="overlap-4">
          <div className="overlap-3">
            <div className="rectangle-3" />
            <img className="photo" alt="Photo" src={teleshka}/>
            <div className="rectangle-5" />
            <div className="text-wrapper-10">Візки розвантажувальні</div>
            <div className="text-wrapper-11">+з імпортним електрообладнанням</div>
          </div>
          <p className="element">
            Основні характеристики:
            <br />
            Вага: до 15000 кг
            <br />
            Ширина стрічки конвеєра: 1600 мм
            <br />
            Ширина колеї: 2300 мм
            <br />
            Швидкість переміщення: 0,125 м/с
            <br />
            Довжина: 7790 мм
            <br />
            Ширина: 4550 мм
            <br />
            Висота: 4328 мм
            <br />
            <br />
            Термін виготовлення: 120 днів
            <br />
            після повного проектування
          </p>
        </div>
        <div className="overlap-5">
          <div className="overlap-3">
            <div className="rectangle-3" />
            <img className="img" alt="Photo" src={oporu} />
            <div className="rectangle-5" />
            <div className="text-wrapper-12">+ нерухома</div>
            <div className="text-wrapper-13">Опори ж/д мостові</div>
          </div>
          <p className="element-2">
            Основні характеристики:
            <br />
            Вага: до 1300 кг
            <br />
            Довжина: 1100 мм
            <br />
            Ширина: 900 мм
            <br />
            Висота: 680 мм
            <br />
            <br />
            Термін виготовлення: 60-90 днів
          </p>
        </div>
        <div className="overlap-6">
          <div className="overlap-3">
            <div className="rectangle-3" />
            <img className="viber" alt="Viber" src={oporuSecond} />
            <div className="rectangle-4" />
            <div className="text-wrapper-14">+ рухомі</div>
            <div className="text-wrapper-15">Опори ж/д мостові</div>
          </div>
          <p className="element-3">
            Основні характеристики:
            <br />
            Вага: до 2600 кг
            <br />
            Довжина: 1360 мм
            <br />
            Ширина: 860 мм
            <br />
            Висота: 770 мм
            <br />
            <br />
            Термін виготовлення: 60-90 днів
          </p>
        </div>
        <div className="overlap-7">
          <div className="overlap-3">
            <div className="rectangle-3" />
            <img className="element-4" alt="Element" src={sbsh} />
            <div className="rectangle-5" />
            <div className="text-wrapper-16">
              Ремонт гусеничного ходу
              <br /> СБШ
            </div>
          </div>
          <p className="element-5">
            Основні характеристики:
            <br />
            Вага: 21 969 кг
            <br />
            Вантажопідйомність, не більше: 50 т<br />
            Швидкість, не менше: 0,77 км/год
            <br />
            <br />
            Термін ремонту: 180 днів
          </p>
        </div>
        <div className="overlap-8">
          <div className="overlap-3">
            <div className="rectangle-3" />
            <img className="dscn" alt="Dscn" src={dscn} />
            <div className="rectangle-5" />
            <p className="text-wrapper-17">Ремонт ковшів ЭКГ-10, ЭКГ-8, ЭКГ-5</p>
          </div>
          <p className="element-6">
            Основні характеристики:
            <br />
            Об&#39;єм: 8 м.куб.
            <br />
            Вага: 19480 кг
            <br />
            <br />
            Термін ремонту: 40 днів
          </p>
        </div>
        <p className="text-wrapper-18">
          Виготовлення металоконструкцій з листового, профільного металу і труб, а також литозварних і зварно-кованих
          конструкцій з вуглецевих і низьколегованих конструкційних сталей. У виробництві використовують різні види
          зварювання, серед яких:
          <br />- автоматичне зварювання під флюсом повним і порошковим дротом;
          <br />- зварювання в середовищі активного та інертного захисного газу плавким і неплавким електродом;
          <br />- ручне дугове зварювання;
          <br />- автоматичне дугове зварювання та плазмове наплавлення зносостійкими матеріалами, у тому числі
          композитними, і корозійностійкими матеріалами.
        </p>
        <div className="overlap-9">
          <div className="overlap-10">
            <div className="rectangle-6" />
            <div className="text-wrapper-19">ВАЖЛИВО!</div>
            <p className="text-wrapper-20">
              Можливості компанії ТОВ ЛВК ПРОМГРУП не обмежуються представленим ілюстративним списком продукції
              категорії СПЕЦІАЛІЗОВАНЕ ОБЛАДНАННЯ ТА МЕТАЛОКОНСТРУКЦІЇ.
              <br />
              По будь-яким питанням зв’яжіться з нами в зручний для Вас спосіб.
            </p>
          </div>
          <div className="overlap-11">
            <img className="rectangle-7" alt="Rectangle" src={Rec99}/>
            <p className="element-7">
              +38 067 638 40 80
              <br />
              +38 067 569 11 33
              <br />
              +38 056 493 80 39
              <br />
              +38 056 493 80 38
            </p>
            <div className="rectangle-8" />
            <div className="text-wrapper-21">
              ВиготовленняПроектування
              <br />
              МеталообробкаПослугиТермообробка
              <br />
              ВиготовленняРемонтПослуги
              <br />
              МеталообробкаТермообробкаРемонт
              <br />
              МеталообробкаВиготовлення
              <br />
              алообробкаПослугиТермообробка
              <br />
              ВиготовленняРемонтПослуги
              <br />
              МеталообробкаТермообробкаРемонт
              <br />
              МеталообробкаВиготовлення
            </div>
            <div className="text-wrapper-22">Україна, Кривий Ріг</div>
            <p className="div-2">
              <span className="span">Г</span>
              <span className="text-wrapper-23">
                оловна
                <br />
                <br />
                Про компанію
                <br />
                <br />
                Продукція
                <br />
                <br />
                Послуги
                <br />
                <br />
                Контакти
              </span>
            </p>
            <div className="text-wrapper-24">promgrupp.pc@gmail.com</div>
            <p className="element-8">
              Всі права захищені. Використання матеріалів тільки з дозволу власника.
              <br />© 2023
            </p>
            <img className="line" alt="Line" src={Line17} />
            <ImgWrapper className="image" img={OneSvg} />
          </div>
        </div>
      </div>
    </div>
  );
};
