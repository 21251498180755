import React from "react";
import Line15P from '../imageContacts/line-15.png'
import Line16P from '../imageContacts/line-16-1.png'
import Line15 from '../imageContacts/line-15-1.svg'
import Line16 from '../imageContacts/line-16.svg'
import Line63 from '../imageContacts/line-63.svg'
import Line67 from '../imageContacts/line-67.svg'
import Line68 from '../imageContacts/line-68.svg'
import Mapw from '../imageContacts/image-18.png'
import Point from '../imageContacts/1.png'
import List from '../imageContacts/1-2x.png'
import Phone from '../imageContacts/2x.png'
import Logo from '../imageContacts/2.svg'
import LogoDark from '../imageContacts/1.svg'
import Menu from '../imageContacts/image.png'
import Manufacturing from '../imageContacts/manufacturing-1.png'
import Manufacturing2 from '../imageContacts/manufacturing-2.png'
import { DivWrapper } from "../components/DivWrapper8";
import { ImgWrapper } from "../components/ImgWrapper8";
import Pict from '../imageContacts/3.png'
import "./contacts.css";
import Footer from "./Footer";
import Map from "../components/Map/Map";
import { useJsApiLoader } from "@react-google-maps/api";
import { Header } from "./Header";
import { useTranslation } from 'react-i18next'
import HeaderLight from "./Header/HeaderLight";
const Contacts = () =>{
  const API_KEY = process.env.REACT_APP_API_KEY
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY
  })
  const {t} = useTranslation()
    return(
    <div className="contact">
      <div className="div">
        <div className="view">
          <div className="overlap-group">
            <div className="rectangle" />
            <div className="rectangle-2" />
            <div className="text-wrapper-2">
              ВиготовленняПроектування
              <br />
              МеталообробкаПослугиТермообробка
              <br />
              ВиготовленняРемонтПослуги
              <br />
              МеталообробкаТермообробкаРемонт
              <br />
              МеталообробкаВиготовлення
              <br />
              алообробкаПослугиТермообробка
              <br />
              ВиготовленняРемонтПослуги
              <br />
              МеталообробкаТермообробкаРемонт
              <br />
              МеталообробкаВиготовлення
            </div>
            <div className="text-wrapper-3">{t("Ukraine")}</div>
            <p className="p">
              
              <span className="text-wrapper-4">
                {t("Main page")}
                <br />
                <br />
                <br />
                {t("Products")}
                <br />
                <br />
                <br />
                {t("Services")}
                <br />
                <br />
                <br />
                {t("Contact us")}
              </span>
            </p>
            <p className="element">
              +38 067 638 40 80
              <br />
              +38 067 569 11 33
              <br />
              +38 056 493 80 39
              <br />
              +38 056 493 80 38
            </p>
            <div className="text-wrapper-5">promgrupp.pc@gmail.com</div>
            <p className="element-2">
              {/* Всі права захищені. Використання матеріалів тільки з дозволу власника. */}
              {t("All rights reserved")}
              <br />© 2023
            </p>
          </div>
          <img className="line" alt="Line" src={Line15P} />
          <img className="img" alt="Line" src={Line16P}/>
          <ImgWrapper className="image" img={Pict} />
        </div>
        <div className="view-2">
          <div className="overlap-group">
            <div className="rectangle" />
            <div className="rectangle-2" />
            <div className="text-wrapper-2">
              ВиготовленняПроектування
              <br />
              МеталообробкаПослугиТермообробка
              <br />
              ВиготовленняРемонтПослуги
              <br />
              МеталообробкаТермообробкаРемонт
              <br />
              МеталообробкаВиготовлення
              <br />
              алообробкаПослугиТермообробка
              <br />
              ВиготовленняРемонтПослуги
              <br />
              МеталообробкаТермообробкаРемонт
              <br />
              МеталообробкаВиготовлення
            </div>
            <div className="text-wrapper-3">Україна, Кривий Ріг</div>
            <p className="p">
             
              <span className="text-wrapper-4">
              {t("Main page")}
                <br />
                <br />
                <br />
                {t("Products")}
                <br />
                <br />
                <br />
                {t("Services")}
                <br />
                <br />
                <br />
                {t("Contact us")}
              </span>
            </p>
            <p className="element">
              +38 067 638 40 80
              <br />
              +38 067 569 11 33
              <br />
              +38 056 493 80 39
              <br />
              +38 056 493 80 38
            </p>
            <div className="text-wrapper-5">promgrupp.pc@gmail.com</div>
            <p className="element-2">
              {/* Всі права захищені. Використання матеріалів тільки з дозволу власника. */}
              {t("All rights reserved")}
              <br />© 2023
            </p>
          </div>
          <img className="line-2" alt="Line" src={Line16P} />
          <img className="img" alt="Line" src={Line16P}/>
          <ImgWrapper className="image" img={Pict} />
        </div>
        <div className="overlap">
          <div className="rectangle-3" />
          <div className="text-wrapper-6">{t("Where is our production located")}</div>
         {isLoaded ? <Map className='image-2'/> : <h2>Что то пошло не так</h2>}
        </div>
        <div className="text-wrapper-7">{t("How to contact us")}</div>
        <img className="image-3" alt="Image" src={Point} />
        <img className="image-4" alt="Image" src={List} />
        <img className="image-5" alt="Image" src={Phone} />
        <div className="overlap-wrapper">
       
          <Footer/>
        </div>
        <div className="overlap-3">
          <div className="overlap-3">
            <div className="rectangle-6" />
            <img className="manufacturing" alt="Manufacturing" src={Manufacturing} />
            <img className="manufacturing-2" alt="Manufacturing" src={Manufacturing2} />
           <a href="/">  <ImgWrapper className="component-128-instance" img={LogoDark}/> </a> 
            <div className="text-wrapper-11">{t("Contact us")}</div>
            <div className="text-wrapper-12">
              {t("LLC")}{t(" ")}{t("FPC Promgrupp")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("RelevanceUper")}
            </div>
            <div className="rectangle-7" />
            <div className="rectangle-8" />
            <div className="text-wrapper-13">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослугиВиготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
            <img className="line-5" alt="Line" src={Line63}/>
            <img className="line-5" alt="Line" src={Line63} />
          </div>
          {/* <img className="image-6" alt="Image" src={Menu} /> */}
          <HeaderLight cls='wrp2' divClassName='dark'/>
        </div>
        <p className="text-wrapper-14">
          {/* Хочете про щось запитати? */}
          {t("Do you have any questions")}
          <br />
          {/* Звяжіться з нами. */}
          {t("Contacts us")}
           <br />
          {/* Ми будемо раді відповісти на будь-які Ваші питання. */}
          {t("We so happy")}
        </p>
        <img className="line-6" alt="Line" src={Line67} />
        <img className="line-7" alt="Line" src={Line68} />
        <p className="element-5">
          <span className="text-wrapper-15">+38</span>
          <span className="text-wrapper-16">
            {" "}
            067 569 11 33
            <br />
            +38 067 638 40 80
            <br />
            +38 056 493 80 39
            <br />
            +38 056{" "}
          </span>
          <span className="text-wrapper-15">493 80 38</span>
        </p>
        <div className="text-wrapper-17">promgrupp.pc@gmail.com</div>
        <p className="element-6">
          {t("55 Sviato-Mykolaivska")}
           <br />
          {/* Дніпропетровська область, */}
          {t("Dnipro")}
          <br />
          {/* місто Кривий Ріг, */}
          {t("Kryvui Rih")}
           <br />
          {/* вулиця Свято-Миколаївська, 55 */}
          {t("street")}
        </p>
      </div>
    </div>
  );
};


export default Contacts