import React from "react";
import { Component } from "../../components/Component80";
import { ImgWrapper } from "../../components/ImgWrapper80";
import Rec13 from '../../mobImages/imagesMetalurgicalProductM/rectangle-13.svg'
import Rec99 from '../../mobImages/imagesMetalurgicalProductM/rectangle-99.svg'
import vizok from '../../mobImages/imagesMetalurgicalProductM/viber-2023-02-16-12-48-06-144-2.png'
import bytara from '../../mobImages/imagesMetalurgicalProductM/1.png'
import pech from '../../mobImages/imagesMetalurgicalProductM/1000x800-img-7267-1.png'
import line17 from '../../mobImages/imagesMetalurgicalProductM/line-17.svg'
import OneSvg from '../../mobImages/imagesMetalurgicalProductM/1.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./metalurgicalProductionM.css";

export const MettalurgicalProductionM = () => {
  return (
    <div className="metallurgicalM">
      <div className="div">
        <div className="overlap">
          <img className="rectangle" alt="Rectangle" src={Rec13} />
         <a href='/contacts'><div className="text-wrapper-2">Контакти</div></a> 
         <a href='/services'><div className="text-wrapper-3">Послуги</div></a> 
         <a href='/'><div className="text-wrapper-4">Продукція</div></a> 
         <a href='/about'> <div className="text-wrapper-5">Про компанію</div></a>
          {/* <Component GR="/img/gr.png" className="component-1" /> */}
          <a href='/'><img className="component-1" src={LogoDark}></img></a>
          <div className="text-wrapper-6">ПРОДУКЦІЯ</div>
          <div className="rectangle-2" />
          <div className="text-wrapper-7">
            Обладнання
            <br />
            металургійного
            <br />
            виробництва
          </div>
        </div>
        <div className="text-wrapper-8">
          ТОВ ЛВК
          ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ПРОДУКЦІЯ
        </div>
        <p className="p">
          Для агломераційного та металургійного виробництва наша компанія виготовляє і ремонтує наступну продукцію:
          <br />
          х&nbsp;&nbsp; палетні вагони для твердосплавних і спікальних машин;
          <br />
          х&nbsp;&nbsp; вали для двовальних класифікаторів з опорами, двома шиберами;
          <br />
          х&nbsp;&nbsp; піддони кільцевих охолоджувачів;
          <br />
          х&nbsp;&nbsp; плити для відкидного екрану гарячого агломерату;
          <br />
          х&nbsp;&nbsp; лінійні охолоджувачі агломерату;
          <br />
          х&nbsp;&nbsp; шлакові ковші.
          <br />
          <br />
          Нижче представлений загальний ілюстративний список з основною виробляємою нами продукцією категорії ОБЛАДНАННЯ
          МЕТАЛУРГІЙНОГО ВИРОБНИЦТВА.
        </p>
        <div className="overlap-2">
          <div className="overlap-3">
            <div className="rectangle-3" />
            <img className="viber" alt="Viber" src={vizok} />
            <div className="rectangle-4" />
            <div className="text-wrapper-9">Спекальний візок випалювальної машини</div>
            <div className="text-wrapper-10">(зварна конструкція)</div>
          </div>
          <p className="element">
            Основні характеристики:
            <br />
            Вага: 1530 кг
            <br />
            Ширина вагонетки: 3040 мм
            <br />
            Довжина: 1000 мм
            <br />
            Ширина: 3219 мм
            <br />
            Висота: 775 мм
            <br />
            <br />
            Термін виготовлення: 60 днів
          </p>
        </div>
        <div className="overlap-4">
          <div className="rectangle-3" />
          <img className="img" alt="Element" src={bytara} />
          <div className="rectangle-4" />
          <div className="text-wrapper-11">Кулеуловлювач</div>
          <div className="text-wrapper-12">(Бутара)</div>
        </div>
        <div className="overlap-5">
          <div className="overlap-3">
            <div className="rectangle-3" />
            <img className="element-IMG" alt="Element IMG" src={pech} />
            <div className="rectangle-4" />
            <div className="text-wrapper-13">Сушильна піч безперервної дії</div>
          </div>
          <p className="element">
            Основні характеристики:
            <br />
            Вага: 2200 кг
            <br />
            Продуктивність: 3 т/г
            <br />
            Потужність: 270 кВт
            <br />
            Розмір: 2700 х 1700 мм
            <br />
            <br />
            Термін виготовлення: 90 днів
          </p>
        </div>
        <p className="element-2">
          Деталі та вузли переробної промисловості займають значну частину виробленої та відремонтованої продукції.
          <br />
          Для всіх видів млинів іноземного та вітчизняного виробництва ми можемо виготовити та відремонтувати:
          <br />
          х&nbsp;&nbsp; ротори;
          <br />
          х&nbsp;&nbsp; кулачок і зубчасті колеса до нього;
          <br />
          х&nbsp;&nbsp; регулювальні та опорні кільця;
          <br />
          х&nbsp;&nbsp; приводний вал в зборі;
          <br />
          х&nbsp;&nbsp; підшипникові блоки;
          <br />
          х&nbsp;&nbsp; конічні і шарійні втулки;
          <br />
          х&nbsp;&nbsp; корпуси до 20000 кг та ін.
        </p>
        <div className="overlap-6">
          <div className="rectangle-5" />
          <div className="text-wrapper-14">ВАЖЛИВО!</div>
          <p className="text-wrapper-15">
            Можливості компанії ТОВ ЛВК ПРОМГРУП не обмежуються представленим ілюстративним списком продукції категорії
            СПЕЦІАЛІЗОВАНЕ ОБЛАДНАННЯ ТА МЕТАЛОКОНСТРУКЦІЇ.
            <br />
            По будь-яким питанням зв’яжіться з нами в зручний для Вас спосіб.
          </p>
        </div>
        <div className="overlap-7">
          <img className="rectangle-6" alt="Rectangle" src={Rec99} />
          <p className="element-3">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-7" />
          <div className="text-wrapper-16">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-17">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="span">Г</span>
            <span className="text-wrapper-18">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-19">promgrupp.pc@gmail.com</div>
          <p className="element-4">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line" alt="Line" src={line17} />
          <ImgWrapper className="image" img={OneSvg} />
        </div>
      </div>
    </div>
  );
};
