import React from "react";
import Line15 from '../imageAbout/line-15.svg'
import Line16 from '../imageAbout/line-16.svg'
import LogoDark from '../imageAbout/2.svg'
import LogoDark1 from '../imageAbout/1.svg'
import Oprokid from '../imageAbout/2-1.png'
import OprokidSec from '../imageAbout/photo-2021-11-11-10-18-40-1.png'
import Vagon4 from '../imageAbout/4-5-removebg-preview-4.png'
import Square from '../imageAbout/0-02-05-0cd9e43f6bbe030808df30761fea053e6594c3feea806d5473845774-1.png'
import VagonetkaPe from '../imageAbout/img-7044-5.png'
import Black from '../imageAbout/0-02-05-0cd9e43f6bbe030808df30761fea053e6594c3feea806d5473845774.png'
import Koleso from '../imageAbout/0-02-0a-fb381f6d82274936a74f1285fe88c6241f3e6bdf7669314befd17f15.png'
import BarabanSmall from '../imageAbout/image-6487327-1.png'
import Val from '../imageAbout/photo-2023-04-22-21-28-36-1.png'
import Dskn from '../imageAbout/dscn0460-1.png'
import Line81 from '../imageAbout/line-81.svg'
import Line83 from '../imageAbout/line-83.svg'
import Line84 from '../imageAbout/line-84.svg'
import Line66 from '../imageAbout/line-66.svg'
import { ImgWrapper } from "../components/ImgWrapper5";
import Menu from '../imageAbout/2x.png'
import "./about.css";
import Footer from "./Footer";
import { Header } from "./Header/Header";
import { useTranslation } from 'react-i18next'
import HeaderLight from "./Header/HeaderLight";

const AboutCompany = () =>{
  const {t} = useTranslation()
    return(
     
    <div className="about">
      <div className="div">
        <p className="element">
          
          
          {/* Технологічні можливості компанії дозволяють здійснювати повний цикл виробництва, від етапу розробки технічного
          завдання до етапу післяпродажного обслуговування та технічного обслуговування та ремонту. */}
          {t("The company's technological")}
          <br />
          <br />
          {/* Виробничу базу підприємства складають заготівельний цех, цех металоконструкцій, обробний цех,
          обробно-складальний цех і цех термообробки. */}
          {t("The company's production")}
          <br />
          {/* Техніка оснащена універсальним і спеціальним обладнанням, а також верстатами з ЧПК. Наявне обладнання дозволяє
          обробляти деталі до 20 тон. */}
          {t("The equipment is equipped with a universal")}
          <br />
          <br />
          {/* До складу машинного обладнання входять: */}
          {t("The machine equipment includes:")}
          <br />
          {/* х&nbsp;&nbsp; токарно-різьбонарізний верстат; */}
          х&nbsp;&nbsp; {t("lathe and threading machines")}
          <br />
          {/* х&nbsp;&nbsp; розточувальні, горизонтальні та координатні верстати; */}
          х&nbsp;&nbsp; {t("boring, horizontal, and coordinate machines")}
          <br />
          {/* х&nbsp;&nbsp; свердлильний, фрезерний, шліфувальний верстати; */}
          х&nbsp;&nbsp; {t("drilling, milling, and grinding machines")}
          <br />
          {/* х&nbsp;&nbsp; верстат зуборізальний, зуборізальний верстат; */}
          х&nbsp;&nbsp; {t("ear cutting and gear hobbing machines")}
          <br />
          {/* х&nbsp;&nbsp; зварювальне та наплавлювальне обладнання; */}
          х&nbsp;&nbsp; {t("welding and surfacing equipment")}
          <br />
          {/* х&nbsp;&nbsp; верстати стрічкові та відрізні; */}
          х&nbsp;&nbsp; {t("bandsaws and cutting-off machines")}
          <br />
          {/* х&nbsp;&nbsp; обладнання для термічної обробки; */}
          х&nbsp;&nbsp; {t("equipment for heat treatment")}
          <br />
          {/* х&nbsp;&nbsp; слюсарно-складальне обладнання. */}
          х&nbsp;&nbsp; {t("locksmith and assembly equipment")}
          <br />
          <br />
          {/* Наявне обладнання дозволяє виконувати ремонт різної складності - заміну підшипників та інших деталей,
          зменшення місць зносу, посадочних поверхонь підшипників і втулок, посилення та модернізацію вузлів. */}
          {t("The available equipment")}
          <br />
          <br />
          {/* Для виконання монтажних або ремонтних робіт на території замовника використовується спеціальне пересувне та
          переносне обладнання, що дозволяє виконувати механічну обробку великогабаритних деталей без демонтажу та
          транспортування. */}
          {t("For installation or")}
          <br />
          <br />
          {/* ТОВ ЛВК ПРОМГРУП має міцні стосунки з переробними підприємствами для виконання складних відливок або кування
          заготовок з будь-яких матеріалів. */}
          {t("LLC LVC PROMGROUP")}
          <br />
          <br />
          {/* Компанія активно розвивається з метою розширення технологічних можливостей, підвищення точності та якості
          продукції, а також підвищення продуктивності та конкурентоспроможності. */}
          {t("The company actively")}
        </p>
        <p className="textWarapper">
     
          {/* ТОВ ЛИВАРНО-ВИРОБНИЧА КОМПАНІЯ ПРОМГРУП - підприємство, яке динамічно розвивається. Позиціонується як виробник
          запасних частин та обладнання для гірничо-рудної, гірничо-добувної та металургійної галузей промисловості, а
          також виконавець поточного та капітального ремонту цього обладнання, а саме конвеєрного, дробильного,
          кар&#39;єрних екскаваторів, СБШ, іншого технологічного та нестандартного обладнання та запасних частин до
          нього. */}
          {t("LLC FPC PROMGROUP is a")}
        </p>
       
        <div className="view">
            <Footer></Footer>
         
        </div>
        <div className="overlap">
          <div className="overlap">
            <a href='/'> <ImgWrapper className="component-117" img={LogoDark1} /> </a>
            <img className="element-4" alt="Element" src={Oprokid} />
            <div className="rectangle-3" />
            <img className="photo" alt="Photo" src={OprokidSec} title="Ah Satan" />
            <div className="text-wrapper-6">{t("About company")}</div>
            <div className="text-wrapper-7">
              {t("LLC FPC PROMGROUP PRODUCTS")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("EXPERIENCE")}
            </div>
            <div className="rectangle-4" />
            <div className="rectangle-5" />
            <img className="line-2" alt="Line" src={Line66} />
            <div className="text-wrapper-8">
              {/* Виготовлення */}
              {t("ManufacturingDesign")}
              <br />
              {/* Проектування */}
              <br />
              {/* Металообробка */}
              <br />
              {/* Термообробка */}
              <br />
              {/* РемонтПослуги */}
              <br />
              {/* Виготовлення */}
              <br />
              {/* Проектування */}
              <br />
              {/* Металообробка */}
              <br />
              {/* Термообробка */}
              <br />
              {/* РемонтПослуги */}
              <br />
              {/* Виготовлення */}
              <br />
              {/* Проектування */}
              <br />
              {/* Металообробка */}
              <br />
              {/* Термообробка */}
              <br />
              {/* РемонтПослугиВиготовлення */}
              <br />
              {/* Проектування */}
              <br />
              {/* Металообробка */}
            </div>
          </div>
          {/* <img className="image-2" alt="Image" src={Menu} /> */}
         <HeaderLight cls='wrp2' divClassName='dark'/>
        </div>
        <div className="text-wrapper-13">{t("Technological capabilities")}</div>
        <div className="text-wrapper-14">{t("Company's activities")}</div>
        <div className="overlap-2">
          <div className="text-wrapper-15">{t("Company’s history")}</div>
          <div className="overlap-wrapper">
            <div className="overlap-3">
              <div className="rectangle-6" />
              <img
                className="element-removebg-preview"
                alt="Element removebg preview"
                src={Vagon4}
              />
              <p className="text-wrapper-16">
                {t("The main principles")} <br />
                {t("LLC FPC PROMGROUP MANUFACTURING")}:
                <br />
                <br />+
                {t("Quality")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+
                {t("EXPERIENCE")}
                <br />
                <br />+ {t("Reliability")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +
                {t("Relevance")}
              </p>
            </div>
          </div>
        </div>
        <div className="text-wrapper-17">{t("Expert in the")}</div>
        <div className="view-2">
          <div className="div-wrapper">
            <div className="text-wrapper-18">2023</div>
          </div>
          <div className="overlap-4">
            <div className="text-wrapper-19">2016</div>
          </div>
          <div className="overlap-5">
            <div className="text-wrapper-20">2014</div>
          </div>
          <div className="overlap-6">
            <div className="text-wrapper-21">2012</div>
          </div>``
          <div className="overlap-7">
            <p className="text-wrapper-22">
              {/* ТОВ ВИРОБНИЧА КОМПАНІЯ ПРОМГРУП, як виробника запчастин та обладнання для гірничодобувних підприємств */}
              {t("of LLC FPC")}
            </p>
            <div className="text-wrapper-23">{t("Establishment")}</div>
          </div>
          <div className="overlap-8">
            <p className="text-wrapper-24">{t("of a quality")}</p>
            <div className="text-wrapper-23">{t("Implementation")}</div>
          </div>
          <div className="overlap-9">
            <p className="text-wrapper-25">
              {/* виробничого потенціалу підприємства шляхом введення в експлуатацію сучасного ливарного комплексу та
              можливістю використання виливків більш високої якості, реогранізація у ТОВ ЛИВАРНО ВИРОБНИЧА КОМПАНІЯ
              ПРОМГРУП */}
              {t("of the company's")}
            </p>
            <div className="text-wrapper-26">{t("Expansion")}</div>
          </div>
          <div className="overlap-10">
            <p className="text-wrapper-27">
              {/* при використанні досвіду і передових технологій виробництва, беззупинне підвищення якості продукції та
              кваліфікації наших спеціалістів */}
              {t("based on experience")}
            </p>
            <div className="text-wrapper-28">{t("Development")}</div>
          </div>
          <img className="line-3" alt="Line" src={Line84}/>
          <img className="line-4" alt="Line" src={Line83}/>
          <img className="line-5" alt="Line" src={Line84}/>
          <img className="line-6" alt="Line" src={Line81} />
          <img className="line-7" alt="Line" src={Line81} />
        </div>
        <img
          className="element-5"
          alt="Element"
          src={Square}
        />
        <img className="IMG" alt="Img" src={VagonetkaPe}/>
        <img
          className="element-6"
          alt="Element"
          src={Black}
        />
        <img
          className="element-a"
          alt="Element"
          src={Koleso}
        />
        <img className="image-3" alt="Image" src={BarabanSmall}/>
        <img className="photo-2" alt="Photo" src={Val} />
        <img className="dscn" alt="Dscn" src={Dskn} />
        <div className="rectangle-7" />
        <div className="rectangle-8" />
        
      </div>
      
    </div>


    )
}

export default AboutCompany