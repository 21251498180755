import React from 'react'
import SharoYl from '../imagesMetalurgicalProd/1.png'
import Vizok from '../imagesMetalurgicalProd/viber-2023-02-16-12-48-06-144-2.png'
import Pech from '../imagesMetalurgicalProd/1000x800-img-7267-1.png'
import Rec13 from '../imagesMetalurgicalProd/rectangle-13.svg'
import Menu from '../imagesMetalurgicalProd/2x.png'
import Logo from '../imagesMetalurgicalProd/2.svg'
import LogoDark from '../imagesMetalurgicalProd/3.svg'
import Line15 from '../imagesMetalurgicalProd/line-15.svg'
import Line16 from '../imagesMetalurgicalProd/line-16.svg'

import { DivWrapper } from "../components/DivWrapper10";
import { ImgWrapper } from "../components/ImgWrapper10";
import "./mettalurgicalp.css";
import Footer from './Footer'
import {Header} from './Header/Header'
import { useTranslation } from 'react-i18next'

const MetallurgicalProduction = ()=>{
  const {t} = useTranslation()
    return(
       
            <div className="mettalurgicalp">
              <div className="div">
                <div className="view">
                  <div className="overlap">
                    <div className="overlap-group">
                      <div className="rectangle" />
                      <img className="img" alt="Element" src={SharoYl} />
                      <div className="rectangle-2" />
                      <div className="text-wrapper-2">{t("Dust collector (Butara)")}</div>
                      <p className="p">
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП </span>
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП</span>
                      </p>
                    </div>
                    <p className="element">
                      {/* Основні характеристики: */}
                      {t("Main specifications butara")}
                      <br />
                      {/* Вага: 532 / 1153 кг */}
                      {t("Weight532")}
                      <br />
                      {/* Діаметр отворів: 10 / 40 мм */}
                      {t("Diametr10")}
                      <br />
                      <br />
                      {/* Термін виготовлення: 60 днів */}
                      {t("Manufacturing lead time: 60 days")}
                    </p>
                  </div>
                </div>
                <div className="overlap-wrapper">
                  <div className="overlap">
                    <div className="overlap-group">
                      <div className="rectangle" />
                      <img className="img" alt="Viber" src={Vizok} />
                      <div className="rectangle-3" />
                      <p className="div-2">
                        <span className="text-wrapper-4">
                          {/* Спекальний візок випалювальної машини */}
                          {t("Sintering wagon")}
                           </span>
                         <br/>
                        <span className="text-wrapper-5">
                          {/* (зварна конструкція) */}
                          {t("welded structure")}
                          </span>
                      </p>
                      <p className="div-3">
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП </span>
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП</span>
                      </p>
                    </div>
                    <p className="element-2">
                      {/* Основні характеристики: */}
                      {t("Main specifications wagon")}
                      <br />
                      {/* Вага: 1530 кг */}
                      {t("weight1530")}
                      <br />
                      {/* Ширина вагонетки: 3040 мм */}
                      {t("width3040")}
                      <br />
                      {/* Довжина: 1000 мм */}
                      {t("Length1000")}
                      <br />
                      {/* Ширина: 3219 мм */}
                      {t("width3219")}
                      <br />
                      {/* Висота: 775 мм */}
                      {t("Height")}
                      <br />
                      <br />
                      {/* Термін виготовлення: 60 днів */}
                      {t("Manufacturing lead time: 60 days")}
                    </p>
                  </div>
                </div>
                <div className="overlap-group-wrapper">
                  <div className="overlap-2">
                    <div className="overlap-3">
                      <div className="rectangle" />
                      <img className="img" alt="Element IMG" src={Pech} />
                      <div className="rectangle-4" />
                      <div className="text-wrapper-6">{t("Continuous-operation drying oven")}</div>
                      <p className="p">
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП </span>
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП</span>
                      </p>
                    </div>
                    <p className="element-3">
                      {/* Основні характеристики: */}
                      {t("Main characteristics drying")}
                      <br />
                      {/* Вага: 2200 кг */}
                      {t("Weight2200")}
                      <br />
                      {/* Продуктивність: 3 т/г */}
                      {t("Productivity3")}
                      <br />
                      {/* Потужність: 270 кВт */}
                      {t("Power")}
                      <br />
                      {/* Розмір: 2700 х 1700 мм */}
                      {t("Size2700")}
                      <br />
                      <br />
                      {/* Термін виготовлення: 90 днів */}
                      {t("Manufacturing time: 90 days")}
                    </p>
                  </div>
                </div>
                <p className="element-4">
                  {/* Деталі та вузли переробної промисловості займають значну частину виробленої та відремонтованої продукції. */}
                  {t("Details and assemblies")}
                  <br />
                  {/* Для всіх видів млинів іноземного та вітчизняного виробництва ми можемо виготовити та відремонтувати: */}
                  {t("For All mlins")}
                  <br />
                  {/* х&nbsp;&nbsp; ротори; */}
                  х&nbsp;&nbsp; {t("rotors")}
                  <br />
                  {/* х&nbsp;&nbsp; кулачок і зубчасті колеса до нього; */}
                  х&nbsp;&nbsp; {t("cams and gears for them")}
                  <br />
                  {/* х&nbsp;&nbsp; регулювальні та опорні кільця; */}
                  х&nbsp;&nbsp; {t("adjusting and support rings")}
                  <br />
                  {/* х&nbsp;&nbsp; приводний вал в зборі; */}
                  х&nbsp;&nbsp; {t("drive shafts")}
                  <br />
                  {/* х&nbsp;&nbsp; підшипникові блоки; */}
                  х&nbsp;&nbsp; {t("bearing blocks")}
                  <br />
                  {/* х&nbsp;&nbsp; конічні і шарійні втулки; */}
                  х&nbsp;&nbsp; {t("tapered and spherical bushings")}
                  <br />
                  {/* х&nbsp;&nbsp; корпуси до 20000 кг та ін. */}
                  х&nbsp;&nbsp; {t("housings up to 20000 kg and more.")}
                </p>
                <div className="div-4">
                  <div className="div-4">
                    <div className="overlap-4">
                      <img className="rectangle-5" alt="Rectangle" src={Rec13} />
                      <div className="text-wrapper-7">
                        {/* Обладнання */}
                        {t("METALLURGICAL")}
                        <br />
                        {/* металургійного */}
                        {t("mattalurgical")}
                        <br />
                        {/* виробництва */}
                        {t("EQUIP")}
                      </div>
                      <div className="text-wrapper-8">{t("PRODUCTSUPPER")}</div>
                      <div className="rectangle-6" />
                      <div className="text-wrapper-9">
                        {t("LLC")} {t("FPC Promgrupp")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("PRODUCTSUPPER")}
                      </div>
                    </div>
                    <p className="text-wrapper-10">
                      {/* Для агломераційного та металургійного виробництва наша компанія виготовляє і ремонтує наступну продукцію: */}
                      {t("For agglomeration")}
                      <br />
                      {/* х&nbsp;&nbsp; палетні вагони для твердосплавних і спікальних машин; */}
                      х&nbsp;&nbsp;{t("pallet wagons for carbide and refractory machines;")}
                      <br />
                      {/* х&nbsp;&nbsp; вали для двовальних класифікаторів з опорами, двома шиберами; */}
                      х&nbsp;&nbsp;{t("shafts for dual-classifiers with supports and two dampers;")}
                      <br />
                      {/* х&nbsp;&nbsp; піддони кільцевих охолоджувачів; */}
                      х&nbsp;&nbsp;{t("trays for ring coolers;")}
                      <br />
                      {/* х&nbsp;&nbsp; плити для відкидного екрану гарячого агломерату; */}
                      х&nbsp;&nbsp;{t("plates for hot agglomerate discharge screen;")}
                      <br />
                      {/* х&nbsp;&nbsp; лінійні охолоджувачі агломерату; */}
                      х&nbsp;&nbsp;{t("linear agglomerate coolers;")}
                      <br />
                      {/* х&nbsp;&nbsp; шлакові ковші. */}
                      х&nbsp;&nbsp;{t("slag buckets")}
                      <br />
                      <br />
                      {/* Нижче представлений загальний ілюстративний список з основною виробляємою нами продукцією категорії
                      ОБЛАДНАННЯ МЕТАЛУРГІЙНОГО ВИРОБНИЦТВА. */}
                      {t("Below is a general second")}
                    </p>
                  </div>
                <a href="/"><DivWrapper className="component-129" imgWrapperImg={Logo}/></a>  
                  {/* <img className="image" alt="Image" src={Menu} /> */}
                  <Header cls='wrp2' divClassName='light'/>
                </div>
                <div className="view-5">
                  {/* <div className="overlap-5">
                    <div className="rectangle-7" />
                    <div className="rectangle-8" />
                    <div className="text-wrapper-11">
                      ВиготовленняПроектування
                      <br />
                      МеталообробкаПослугиТермообробка
                      <br />
                      ВиготовленняРемонтПослуги
                      <br />
                      МеталообробкаТермообробкаРемонт
                      <br />
                      МеталообробкаВиготовлення
                      <br />
                      алообробкаПослугиТермообробка
                      <br />
                      ВиготовленняРемонтПослуги
                      <br />
                      МеталообробкаТермообробкаРемонт
                      <br />
                      МеталообробкаВиготовлення
                    </div>
                    <div className="text-wrapper-12">Україна, Кривий Ріг</div>
                    <p className="div-5">
                      <span className="text-wrapper-13">Г</span>
                      <span className="text-wrapper-14">
                        оловна
                        <br />
                        <br />
                        Про компанію
                        <br />
                        <br /> */}
                        {/* Продукція
                        <br />
                        <br />
                        Послуги
                        <br />
                        <br />
                        Контакти
                      </span>
                    </p>
                    <p className="element-5">
                      +38 067 638 40 80 */}
                      {/* <br />
                      +38 067 569 11 33
                      <br />
                      +38 056 493 80 39
                      <br />
                      +38 056 493 80 38
                    </p>
                    <div className="text-wrapper-15">promgrupp.pc@gmail.com</div>
                    <p className="element-6">
                      Всі права захищені. Використання матеріалів тільки з дозволу власника.
                      <br />© 2023
                    </p>
                    <img className="line" alt="Line" src={Line15} />
                    <img className="line-2" alt="Line" src={Line16} />
                    <ImgWrapper className="component-128-instance" img={LogoDark} />
                  </div> */}
                  <Footer/>
                </div>
                <div className="view-6">
                  <div className="overlap-6">
                    <p className="text-wrapper-16">
                      {/* Можливості компанії ТОВ ЛВК ПРОМГРУП не обмежуються представленим ілюстративним списком продукції
                      категорії СПЕЦІАЛІЗОВАНЕ ОБЛАДНАННЯ ТА МЕТАЛОКОНСТРУКЦІЇ. */}
                      {t("The capabilities second")}
                      <br />
                      {/* По будь-яким питанням зв’яжіться з нами в зручний для Вас спосіб. */}
                      {t("Please feel free to contact us using your")}
                    </p>
                    <div className="text-wrapper-17">{t("IMPORTANT")}!</div>
                  </div>
                </div>
              </div>
            </div>
          );
        };

export default MetallurgicalProduction