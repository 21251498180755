import React from "react";
import { Component } from "../../components/Component78";
import { ImgWrapper } from "../../components/ImgWrapper78";
import Rec13 from '../../mobImages/imagesManufacturingRepairM/rectangle-13.svg'
import Rec99 from '../../mobImages/imagesManufacturingRepairM/rectangle-99.svg'
import mask from '../../mobImages/imagesManufacturingRepairM/mask-group-1.png'
import maskSec from '../../mobImages/imagesManufacturingRepairM/mask-group.png'
import Line17 from '../../mobImages/imagesManufacturingRepairM/line-17.svg'
import OneSvg from '../../mobImages/imagesManufacturingRepairM/1.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./manufactureingRepairM.css";

export const ManufacturingRepairM = () => {
  return (
    <div className="manufacturingRM">
      <div className="div">
        <div className="overlap">
          <img className="rectangle" alt="Rectangle" src={Rec13} />
          <div className="text-wrapper-2">ПОСЛУГИ</div>
        <a href='/contacts'><div className="text-wrapper-3">Контакти</div></a>  
        <a href='/'><div className="text-wrapper-5">Продукція</div></a>  
        <a href='/services'><div className="text-wrapper-4">Послуги</div></a>  
        <a href='/about'><div className="text-wrapper-6">Про компанію</div></a> 
          {/* <Component GR="/img/gr-1.png" className="component-1" maskGroup={mask} /> */}
          <a href='/'><img style={{width:85, height:35, marginTop:60, marginLeft:15}} className="component-1" src={LogoDark}></img></a>
          <div className="rectangle-2" />
          <div className="text-wrapper-7">
            Виготовлення
            <br />
            та ремонт
          </div>
        </div>
        <div className="text-wrapper-8">
          ТОВ ЛВК
          ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ПРОДУКЦІЯ
        </div>
        <p className="element">
          Однією з основних спеціалізацій нашої компанії є виготовлення або ремонт різноманітних вузлів, машин або
          окремих частин нестандартного обладнання. <br />
          <br />
          Діяльність - це гірниче обладнання, технологічне обладнання, транспортне та підйомне обладнання та різні
          металоконструкції. Також ми виконуємо замовлення на виготовлення різної продукції інших галузей промисловості.
          <br />
          Продукція:
          <br />
          х&nbsp;&nbsp; напівмуфти, шківи;
          <br />
          х&nbsp;&nbsp; ротори;
          <br />
          х&nbsp;&nbsp; зубчасті колеса, коронні шестерні, шестерні, шестерні, вісь механізму екскаватора;
          <br />
          х&nbsp;&nbsp; блоки і напівблоки;
          <br />
          х&nbsp;&nbsp; натяжні, опорні та провідні колеса;
          <br />
          х&nbsp;&nbsp; корпуси підшипників;
          <br />
          х&nbsp;&nbsp; зварні металоконструкції опор, каркасів, корпусів;
          <br />
          х&nbsp;&nbsp; приводні, натяжні і дефлекторні барабани;
          <br />
          х&nbsp;&nbsp; колеса з роликовими підшипниками;
          <br />
          х&nbsp;&nbsp; конічні і шарійні втулки;
          <br />
          х&nbsp;&nbsp; центральні шпильки, рейкові кільця;
          <br />
          х&nbsp;&nbsp; ковші з ємністью від 4,6 м³ до 15 м³; і деталі до нього (зубці, днища, стінки);
          <br />
          х&nbsp;&nbsp; шахтні візки ВГ-1,0, ВГ-1,6, ВГ-2,5, ВГ-3,3, ВГ-4,5, Р7КМ, пасажирська вагонетка ВПШ-18;
          <br />
          х&nbsp;&nbsp; грохота вібраційні та свинцево-стрічкові;
          <br />
          х&nbsp;&nbsp; агрегати вальцьових свердлильних машин;
          <br />
          х&nbsp;&nbsp; агрегати ЕКГ-5; ЕКГ-8; ЕКГ-10;
          <br />
          х&nbsp;&nbsp; палетні вагони;
          <br />
          х&nbsp;&nbsp; колійні ланки та інші агрегати;
          <br />
          х&nbsp;&nbsp; колектор кульок і барабани;
          <br />
          х&nbsp;&nbsp; самохідні бункера;
          <br />
          х&nbsp;&nbsp; перекидачі вагонеток кругові, -одно та двохвагонні;
          <br />
          х&nbsp;&nbsp; машини погрузочні шахтні ППН3;
          <br />
          х&nbsp;&nbsp; прожарювання машин та інше,
          <br />
          Детальніше про вироблене обладнання можна дізнатись, перейшовши на вкладку Продукція.
        </p>
        <div className="overlap-2">
          <img className="img" alt="Rectangle" src={Rec99} />
          <p className="p">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-3" />
          <div className="text-wrapper-9">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-10">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="span">Г</span>
            <span className="text-wrapper-11">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-12">promgrupp.pc@gmail.com</div>
          <p className="element-2">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line" alt="Line" src={Line17} />
          <ImgWrapper className="image" img={OneSvg} />
        </div>
      </div>
    </div>
  );
};
