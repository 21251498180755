import React from "react";
import Photo from '../imagesMechanicalPr/1-5-1.png'
import Line15 from '../imagesMechanicalPr/line-15.svg'
import Line16 from '../imagesMechanicalPr/line-16.svg'
import Logo from '../imagesMechanicalPr/3.svg'
import LogoLight from '../imagesMechanicalPr/2.svg'
import Rec13 from '../imagesMechanicalPr/rectangle-13.svg'
import Rec88 from '../imagesMechanicalPr/rectangle-88.svg'
import Menu from '../imagesMechanicalPr/2x.png'
import Bolt from '../imagesMechanicalPr/viber-2023-02-23-12-20-25-066-1.png'
import Culindr from '../imagesMechanicalPr/photo-2023-04-22-21-28-35-1.png'
import Shesterna from '../imagesMechanicalPr/photo-2023-04-22-21-28-51-1.png'

import { DivWrapper } from "../components/DivWrapper16";
import { ImgWrapper } from "../components/ImgWrapper16";
 import "./mechanicalPr.css";
import Footer from "./Footer";
import { Header } from "./Header";
import { useTranslation } from 'react-i18next'

const MechanicalProcessing = () =>{
  const {t} = useTranslation()
    return(
        
            <div className="mechanical-tr">
              <div className="div">
                <p className="p">
                  {/* Ми готові прийняти замовлення на різні види обробки.  */}
                  {t("We are fully equipped to cater to a wide array of machining needs and provide an extensive range of services to meet diverse requirements.")}
                  </p>
                <div className="overlap">
                  <p className="text-wrapper-2">
                    {/* Зверніть увагу! Маса деталей для обробки - до 20 тон з точністю 6-7 квалітету. */}
                    {t("Please note that the weight of the parts for processing can be up to 20 tons with an accuracy of 6-7 grade.")}
                  </p>
                  <div className="overlap-group">
                    <div className="rectangle" />
                    <div className="rectangle-2" />
                    <div className="rectangle-3" />
                    <div className="rectangle-4" />
                    <div className="rectangle-5" />
                    <p className="element">
                      {/* виготовлення отворів та інших внутрішніх осьових поверхонь свердлильно-розточувальними верстатами  */}
                      {t("the production of holes and other internal axial surfaces using drilling and boring machines for components with a diameter of up to 800 mm")}
                      <br />
                      ф&lt;800 мм
                    </p>
                    <p className="element-2">
                      {/* токарну обробку деталей із зовнішніми циліндричними, конічними та сферичними поверхнями  */}
                      {t("turning operations for components with external cylindrical, conical, and spherical surfaces. Maximum diameter: up to 1450 mm.")}
                      <br />
                      ф&lt;1450 мм
                    </p>
                    <p className="text-wrapper-3">
                      {/* обробку плоских поверхонь за допомогою груп фрезерного, стругального та довбального верстатів; */}
                      {t("machining of flat surfaces using a range of milling, planing, and boring machines. We also offer gear machining services for components with involute tooth profiles.")}
                      <br />
                      {/* обробку деталей зуба (евольвентне зачеплення) */}
                      {t("obrabotka")}
                    </p>
                    <p className="text-wrapper-4">
                      {/* фінішну обробку за допомогою циліндричної шліфувальної машини, внутрішньої шліфувальної машини та
                      напильника */}
                      {t("finishing treatment using cylindrical grinding machines, internal grinding machines, and files.")}
                    </p>
                    <div className="text-wrapper-5"> {t("The capabilities of our machine park allow us to offer:")}</div>
                    <img className="img" alt="Element" src={Photo} />
                  </div>
                </div>
                <p className="text-wrapper-6">
                  {/* Наша компанія надає послуги з обробки деталей і ємностей без демонтажу на території замовника. Для таких робіт
                  використовуються спеціалізовані пересувні верстати токарної, свердлильної, розточувальної та фрезерної груп,
                  які виконують обробку в будь-якому положенні шляхом закріплення на самій деталі. */}
                  {t("Our company offers")}
                  <br />
                  {/* Ми маємо можливість проводити термічну обробку всіх деталей (включаючи термохімічну обробку) в печах і ДФУ. */}
                  {t("We also have the capability to perform thermal processing of all parts, including thermochemical treatment, using furnaces and controlled atmosphere systems.")}
                </p>
                <div className="view">
                    <Footer/>
                </div>
                <div className="div-3">
                  <div className="div-3">
                    <div className="overlap-2">
                      <img className="rectangle-8" alt="Rectangle" src={Rec13} />
                      <div className="text-wrapper-11">
                        {/* Механічна */}
                        {t("Mechanical processing")}
                        <br />
                        {/* обробка */}
                        {t("processing")}
                      </div>
                      <div className="text-wrapper-12">{t("SERVICESS")}</div>
                      <div className="rectangle-9" />
                      <div className="text-wrapper-13">
                        {t("LLC")} 
                        {t("FPC Promgrupp")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("SERVICESS")}
                      </div>
                    </div>
                  </div>
                 <a href = "/"> <DivWrapper className="component-95" imgWrapperImg={LogoLight}/> </a> 
                  {/* <img className="image-2" alt="Image" src={Menu} /> */}
                  <Header cls='wrp2' divClassName='light'/>
                </div>
                <div className="overlap-wrapper">
                  <div className="overlap-3">
                    <img className="rectangle-10" alt="Rectangle" src={Rec88}/>
                    <p className="text-wrapper-14">
                      {/* Заявки на прорахунок вартості механообробки деталей Ви можете відправити нам на електронну
                      адресу promgrupp.pc@gmail.com */}
                      {t("You can send requests for the cost estimation of grinding treatment for parts to us at our email address promgrupp.pc@gmail.com")}
                    </p>
                    <div className="text-wrapper-15">{t("IMPORTANT")}!</div>
                  </div>
                </div>
                <div className="rectangle-11" />
                <div className="rectangle-12" />
                <img className="viber" alt="Viber" src={Bolt} />
                <img className="photo" alt="Photo" src={Shesterna}/>
                <img className="photo-2" alt="Photo" src={Culindr} />
              </div>
            </div>
          );
        };
        

export default MechanicalProcessing