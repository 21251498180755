/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import Logo from '../../images/1.svg'
import "./style.css";

export const ImgWrapper = ({ className, img = {Logo} }) => {
  return  <img className={`img-wrapper ${className}`} alt="Img" src={img} /> ;
};

ImgWrapper.propTypes = {
  img: PropTypes.string,
};
