/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const DivWrapper = ({
  className,
  divClassName,
  divClassNameOverride,
  text = "&gt; 6",
  divClassName1,
  text1 = "років досвіду на ринку України",
}) => {
  return (
    <div className={`dvp ${className}`}>
      <div className={`div ${divClassName}`}>
        <div className={`text-wrapper ${divClassNameOverride}`}>{text}</div>
        <p className={`p ${divClassName1}`}>{text1}</p>
      </div>
    </div>
  );
};

DivWrapper.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
};
