import React from "react";
import { DivWrapper } from "../../components/DivWrapper101";
import { ImgWrapper } from "../../components/ImgWrapper101";
import Shesternia from '../../mobImages/imagesMechanical/photo-2023-04-22-21-28-51-1.png'
import Rec13 from '../../mobImages/imagesMechanical/rectangle-13.svg'
import Rec103 from '../../mobImages/imagesMechanical/rectangle-103.png'
// import Logo from '../../mobImages/imagesMechanical/group-22.png'
import Stanok from '../../mobImages/imagesMechanical/1-5-1.png'
import Boltu from '../../mobImages/imagesMechanical/photo-2023-04-22-21-28-35-1.png'
import Bolt from '../../mobImages/imagesMechanical/viber-2023-02-23-12-20-25-066-1.png'
import Rec49 from '../../mobImages/imagesMechanical/rectangle-49.svg'
import Line15 from '../../mobImages/imagesMechanical/line-15.svg'
import Line16 from '../../mobImages/imagesMechanical/line-16.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import { useTranslation } from 'react-i18next'

import "./MechanicalPrMob.css"

export const MechanicalPrM = () => {
    const {t} = useTranslation()
  return (
    <div className="LLC-FPC-m">
      <div className="div">
        <p className="element">
        {/* виготовлення отворів та інших внутрішніх осьових поверхонь свердлильно-розточувальними верстатами  */}
        {t("the production of holes and other internal axial surfaces using drilling and boring machines for components with a diameter of up to 800 mm")}

          <br />
          ф&lt;800 мм
        </p>
        <p className="p">
          {/* фінішну обробку за допомогою циліндричної шліфувальної машини, внутрішньої шліфувальної машини та напильника */}
          {t("finishing treatment using cylindrical grinding machines, internal grinding machines, and files.")}
        </p>
        <img className="image" alt="Image" src="image.png" />
        <DivWrapper className="view" divClassName="component-84" />
        {/* <DivWrapper className="component-84-instance" divClassName="component-84" text="Продукція" /> */}
       <a href='/'><div style={{position:'absolute',left:130 ,marginTop:190, fontSize:9}}>Продукція</div></a> 
       <a href='/about'><div style={{position:'absolute',left:50, marginTop:190, fontSize:9}}>Про компанію</div></a> 
       <a href='/services'><div style={{position:'absolute',left:180, marginTop:190, fontSize:9}}>Послуги</div></a> 
       <a href='/contacts'><div style={{position:'absolute',left:230, marginTop:190, fontSize:9}}>Контакти</div></a> 
        
        <DivWrapper className="design-component-instance-node" divClassName="component-84" text="Послуги" />
        <DivWrapper className="view-2" divClassName="component-84" text="Контакти" />
        <img className="photo" alt="Photo" src={Shesternia} />
        <div className="overlap">
          <img className="rectangle" alt="Rectangle" src={Rec13} />
          <img className="img" alt="Rectangle" src={Rec103} />
          <div className="text-wrapper-2">{t("SERVICESS")}</div>
          <div className="text-wrapper-3">
            {t("Mechanical processing")}
            <br />
            {t("processing")}
          </div>
          <img style={{width:85, height:35, marginTop:10}} className="group" alt="Group" src={LogoDark} />
          <div className="element-2">
            {t("LLC")} {t("FPC Promgrupp")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("")}
          </div>
        </div>
        <p className="text-wrapper-4">Ми готові прийняти замовлення на різні види обробки.</p>
        <div className="overlap-group">
          <div className="rectangle-2" />
          <img className="element-3" alt="Element" src={Stanok} />
          <div className="text-wrapper-5">Можливості верстатного парку дозволяють:</div>
          <div className="rectangle-3" />
          <div className="rectangle-4" />
          <p className="element-4">
            виготовлення отворів та інших внутрішніх осьових поверхонь свердлильно-розточувальними верстатами <br />
            ф&lt;800 мм
          </p>
          <p className="text-wrapper-6">
            обробку плоских поверхонь за допомогою груп фрезерного, стругального та довбального верстатів;
            <br />
            обробку деталей зуба (евольвентне зачеплення)
          </p>
          <div className="rectangle-5" />
          <div className="rectangle-6" />
          <p className="text-wrapper-7">
            фінішну обробку за допомогою циліндричної шліфувальної машини, внутрішньої шліфувальної машини та напильника
          </p>
          <p className="element-5">
            токарну обробку деталей із зовнішніми циліндричними, конічними та сферичними поверхнями <br />
            ф&lt;1450 мм
          </p>
          <p className="text-wrapper-8">
            Зверніть увагу! Маса деталей для обробки - до 20 тон з точністю 6-7 квалітету.
          </p>
        </div>
        <img className="photo-2" alt="Photo" src={Boltu}/>
        <p className="text-wrapper-9">
          Наша компанія надає послуги з обробки деталей і ємностей без демонтажу на території замовника. Для таких робіт
          використовуються спеціалізовані пересувні верстати токарної, свердлильної, розточувальної та фрезерної груп,
          які виконують обробку в будь-якому положенні шляхом закріплення на самій деталі.
          <br />
          Ми маємо можливість проводити термічну обробку всіх деталей (включаючи термохімічну обробку) в печах і ДФУ.
        </p>
        <div className="rectangle-7" />
        <div className="rectangle-8" />
        <img className="viber" alt="Viber" src={Bolt} />
        <div className="overlap-group-2">
          <img className="rectangle-9" alt="Rectangle" src={Rec49} />
          <div className="rectangle-10" />
          <div className="text-wrapper-10">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-11">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="span">Г</span>
            <span className="text-wrapper-12">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <p className="element-6">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="text-wrapper-13">promgrupp.pc@gmail.com</div>
          <p className="element-7">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          
          <img className="line" alt="Line" src={Line15} />
          <ImgWrapper className="component-85" img="image-2.svg" />
          <img className="line-2" alt="Line" src={Line16} />
        </div>
      </div>
    </div>
  );
};

