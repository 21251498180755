import React from 'react'
import {Header} from './Header/Header'
import Oprokid from '../images/oprokid.png'
import Viber from '../images/viber-2023-02-16-12-48-06-144-1.png'
import Photo32 from '../images/photo-2022-01-17-11-16-00-3-1.png'
import Img2 from '../images/img-7042-3.png'
import Img from '../images/img-7044-3.png'
import SvgImg from '../images/image.svg'
import Logo from '../images/1.svg'
import Line15 from '../images/line-15.svg'
import Line16 from '../images/line-16.svg'

import Photo4 from '../images/photo-2022-01-17-11-16-00-3.png'
import Photo5 from '../images/photo-2022-01-17-11-20-08-2.png'
import Photo6 from '../images/photo-2022-01-17-11-16-01-4-1.png'
import Photo7 from '../images/photo-2021-11-11-10-18-45-2.png'
import Photo8 from '../images/photo-2021-11-11-10-18-37-2.png'
import Photo9 from '../images/photo-2021-11-11-10-18-41-1.png'
import Element1 from '../images/3-3-900-00-00-1.png'
import Call from '../images/01call-1-1.svg'
import Design from '../images/02design-1.svg'
import Create from '../images/03create-1.svg'
import Logistic from '../images/04logistic-1.svg'
import Line26 from '../images/line-26.svg'
import Line27 from '../images/line-27.svg'
import Line28 from '../images/line-28.svg'
import Rec from '../images/rectangle-98-1.svg'
import Img3 from '../images/logo.svg'
import Rec2 from '../images/rectangle-98.svg'
import Gear1 from '../images/gear-icon-gear-icon-design-illustration-gear-icon-collection-gea-2.png'
import Element2 from '../images/2023-03-15-185726172-removebg-preview-1.png'
import Image2 from '../images/image-2.png'
import Image3 from '../images/image-3.png'
import Element3 from '../images/14-08-1.png'
import ElementRe from '../images/2-15-30-removebg-preview-1.png'
import Photo from '../images/photo-2023-02-19-20-38-41-1.png'
import Element7 from '../images/2.png'
import Element8 from '../images/1-1.png'
import Photo2 from '../images/photo-2023-02-19-20-38-30-2.png'
import Photo3 from '../images/photo-2021-11-05-13-57-06-2.png'
import Element10 from '../images/1-4-5-750-01-1.png'
import Reactangle6 from '../images/rectangle-81.svg'
import RePrewie from '../images/3693-removebg-preview-1.png'
import Line29 from '../images/line-15.svg'
import HeaderTop from '../images/HeaderTop.svg'
import { useTranslation } from 'react-i18next'
import { Component335 } from "../components/Component335";
import { DivWrapper } from "../components/DivWrapper";
import { ImgWrapper } from "../components/ImgWrapper";
import './main.css'
import Footer from './Footer'

const Main = () =>{
  const {t} = useTranslation()
    return(
        // <div >
        //     <img src={Oprokid}/>
        // </div>




    <div className="screen">
      <div className="div-2">
        <div className="overlap">
          <div className="view">
            <div className="text-wrapper-3">{t("HIGH MANUFACTURING STANDARDS")}</div>
            <div className="overlap-group">
              <p className="text-wrapper-4">
                {t("Starting from the stage")}
              </p>
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <div className="group">
                    <div className="text-wrapper-5">
                      {t("A phone call")}
                      <br />
                      {t("or email")}
                    </div>
                    <img className="element" alt="Element" src={Call} />
                  </div>
                  <div className="frameq">
                    <img className="element-design" alt="Element" src={Design} />
                    <div className="textWarapper-6">
                      {t("Calculations")}
                      <br />
                      {t("and design")}
                    </div>
                  </div>
                  <div className="group-2">
                    <img className="element-create" alt="Element" src={Create} />
                    <div className="text-wrapper-7">
                      {t("Manufacturingg")}
                      <br />
                      {t("or repair")}
                    </div>
                  </div>
                  <div className="group-3">
                    <img className="element-logistic" alt="Element" src={Logistic} />
                    <div className="text-wrapper-8">
                      {t("Delivery")} <br />
                      {t("and installation")}
                    </div>
                  </div>
                  <img className="line" alt="Line" src={Line26} />
                  <img className="img" alt="Line" src={Line27} />
                  <img className="line-2" alt="Line" src={Line28} />
                </div>
              </div>
            </div>
            <div className="overlap-2">
              <p className="text-wrapper-9">
                {/* Впроваджена система якості та високих виробничих вимог гарантують надійність і довговічність нашої
                продукції. */}
                {t("The implemented quality")}
              </p>
              <div className="view-2">
                <DivWrapper
                  className="component-332"
                  divClassName="component-332-instance"
                  divClassName1="view-3"
                  divClassNameOverride="design-component-instance-node"
                  text="+10"
                  text1={
                    <>
                      {/* років досвіду на ринку <br />
                      виробників України */}
                      {t("years of experience")}
                    </>
                  }
                />
                <DivWrapper
                  className="view-4"
                  divClassName="view-5"
                  divClassName1="view-3"
                  divClassNameOverride="view-6"
                  text="+100"
                  text1={t("satisfied customers")}
                />
                <DivWrapper
                  className="view-7"
                  divClassName="view-8"
                  divClassName1="view-3"
                  divClassNameOverride="view-9"
                  text="+2000"
                  text1={t("completed projects")}
                />
              </div>
            </div>
            <p className="text-wrapper-10">
              {/* Ми розуміємо кожного нашого клієнта та знаходимо оптимальні швидкі рішення у будь-яких Ваших запитах. */}
              {t("We understand")}
            </p>
            <div className="text-wrapper-11">{t("EXPERIENCE AND PROMPTNESS")}</div>
            <div className="overlap-3">
              <p className="text-wrapper-12">+ |&nbsp;&nbsp;{t("ADVANTAGES OF LLC FPC PROMGROUP")}</p>
              <div className="text-wrapper-13">+{t("ACTUALITY")}</div>
            </div>
            <div className="text-wrapper-14">{t("FULL PRODUCTION CYCLE OF PRODUCTS")}</div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap-4">
              <img className="rectangle" alt="Rectangle" src={Oprokid} />
              
              <img className="rectangle-2" alt="Rectangle" src={HeaderTop}/>
              {/* <Component333 className="component-333-instance" divClassName="view-10" /> */}
              <Header cls='wrp' divClassName='dark'/>
              {/* <Component333 className="view-11" divClassName="view-10" text="Продукція" />
              <Component333 className="view-12" divClassName="view-10" text="Послуги" />
              <Component333 className="view-13" divClassName="view-10" text="Контакти" /> */}
              <div className="text-wrapper-15">{t("FPC Promgrupp")}</div>
              <div className="text-wrapper-16">{t("LLC")}</div>
              <p className="text-wrapper-17">
                {/* Виробник обладнання і запчастин
                <br />
                для гірничодобувних компаній. */}
                {t("Manufacturer of equipment")}
              </p>
              <div className="overlap-group-wrapper-2">
                <div className="overlap-group-3">
                  <div className="text-wrapper-90"><a href="/about"> <button className='butt'>{t("Learn more")}</button> </a></div>
                </div>
              </div>
              <Component335 className="component-335-instance" />
              <div className="view-14">
                <p className="text-wrapper-19">
                  {/* Наша компанія позиціонується як виробник запасних частин та обладнання для <br />
                  гірничо-рудної, гірничо-добувної та металургійної галузей промисловості, <br />а також виконавець
                  поточного та капітального ремонту цього обладнання, а саме конвеєрного, дробильного, кар&#39;єрних
                  екскаваторів, СБШ, іншого технологічного та нестандартного обладнання та запасних частин до нього. */}
                  {t("Our company is positioned")}
                </p>
                <p className="text-wrapper-20">
                  {/* найкращі рішення для <br />
                  вашого бізнесу */}
                  {t("The best solution")}
                </p>
              </div>
              <div className="view-15">
                <div className="text-wrapper-21">
                  {/* наша
                  <br />
                  спеціалізація */}
                  {t("Our specialization")}
                </div>
                <div className="overlap-5">
                  <div className="flexcontainer">
                    <p className="text">
                      <span className="span">
                        {t("Manufacturing and repair")}
                        <br />
                      </span>
                    </p>
                    <p className="text">
                      <span className="span">
                        {t("MACHINING")}
                        <br />
                      </span>
                    </p>
                    <p className="text">
                      <span className="span">
                        {t("Heat treatment")}
                        <br />
                      </span>
                    </p>
                    <p className="text">
                      <span className="span">
                        {t("Designing")}
                        <br />
                      </span>
                    </p>
                    <p className="text">
                      <span className="span">
                        <br />
                      </span>
                    </p>
                    <p className="text">
                      <span className="span">{""}</span>
                    </p>
                  </div>
                  <img
                    className="gear-icon-gear-icon"
                    alt="Gear icon gear icon"
                    src={Gear1}
                  />
                  <img
                    className="gear-icon-gear-icon-2"
                    alt="Gear icon gear icon"
                    src={Gear1}
                  />
                  <img
                    className="gear-icon-gear-icon-3"
                    alt="Gear icon gear icon"
                    src={Gear1}
                  />
                </div>
              </div>
              <div className="overlap-wrapper-2">
                <div className="overlap-6">
                  <div className="ellipse" />
                  <div className="ellipse-2" />
                  <img className="element-2" alt="Element" src={Element2} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-7">
          <div className="view-16">
            <div className="overlap-8">
              <img className="image-2" alt="Image" src={Image2} />
              <img className="element-3" alt="Element" src={Element3} />
              <div className="view-17">
                <div className="overlap-group-4">
                  <div className="ellipse-3" />
                  <div className="ellipse-4" />
                  <div className="ellipse-5" />
                  <div className="ellipse-6" />
                  <div className="ellipse-7" />
                </div>
              </div>
              <div className="rectangle-3" />
              <a href='/certification'><img className="image-3" alt="Image" src={Image3} /></a>
              <div className="text-wrapper-22">+{t("QUALITY")}</div>
              <div className="text-wrapper-23">+ |&nbsp;&nbsp;{t("OUR DOCUMENTATION")}</div>
            </div>
          </div>
          <div className="view-18">
            <div className="overlap-9">
              <p className="text-wrapper-24">
                {/* Оперативність та гнучкість у відносинах із замовником, розумне формування ціни, якість виконаної роботи
                – це основна стратегія розвитку підприємства, яку реалізує високий потенціал висококваліфікованого
                управлінського персоналу, інженерно-технічного та робочих спеціальностей. */}
                {t("Promptness and flexibility")}
                <br />
                <br />
                {/* Переконайтесь в цьому, ознайомившись з детальною інформацієї про компанію, наші вироби та послуги, а
                також звернувшись до нас в зручний для Вас спосіб. */}
                {t("To assure yourself of this")}
              </p>
              <div className="text-wrapper-25">{t("ABOUT US")}</div>
              <div className="overlap-group-wrapper-3">
                <div className="overlap-group-3">
                  <div className="text-wrapper-55"><a href='/about' className='butt'>{t("Learn more")}</a></div>
                </div>
              </div>
            </div>
          </div>
          <div className="view-19">
            <div className="element-4">
              <div className="overlap-group-5">
                <div className="rectangle-4" />
                <div className="rectangle-5" />
                <p className="text-wrapper-27">
                  {/* Призначений для рівномірної подачі матеріалів з бункерів, воронок та інших ємностей в робочі машини чи
                  транспортуючі засоби. В ролі транспортуючого органу використовується стрічка, виконана в формі
                  “гусеничного ланцюга” з конструкційної легованої сталі. */}
                  {t("The plate feeder")}
                </p>
                <div className="text-wrapper-28">04</div>
                <p className="element-5">
                  <span className="text-wrapper-29">
                    ___
                    <br />
                    {t("Plate")}
                    <br />
                    {t("feeder")}
                    <br />
                  </span>
                  <span className="text-wrapper-30">{t("ПП2-15-30")} </span>
                </p>
                <div className="text-wrapper-31">
                  {t("Customer")}
                  <br />
                 {t("JSC “KZRK”")}
                 
                </div>
                <p className="element-6">
                  {t("Overall dimensions fourth")}
                  <br/>
                  {t("size fourth")}
                  <br/>
                  {t("masa fourth")}
                  <br/>
                  {t("17,56")}
              
                </p>
                <img className="element-removebg" alt="Element removebg" src={ElementRe} />
                <img className="photo" alt="Photo" src={Photo} />
                <img className="element-7" alt="Element" src={Element7} />
                <img className="element-8" alt="Element" src={Element8} />
              </div>
            </div>
            <div className="element-9">
              <div className="div-3">
                <img className="element-10" alt="Element" src={Element10} />
                <div className="element-11">
                  {t("Overall dimensions third")}
                  <br />
                  {t("16000х6500х4500")}
                  <br />
                  {t("Masa third")}
                  <br />
                  {t("62")}
                </div>
                <p className="text-wrapper-32">
                  {t("The tippler is designed second")}
                </p>
                <div className="element-12">{t("according to TU U 28.2-40806615-002:2020")}</div>
                <div className="text-wrapper-33">
                {t("Customer")}
                  <br />
                 {t("JSC “KZRK”")}
                </div>
                <p className="element-13">
                  <span className="text-wrapper-29">
                    ___
                    <br />
                    {t("Two-car conveyor")} <br />
                    {t("cart")} <br />
                    {t("for unloading")}
                    <br />
                  </span>
                  <span className="text-wrapper-34">ОКЕ2-4,5-750 </span>
                  <span className="text-wrapper-29">&nbsp;</span>
                </p>
                <img className="photo-2" alt="Photo" src={Photo2} />
              </div>
              <div className="overlap-10">
                <div className="text-wrapper-35">03</div>
              </div>
              <img className="photo-3" alt="Photo" src={Photo3} />
            </div>
            <div className="element-14">
              <div className="overlap-11">
                <img className="rectangle-6" alt="Rectangle" src={Reactangle6} />
                <div className="rectangle-7" />
                <img className="element-removebg-2" alt="Element removebg" src={RePrewie} />
                <div className="text-wrapper-36">02</div>
                <p className="element-15">
                  <span className="text-wrapper-29">
                    ___
                    <br />
                    {t("Conveoyr cart for")} <br />
                    {t("unloading")}
                    <br />
                  </span>
                  <span className="text-wrapper-30">ОТ-3693</span>
                </p>
                <div className="bonfiglioli">
                  <br />{t("with Bonfiglioli motor-reducers")}
                </div>
                <div className="text-wrapper-37">
                  {t("Сustomer2")}
                  <br />
                  {t("JSC `Pivdennyy HZK`")}
                </div>
                <div className="element-16">
                  {t("Overall dimensions second")}
                  <br />
                  7790х4550х4328
                  <br />
                  {t("Masa2")}
                  <br />
                  15
                </div>
                <p className="text-wrapper-38">
                  {t("The сonveyor cart")}
                  {/* Візок розвантажувальний призначений для перевантаження вантажів з стрічкового конвеєра на іншу
                  транспортуючу машину, бункера, що входять до складу транспортуючого комплексу */}
                </p>
                <img className="photo-4" alt="Photo" src={Photo4}/>
                <img className="photo-5" alt="Photo" src={Photo5} />
                <img className="photo-6" alt="Photo" src={Photo6} />
              </div>
            </div>
            <div className="element-17">
              <div className="overlap-12">
                <div className="overlap-13">
                  <div className="div-3" />
                  <img className="element-18" alt="Element" src={Element1} />
                  <p className="element-19">
                    <span className="text-wrapper-29">
                      ___
                      <br />
                      {t("WAGON")}
                      <br />
                      {t("TIPPING")}
                      <br />
                      {t("MACHINE")}
                      <br />
                    </span>
                    <span className="text-wrapper-30">{t("ОKVM1-3,3-9")}</span>
                  </p>
                  <div className="text-wrapper-39">
                    {t("with explosion-proof")} <br />
                    {t("electrical equipment")}
                  </div>
                  <div className="text-wrapper-40">
                    {t("СustomerPJSC")}
                    <br />
                   {t("PRaT DTEK")} <br />
                    {t("PAVLOGRAD")}
                  </div>
                  <img className="photo-7" alt="Photo" src={Photo7} />
                  <img className="photo-8" alt="Photo" src={Photo8} />
                  <img className="photo-9" alt="Photo" src={Photo9} />
                  <div className="element-20">
                    {t("Overall dimensions")}
                    <br />
                    4796х4109х3926
                    <br />
                    {t("Masaov")}
                    <br />
                    13,7
                  </div>
                  <p className="text-wrapper-41">
                    {t("The tippler is designed")}
                  </p>
                </div>
                <div className="overlap-10">
                  <div className="text-wrapper-42">01</div>
                </div>
              </div>
            </div>
            <p className="text-wrapper-43">
              {t("LLC FPC PROMGROUP")} <br />
              {t("Our company forever")}
              <br />
              <br />
              {t("project bottom")}
            </p>
            <div className="text-wrapper-44">+{t("EXPERIENCE")}</div>
            <div className="text-wrapper-45">+ |&nbsp;&nbsp;{t("IMPLEMENTED PROJECTS")}</div>
          </div>
        </div>
        <div className="view-20">
      
        <Footer></Footer>
        </div>
        <div className="view-21">
          <div className="overlap-15" id='products'>
            <div className="overlap-16">
              <img className="IMG" alt="Img" src={Img} />
              <img className="IMG-2" alt="Img" src={Img2} />
              <img className="photo-10" alt="Photo" src={Photo32}/>
              <img className="viber" alt="Viber" src={Viber} />
              <img className="line-5" alt="Line" src="line-29.svg" />
              <img className="line-6" alt="Line" src="line-32.svg" />
              <img className="line-7" alt="Line" src="line-33.svg" />
              <img className="line-8" alt="Line" src="line-34.svg" />
              <div className="text-wrapper-51">
                {t("MINING")}
                <br />
                {t("EQUIPMENT")}
              </div>
              <div className="text-wrapper-52">
                {t("MINERAL")}
                <br />
                {t("PROCESSING EQUIPMENT")}
              </div>
              <div className="text-wrapper-53">
                {t("METALLURGICAL")}
                <br />
                {t("EQUIP")}
              </div>
              <div className="text-wrapper-54">{t("Specialized equipment and metal structures")}</div>
            </div>
            <div className="line-9" alt="Line"/>
            <img className="line-10" alt="Line" src="line-31.svg" />
            <img className="line-11" alt="Line" src="line-35.svg" />
            <div className="overlap-group-wrapper-4">
              <div className="overlap-group-3">
                <div className="text-wrapper-55"><a href='/miningbenefication' className='butt'>{t("More details")}</a></div>
              </div>
            </div>
            <div className="overlap-wrapper-3">
              <div className="overlap-group-3">
                <div className="text-wrapper-55"><a href='/metallurgical' className='butt'>{t("More details")}</a></div>
              </div>
            </div>
            <div className="overlap-wrapper-4">
              <div className="overlap-group-3">
                <div className="text-wrapper-55"><a className='butt' href='/miningeq'>{t("More details")}</a></div>
              </div>
            </div>
            <div className="overlap-wrapper-5">
              <div className="overlap-group-3">
                <div className="text-wrapper-55"><a href='/specialized' className='butt'>{t("More details")}</a></div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-56">+{t("REABILITI")}</div>
          <div className="text-wrapper-57">+ |&nbsp;&nbsp;{t("PRODUCT CATALOG")}</div>
        </div>
      </div>
    </div>
 

    )
}

export default Main