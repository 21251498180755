import React from 'react'
import { ImgWrapper } from '../components/ImgWrapper'

import Logo from '../images/1.svg'
import Line15 from '../images/line-15.svg'
import Line16 from '../images/line-16.svg'
import './foter.css'
import { useTranslation } from 'react-i18next'


const Footer = () =>{
  const {t} = useTranslation()
    return(
        // <div className="view-20">
        <div className="over">
          <div className="rec8" />
          <div className="rec9" />
          <div className="txtwpr46">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="txtwpr47">Україна, Кривий Ріг</div>
          <p className="vid4">
            
            <span className="txtwpr49">
              <a href='/' className='txtwpr49'>{t("Main page")}</a>
              <br />
              <br />
              <a href='/about' className='txtwpr49'>{t("About company")}</a>
              <br />
              <br />
              <a href='/#products' className='txtwpr49'>{t("Products")}</a>
              <br />
              <br />
              <a href='/services' className='txtwpr49'>{t("Services")}</a>
              <br />
              <br />
              <a href='/contacts' className='txtwpr49'>{t("Contact us")}</a>
            </span>
          </p>
          <p className="ele21">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="txtwpr50">promgrupp.pc@gmail.com</div>
          <p className="ele22">
            {t("All rights reserved")}
            <br />© 2023
          </p>
          <img class="li3" alt="Line" src={Line15} />
          <img class="li4" alt="Line" src={Line16} />
          <ImgWrapper className="comp334" img={Logo} />
        </div>
    //   </div>
    )
}

export default Footer