import React from "react";
import { ImgWrapper } from "../../components/ImgWrapper";
import mask from '../../mobImages/imagesContactsM/mask-group.png'
import Line17 from '../../mobImages/imagesContactsM/line-17.svg'
import Line66 from '../../mobImages/imagesContactsM/line-66.svg'
import Line68 from '../../mobImages/imagesContactsM/line-68.svg'
import manufacturing2 from '../../mobImages/imagesContactsM/manufacturing-2.png'
import nanufacturing1 from '../../mobImages/imagesContactsM/manufacturing-1.png'
import imager from '../../mobImages/imagesContactsM/1.png'
import image from '../../mobImages/imagesContactsM/image.png'
import x2 from '../../mobImages/imagesContactsM/2x.png'
import image18 from '../../mobImages/imagesContactsM/image-18.png'
import Rec99 from '../../mobImages/imagesContactsM/rectangle-99.svg'
import One from '../../mobImages/imagesContactsM/1.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./contactsM.css";

export const ContactsM = () => {
  return (
    <div className="contactsM">
      <div className="div">
        <div className="overlap">
          <div className="overlap-group">
            {/* <div className="text-wrapper">LLC FPC</div> */}
            {/* <div className="UP">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; UP</div>
            <div className="m">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; M</div>
            <img className="PR" alt="Pr" src="/img/pr.png" />
            <img className="GR" alt="Gr" src="/img/gr.png" />
            <img className="mask-group" alt="Mask group" src={mask} /> */}
            <img className="mask-group" src={Logo}/>
          <a href='/about'> <div className="text-wrapper-2">Про компанію</div></a> 
          <a href='/'><div className="text-wrapper-3">Продукція</div></a>  
            <div className="rectangle" />
            <img className="line" alt="Line" src={Line66}/>
            <div className="text-wrapper-4">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослугиВиготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
            <img className="manufacturing" alt="Manufacturing" src={manufacturing2} />
            <img className="img" alt="Manufacturing" src={nanufacturing1} />
            <div className="text-wrapper-5">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
            <div className="rectangle-2" />
            <div className="rectangle-3" />
            <div className="text-wrapper-6">
              ТОВ ЛВК
              ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;АКТУАЛЬНІСТЬ
            </div>
            <div className="text-wrapper-7">Контакти</div>
          </div>
        <a href='/services'> <div className="text-wrapper-8">Послуги</div></a> 
        <a href='/contacts'> <div className="text-wrapper-9">Контакти</div></a> 
        </div>
        <p className="p">
          Хочете про щось запитати?
          <br />
          Звяжіться з нами. <br />
          Ми будемо раді відповісти на будь-які Ваші питання.
        </p>
        <div className="text-wrapper-10">Як зв’язатись з нами?</div>
        <img className="image" alt="Image" src={imager} />
        <p className="element">
          +38 067 569 11 33
          <br />
          +38 067 638 40 80
          <br />
          +38 056 493 80 39
          <br />
          +38 056 493 80 38
        </p>
        <img className="image-2" alt="Image" src={image} />
        <img className="image-3" alt="Image" src={x2} />
        <p className="element-2">
          50000, Україна, <br />
          Дніпропетровська область,
          <br />
          місто Кривий Ріг, <br />
          вулиця Свято-Миколаївська, 55
        </p>
        <div className="text-wrapper-11">promgrupp.pc@gmail.com</div>
        <img className="line-2" alt="Line" src={Line68} />
        <img className="line-3" alt="Line" src={Line68} />
        <div className="overlap-2">
          <div className="rectangle-4" />
          <img className="image-4" alt="Image" src={image18} />
          <div className="text-wrapper-12">Де знаходиться наше виробництво?</div>
        </div>
        <div className="overlap-3">
          <img className="rectangle-5" alt="Rectangle" src={Rec99} />
          <p className="element-3">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-6" />
          <div className="text-wrapper-13">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-14">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="span">Г</span>
            <span className="text-wrapper-15">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-16">promgrupp.pc@gmail.com</div>
          <p className="element-4">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line-4" alt="Line" src={Line17} />
          <ImgWrapper className="component-78" img={One} />
        </div>
      </div>
    </div>
  );
};
