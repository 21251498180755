import React, { useState, useEffect } from 'react'
import {Routes, Navigate, Route} from 'react-router-dom'
import AboutCompany from '../pages/AboutCompany'
import Certification from '../pages/Certification'
import Contacts from '../pages/Contacts'
import DesignEngineering from '../pages/DesignEngineering'
import GrindingTreatment from '../pages/GrindingTreatment'
import HeatTreatment from '../pages/HeatTreamtent'
import Main from '../pages/Main'
import ManufacturingRepair from '../pages/ManufacturingRepair'
import MechanicalProcessing from '../pages/MechanicalProcesisng'
import MetallurgicalProduction from '../pages/MetallurgicalProduction'
import MiningBenefication from '../pages/MiningBenefication'
import MiningEquipment from '../pages/MiningEquipment'
import Partners from '../pages/Partners'
import PlasmaCutting from '../pages/PlasmaCutting'
// import Services from '../pages/services/Services'
import Services from '../pages/Services'
import SpecializedEquipment from '../pages/SpecializedEquipment'


import { publicRoutes } from '../routes'
import { MAIN } from './utils/consts' 
import { MainM } from '../pages/mobilePages/ MainM'
import {ServicesM} from '../pages/mobilePages/ServicesM'
import {MechanicalPrM} from '../pages/mobilePages/MechanicalPrM'
import {MiningEquipmentM} from '../pages/mobilePages/MiningEquipmentM'
import {PlasmaCuttingM} from '../pages/mobilePages/PlasmaCuttingM'
import {AboutM} from '../pages/mobilePages/AboutM'
import {PartnesM} from '../pages/mobilePages/PartnersM'
import {SertificationM} from '../pages/mobilePages/SertificationM'
import { ContactsM } from '../pages/mobilePages/ContactsM'
import { MiningBeneficationM } from '../pages/mobilePages/MiningBeneficationM'
import { MettalurgicalProductionM } from '../pages/mobilePages/MettalurgicalProductionM'
import { SpecializedM } from '../pages/mobilePages/SpecializedM'
// import { ManufacturingRepairdM} from '../../mobilePages/'
import {ManufacturingRepairM} from '../pages/mobilePages/ManufacturingRepairM'
import { DesignEngineeringM } from '../pages/mobilePages/DesignEngineeringM'

function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return size;
}


const AppRouter = () =>{

    const [width, height] = useWindowSize();
    const isMobile = width < 768; 



    return(

        <Routes>   
            {isMobile ? (
                 <>
                 <Route path="/" element={<MainM/>} />
                 <Route path="/mechanical" element={<MechanicalPrM />} />
                 <Route path="/miningeq" element={<MiningEquipmentM/>} />
                 <Route path='/services' element={<ServicesM />}/>
                 <Route path='/plasma' element={<PlasmaCuttingM/>} />
                 <Route path='/about' element={<AboutM/>}/>
                 <Route path='/partners' element={<PartnesM/>}/>
                 <Route path='/certification' element={<SertificationM/>}/>
                <Route path='/contacts' element={<ContactsM/>}/>
                <Route path='/miningbenefication' element={<MiningBeneficationM/>}/>
                <Route path='/metallurgical' element={<MettalurgicalProductionM/>}/>
                <Route path='/specialized' element={<SpecializedM/>}/>
                <Route path='/manufacturing' element={<ManufacturingRepairM/>}/>
                <Route path='/design' element={<DesignEngineeringM/>}/>
             </>
            ):(
                <>
                <Route path="/" element={<Main/>}/>
                <Route path="/about" element={<AboutCompany/>} />
                <Route path="/services" element={<Services/>} />
                <Route path="/partners" element={<Partners/>} />
                <Route path="/certification" element={<Certification/>} />
                <Route path="/contacts" element={<Contacts/>} />
                <Route path="/miningeq" element={<MiningEquipment/>} />
                <Route path="/plasma" element={<PlasmaCutting/>}  />
                <Route path="/miningbenefication" element={<MiningBenefication/>} />
                <Route path="/metallurgical" element={<MetallurgicalProduction/>} />
                <Route path="/specialized" element={<SpecializedEquipment/>} />
                <Route path="/manufacturing" element={<ManufacturingRepair/>} />
                <Route path="/design" element={<DesignEngineering/>} />
                <Route path="/heatreatment" element={<HeatTreatment/>} />
                <Route path="/gritreatment" element={<GrindingTreatment/>} />
                <Route path="/mechanical" element={<MechanicalProcessing/>} />
                </>
            )}         
               
              
            {/* <Navigate to={Main}/> */}
        </Routes>
    )

}

export default AppRouter