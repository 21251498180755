import PropTypes from "prop-types";
import React from "react";
import "./DivWrapper.css";

export const DivWrapper = ({ className, divClassName, text = "Про компанію" }) => {
  return (
    <div className={`div-wrapper ${className}`}>
      <div className={`text-wrapper ${divClassName}`}>{text}</div>
    </div>
  );
};

DivWrapper.propTypes = {
  text: PropTypes.string,
};
