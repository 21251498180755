import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import './header.css'


export const Header = ({cls, divClassName}) =>{
  


    const { i18n } = useTranslation();
  
    const changeLanguage = (language) => {
      i18n.changeLanguage(language);
    };
    const {t} = useTranslation()
  return (
    <div className={`${cls}`}>
        <a className={`${divClassName}`}  href='/about'>{t("About company")}</a>
        <a className={`${divClassName}`} style={{marginLeft:'18px'}} href='/'>{t("Products")}</a>
        <a className={`${divClassName}`} style={{marginLeft:'18px'}} href='/services'> {t("Services")}</a>
        <a className={`${divClassName}`} style={{marginLeft:'18px'}} href='/contacts'>{t("Contact us")}</a>
     
          <button className='butDark' style={{marginLeft:'250px'}} onClick={()=>{ changeLanguage('eng')}}>Eng</button>
          <button className='butDark' style={{marginLeft:'15px'}} onClick={()=>{ changeLanguage('ru')}}>Ру</button>
          <button className='butDark' style={{marginLeft:'15px'}} onClick={()=>{ changeLanguage('ua')}}>Укр</button>
    
      
    </div>
    
  );
}

Header.propTypes = {
  text: PropTypes.string,
};









