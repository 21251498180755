import React from "react";
import { Component } from "../../components/Component77";
import { ImgWrapper } from "../../components/ImgWrapper77";
import Rec13 from '../../mobImages/imagesDesignEngineeringM/rectangle-13.svg'
import Rec99 from '../../mobImages/imagesDesignEngineeringM/rectangle-99.svg'
import mask from '../../mobImages/imagesDesignEngineeringM/mask-group-1.png'
import kysokOprokida from '../../mobImages/imagesDesignEngineeringM/image-23.png'
import kysokDva from '../../mobImages/imagesDesignEngineeringM/photo-2022-01-17-11-20-09-1.png'
import kysokThree from '../../mobImages/imagesDesignEngineeringM/3693-1.png'
import raschetu from '../../mobImages/imagesDesignEngineeringM/raschety-na-nagruzki-1.png'
import Line17 from '../../mobImages/imagesDesignEngineeringM/line-17.svg'
import oneSvg from '../../mobImages/imagesDesignEngineeringM/1.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./designEngineeringM.css";

export const DesignEngineeringM = () => {
  return (
    <div className="designM">
      <div className="div">
        <div className="overlap">
          <img className="rectangle" alt="Rectangle" src={Rec13} />
          <div className="text-wrapper-2">ПОСЛУГИ</div>
        <a href="/contacts"><div className="text-wrapper-3">Контакти</div></a>  
        <a href="/services"> <div className="text-wrapper-4">Послуги</div></a> 
        <a href="/"><div className="text-wrapper-5">Продукція</div></a>  
        <a href="/about"><div className="text-wrapper-6">Про компанію</div></a>  
          {/* <Component GR="/img/gr-1.png" className="component-1" maskGroup={mask} /> */}
          <img className="component-1" src={LogoDark}></img>
          <div className="rectangle-2" />
          <div className="text-wrapper-7">
            Розрахунок та
            <br />
            проектування
          </div>
        </div>
        <div className="text-wrapper-8">
          ТОВ ЛВК
          ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ПРОДУКЦІЯ
        </div>
        <div className="overlap-2">
          <p className="p">
            Висококваліфіковані спеціалісти ТОВ ЛВК ПРОМГРУП мають великий практичний досвід і володіють сучасними
            методиками та новітніми програмними продуктами. Спеціалізуються на розробці конструкторської документації
            для виробництва різних видів продукції.
          </p>
          <div className="text-wrapper-9">Сучасні конструкторські рішення</div>
        </div>
        <img className="element" alt="Element" src={kysokThree} />
        <img className="image" alt="Image" src={kysokOprokida} />
        <div className="overlap-3">
          <img className="photo" alt="Photo" src={kysokDva} />
          <img className="raschety-na-nagruzki" alt="Raschety na nagruzki" src={raschetu} />
          <div className="rectangle-3" />
          <p className="text-wrapper-10">
            Наша компанія розробляє конструкторську документацію на обладнання, машини, металоконструкції для
            металургійної, машинобудівної, енергетичної промисловості.
            <br />У разі виготовлення нестандартного або спеціального обладнання, ми можемо виконати розрахунок і
            проектування вузлів і механізмів згідно технічного завдання замовника.
          </p>
          <div className="text-wrapper-11">Розширений спектр можливостей</div>
        </div>
        <div className="overlap-4">
          <img className="img" alt="Rectangle" src={Rec99} />
          <p className="element-2">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-4" />
          <div className="text-wrapper-12">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-13">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="span">Г</span>
            <span className="text-wrapper-14">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-15">promgrupp.pc@gmail.com</div>
          <p className="element-3">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line" alt="Line" src={Line17} />
          {/* <ImgWrapper className="component-82" img={oneSvg} /> */}
          <img className="component-82" src={Logo}></img>
        </div>
      </div>
    </div>
  );
};
