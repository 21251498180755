import React from "react";
import "./Component.css";

export const Component = ({ className }) => {
  return (
    <div className={`component ${className}`}>
      <div className="overlap-group-2">
        <div className="text-wrapper">LLC FPC</div>
        <div className="m">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; M</div>
        <div className="UP">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; UP</div>
        <img className="PR" alt="Pr" src="/img/pr.png" />
        <img className="GR" alt="Gr" src="/img/gr.png" />
        <img className="group" alt="Group" src="/img/group-24.png" />
        <img className="mask-group" alt="Mask group" src="/img/mask-group.png" />
      </div>
    </div>
  );
};
