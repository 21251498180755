import React from "react";

import { ImgWrapper } from "../components/ImgWrapper3";
import Point from '../imagesServices/line-16-1.png'
import Point2 from '../imagesServices/3.png'
import Line15 from '../imagesServices/line-15.svg'
import Line16 from '../imagesServices/line-16.svg'
import Logo from '../imagesServices/2.svg'
import LogoSec from '../imagesServices/1.svg'
import backgr from '../imagesServices/rectangle-86.png'
import backgrq from '../imagesServices/rectangle-87.png'
import Menu from '../imagesServices/2x.png'
import Head from '../imagesServices/line-66.svg'
import './styleThird.css'
import Footer from "./Footer";
import {Header} from "./Header/Header";
import { useTranslation } from 'react-i18next'
import HeaderLight from "./Header/HeaderLight";
const Services = () =>{
  const {t} = useTranslation()
    return(

    <div className="serv">
      <div className="div">
        <div className="view">
          <div className="overlap-group">
            <div className="rectangle" />
            <div className="rectangle-2" />
            <div className="text-wrapper-2">
              ВиготовленняПроектування
              <br />
              МеталообробкаПослугиТермообробка
              <br />
              ВиготовленняРемонтПослуги
              <br />
              МеталообробкаТермообробкаРемонт
              <br />
              МеталообробкаВиготовлення
              <br />
              алообробкаПослугиТермообробка
              <br />
              ВиготовленняРемонтПослуги
              <br />
              МеталообробкаТермообробкаРемонт
              <br />
              МеталообробкаВиготовлення
            </div>
            <div className="text-wrapper-3">
              {/* Україна, Кривий Ріг */}
              {t("Ukraine")}
              </div>
            <p className="p">
              <span className="span"></span>
              <span className="text-wrapper-4">
                {t("Main page")}
                <br />
                <br />
                <br />
                {t("Products")}
                <br />
                <br />
                <br />
                {/* Послуги */}
                {t("Services")}
                <br />
                <br />
                <br />
                {/* Контакти */}
                {t("Contact us")}
              </span>
            </p>
            <p className="element">
              +38 067 638 40 80
              <br />
              +38 067 569 11 33
              <br />
              +38 056 493 80 39
              <br />
              +38 056 493 80 38
            </p>
            <div className="text-wrapper-5">promgrupp.pc@gmail.com</div>
            <p className="element-2">
              {/* Всі права захищені. Використання матеріалів тільки з дозволу власника. */}
              {t("All rights reserved")}
              <br />© 2023
            </p>
          </div>
          <img className="line" alt="Line" src={Point} />
          <img className="img" alt="Line" src={Point} />
          <ImgWrapper className="image" img={Point2} />
        </div>
        <p className="text-wrapper-6">
          {/* ТОВ ЛВК ПРОМГРУП надає широкий спектр послуг - від стадії проектування до етапу монтажу, а також виконує
          послуги, такі як: механообробка, плазмове різання, шліфувальна обробка та термообробка, в тому числі СВЧ. */}
          {t("LLC FPC PROMGROUP offers")}
          <br />
          <br />
          {/* Дізнайтесь більше, перейшовши по активним посиланням нижче. */}
          {t("Learn more by clicking on the active link below")}
        </p>
        <div className="text-wrapper-7">
          ___
          <br />
          {t("Manufacturingg")} <br />
          {/* та ремонт */}
          {t("and repair")}
        </div>
        <div className="text-wrapper-8">
          ___
          <br />
          {/* Проектування */}
          {t("Design and engineering")}
          <br />
          {/* та розрахунки */}
          {t("and engineering")}
        </div>
        <div className="text-wrapper-9">
          ___
          <br />
          {t("Plasma cutting")}
          <br />
          {t("cutting")}
        </div>
        <div className="text-wrapper-10">
          ___
          <br />
          {t("Grinding")}
          <br />
          {t("processing")}
        </div>
        <div className="text-wrapper-11">
          ___
          <br />
          {t("Mechanical processing")}
          <br />
          {t("processing")}
        </div>
        <div className="text-wrapper-12">
          ___
          <br />
          {t("Heat treatment and microwave heat treatment")}
          <br />
          {t("СВЧ")}
        </div>
        <p className="text-wrapper-13">
          {/* Однією з основних спеціалізацій нашої компанії є виготовлення або ремонт різноманітних вузлів, машин або
          окремих частин нестандартного обладнання. */}
          {t("One of the main")}
        </p>
        <p className="text-wrapper-14">
          {/* Висококваліфіковані спеціалісти ТОВ ЛВК ПРОМГРУП мають великий практичний досвід та володіють сучасними
          методиками і новітніми програмними продуктами. */}
          {t("The highly skilled")}
        </p>
        <p className="text-wrapper-15">
          {/* Застосовуємо сучасний технологічний процес металообробки, при якому в якості різця використовується потік
          плазми. */}
          {t("We employ")}
        </p>
        <p className="text-wrapper-16">
          {/* Ми готові прийняти замовлення на різні види обробки. Маса деталей для обробки - до 20 тонн з точністю 6-7
          квалітету. */}
          {t("We are ready")}
        </p>
        <p className="text-wrapper-17">
          {/* Виконуємо на замовлення основні види термообробки - відпуск, відпал, гартування, нормалізація, цементація, а
          також виконуємо СВЧ деталей. */}
          {t("We provide customized")}
        </p>
        <p className="text-wrapper-18">
          {/* Кругло-шліфувальна та плоско-шліфувальна обробка для деталей як серійного, так і одиничного виробництва. */}
          {t("We offer both")}
        </p>
        <div className="overlap-wrapper">
          <div className="overlap">
            <div className="text-wrapper-19"><a href="/manufacturing" className="link">{t("More details")}</a></div>
          </div>
        </div>
        <div className="overlap-group-wrapper">
          <div className="overlap">
            <div className="text-wrapper-19"><a href='/mechanical' className="link">{t("More details")}</a></div>
          </div>
        </div>
        <div className="overlap-wrapper-2">
          <div className="overlap">
            <div className="text-wrapper-20"><a href='/heatreatment' className='link'>{t("More details")}</a></div>
          </div>
        </div>
        <div className="overlap-wrapper-3">
          <div className="overlap">
            <div className="text-wrapper-19"><a href='/gritreatment' className="link">{t("More details")}</a></div>
          </div>
        </div>
        <div className="overlap-wrapper-4">
          <div className="overlap">
            <div className="text-wrapper-20"><a href='/design' className="link">{t("More details")}</a></div>
          </div>
        </div>
        <div className="overlap-wrapper-5">
          <div className="overlap">
            <div className="text-wrapper-19"><a className="link" href="/plasma">{t("More details")}</a></div>
          </div>
        </div>
        <div className="view-2">
            <Footer/>
        </div>
        <div className="overlap-3">
          <div className="overlap-3">
            <div className="rectangle-5" />
            <img className="rectangle-6" alt="Rectangle" src={backgr} />
            <img className="rectangle-7" alt="Rectangle" src={backgrq} />
            <div className="rectangle-8" />
            <div className="rectangle-9" />
            <div className="text-wrapper-24">{t("Services")}</div>
            <div className="text-wrapper-25">
              {/* ТОВ ЛВК
              ПРОМГРУП */}
              {t("LLC FPC PROMGROUP MANUFACTURING")}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("MANUFACTURING")}
            </div>
           <a href="/" >  <ImgWrapper className="component-132-instance" img={LogoSec} />  </a> 
            <img className="line-4" alt="Line" src={Head} />
            <div className="text-wrapper-26">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослугиВиготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
          </div>
          {/* <img className="image-2" alt="Image" src={Menu} /> */}
          <HeaderLight cls='wrp2' divClassName='dark'/>
        </div>
        <p className="text-wrapper-27">{t("We possess comprehensive")}</p>
      </div>
    </div>


    )
}

export default Services