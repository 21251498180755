import React from "react";
import Raschety from '../imagesDesignEng/raschety-na-nagruzki-1.png'
import Photo from '../imagesDesignEng/photo-2022-01-17-11-20-09-1.png'
import Line15 from '../imagesDesignEng/line-15.svg'
import Line16 from '../imagesDesignEng/line-16.svg'
import Logo from '../imagesDesignEng/3.svg'
import LogoDark from '../imagesDesignEng/2.svg'
import Menu from '../imagesDesignEng/2x.png'
import Rec13 from '../imagesDesignEng/rectangle-13.svg'
import Chertej from '../imagesDesignEng/3693-1.png'
import ChertejSec from '../imagesDesignEng/image-23.png'

import { DivWrapper } from "../components/DivWrapper13";
import { ImgWrapper } from "../components/ImgWrapper13";
import HeaderFullScreen from '../imagesDesignEng/HeaderFullScreen.svg'
import Teleshka from '../imagesDesignEng/Teleshka.svg'
import { Header } from "./Header";
import "./designEng.css";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next'

const DesignEngineering = () =>{
  const {t} = useTranslation()

    return(
      
            <div className="design">
              
              <div className="div">
                <div className="overlap">
                  <p className="p">
                    {/* Висококваліфіковані спеціалісти ТОВ ЛВК ПРОМГРУП мають великий практичний досвід і володіють сучасними
                    методиками та новітніми програмними продуктами. Спеціалізуються на розробці конструкторської документації
                    для виробництва різних видів продукції. */}
                    {t("Highly qualified specialists")}
                  </p>
                  <div className="text-wrapper-2">
                    {/* Сучасні конструкторські рішення */}
                    {t("Modern engineering solutions")}
                    </div>
                </div>
                <div className="overlap-group">
                  <div className="rectangle" />
                  <div className="text-wrapper-3">
                    {/* Розширений спектр можливостей */}
                    {t("Expanded range of capabilities")}
                    </div>
                  <p className="text-wrapper-4">
                    {/* Наша компанія розробляє конструкторську документацію на обладнання, машини, металоконструкції для
                    металургійної, машинобудівної, енергетичної промисловості. */}
                    {t("Our company specializes")}
                    <br />
                    {/* У разі виготовлення нестандартного або спеціального обладнання, ми можемо виконати розрахунок і
                    проектування вузлів і механізмів згідно технічного завдання замовника. */}
                    {t("Y razi")}
                  </p>
                  <img className="raschety-na-nagruzki" alt="Raschety na nagruzki" src={Raschety} />
                  <img className="photo" alt="Photo" src={Photo} />
                </div>
                <div className="view">
                    <Footer/>
                 
                 
                </div>
                <div className="div-3">
                  <div className="div-3">
                    <div className="overlap-2">
                      <img className="rectangle-4" alt="Rectangle" src={Teleshka} />
                      <div className="text-wrapper-9">
                        {t("Calculations")}
                        <br />
                        {t("and design")}
                      </div>
                      <div className="text-wrapper-10">{t("SERVICESS")}</div>
                      <div className="rectangle-5" />
                      <div className="text-wrapper-11">
                        {t("LLC")} {t("FPC Promgrupp")}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("SERVICESS")}
                      </div>
                    </div>
                  </div>
                  <a href="/"> <DivWrapper className="component-74" imgWrapperImg={LogoDark} /> </a>
                  {/* <img className="image-2" alt="Image" src={Menu} /> */}
                  <Header cls='wrp' divClassName='light'/>
                </div>
                <img className="element-3" alt="Element" src={Chertej} />
                <img className="image-3" alt="Image" src={ChertejSec}/>
              </div>
            </div>
          );
        };
        

export default DesignEngineering