import React from "react";
import Line15 from '../imagesManufacturingRe/line-15.svg'
import Line16 from '../imagesManufacturingRe/line-16.svg'
import Logo from '../imagesManufacturingRe/3.svg'
import Rec13 from '../imagesManufacturingRe/rectangle-13.svg'
import LogoSec from '../imagesManufacturingRe/2.svg'
import Menu from '../imagesManufacturingRe/2x.png'
import { useTranslation } from 'react-i18next'
import { DivWrapper } from "../components/DivWrapper12";
import { ImgWrapper } from "../components/ImgWrapper12";
import "./ManufacturingRe.css";
import Footer from "./Footer";
import { Header } from "./Header";

const ManufacturingRepair = () =>{
  const {t} = useTranslation()
 
 return(
        <div className="manufacturing-re">
          <div className="div">
            <p className="element">
              {/* Однією з основних спеціалізацій нашої компанії є виготовлення або ремонт різноманітних вузлів, машин або
              окремих частин нестандартного обладнання. */}
              {t("One of the main second")}
               <br />
              <br />
              {/* Діяльність - це гірниче обладнання, технологічне обладнання, транспортне та підйомне обладнання та різні
              металоконструкції. Також ми виконуємо замовлення на виготовлення різної продукції інших галузей промисловості. */}
              {t("Our activities cover")}
              <br />
              {t("Products")}:
              <br />
              {/* х&nbsp;&nbsp; напівмуфти, шківи; */}
              х&nbsp;&nbsp;{t("Flanges and pulleys")}
              <br />
              {/* х&nbsp;&nbsp; ротори; */}
              х&nbsp;&nbsp;{t("rotors")}
              <br />
              {/* х&nbsp;&nbsp; зубчасті колеса, коронні шестерні, шестерні, шестерні, вісь механізму екскаватора; */}
              х&nbsp;&nbsp;{t("Gears, crown gears, pinions, excavator mechanism axles")}
              <br />
              {/* х&nbsp;&nbsp; блоки і напівблоки; */}
              х&nbsp;&nbsp;{t("Blocks and half-blocks")}
              <br />
              {/* х&nbsp;&nbsp; натяжні, опорні та провідні колеса; */}
              х&nbsp;&nbsp;{t("Tensioning, support, and guide wheels")}
              <br />
              {/* х&nbsp;&nbsp; корпуси підшипників; */}
              х&nbsp;&nbsp;{t("Bearing housings")}
              <br />
              {/* х&nbsp;&nbsp; зварні металоконструкції опор, каркасів, корпусів; */}
              х&nbsp;&nbsp;{t("Welded metal structures such as supports, frames, and casings")}
              <br />
              {/* х&nbsp;&nbsp; приводні, натяжні і дефлекторні барабани; */}
              х&nbsp;&nbsp;{t("Drive, tensioning, and deflection drums")}
              <br />
              {/* х&nbsp;&nbsp; колеса з роликовими підшипниками; */}
              х&nbsp;&nbsp;{t("Wheels with roller bearings")}
              <br />
              {/* х&nbsp;&nbsp; конічні і шарійні втулки; */}
              х&nbsp;&nbsp;{t("tapered and spherical bushings")}
              <br />
              {/* х&nbsp;&nbsp; центральні шпильки, рейкові кільця; */}
              х&nbsp;&nbsp;{t("Central pins, rail rings")}
              <br />
              {/* х&nbsp;&nbsp; ковші з ємністью від 4,6 м³ до 15 м³; і деталі до нього (зубці, днища, стінки); */}
              х&nbsp;&nbsp;{t("Buckets with a capacity from 4.6m³ to 15m³ and related parts (teeth, bottoms, walls)")}
              <br />
              {/* х&nbsp;&nbsp; шахтні візки ВГ-1,0, ВГ-1,6, ВГ-2,5, ВГ-3,3, ВГ-4,5, Р7КМ, пасажирська вагонетка ВПШ-18; */}
              х&nbsp;&nbsp;{t("Mining cars (VG-1.0, VG-1.6, VG-2.5, VG-3.3, VG-4.5, R7KM, passenger car VPSh-18)")}
              <br />
              {/* х&nbsp;&nbsp; грохота вібраційні та свинцево-стрічкові; */}
              х&nbsp;&nbsp;{t("Vibrating and belt screens")}
              <br />
              {/* х&nbsp;&nbsp; агрегати вальцьових свердлильних машин; */}
              х&nbsp;&nbsp;{t("Units for rolling drilling machines")}
              <br />
              {/* х&nbsp;&nbsp; агрегати ЕКГ-5; ЕКГ-8; ЕКГ-10; */}
              х&nbsp;&nbsp;{t("Units for EKG-5, EKG-8, EKG-10")}
              <br />
              {/* х&nbsp;&nbsp; палетні вагони; */}
              х&nbsp;&nbsp;{t("Pallet wagons")}
              <br />
              {/* х&nbsp;&nbsp; колійні ланки та інші агрегати; */}
              х&nbsp;&nbsp;{t("Track links and other aggregates")}
              <br />
              {/* х&nbsp;&nbsp; колектор кульок і барабани; */}
              х&nbsp;&nbsp;{t("Ball collector and drums")}
              <br />
              {/* х&nbsp;&nbsp; самохідні бункера; */}
              х&nbsp;&nbsp;{t("Self-propelled bunkers")}
              <br />
              {/* х&nbsp;&nbsp; перекидачі вагонеток кругові, -одно та двохвагонні; */}
              х&nbsp;&nbsp;{t("Circular and single/double-car tipplers")}
              <br />
              {/* х&nbsp;&nbsp; машини погрузочні шахтні ППН3; */}
              х&nbsp;&nbsp;{t("Shaft loading machines PPN3")}
              <br />
              {/* х&nbsp;&nbsp; прожарювання машин та інше, */}
              х&nbsp;&nbsp;{t("Roasting machines, and more")}
              <br />
              {/* Детальніше про вироблене обладнання можна дізнатись, перейшовши на вкладку Продукція. */}
            {t("For more detailed")}
            </p>
            <div className="view">
                <Footer/>
            </div>
            <div className="div-2">
              <div className="div-2">
                <div className="overlap">
                  <img className="rectangle-3" alt="Rectangle" src={Rec13} />
                  <div className="text-wrapper-6">
                    {/* Виготовлення */}
                    {t("Manufacturingg")}
                    <br />
                    {/* та ремонт */}
                    {t("and repair")}
                  </div>
                  <div className="text-wrapper-7">{t("SERVICESS")}</div>
                  <div className="rectangle-4" />
                  <div className="text-wrapper-8">
                    {t("LLC")} {t("FPC Promgrupp")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("SERVICESS")}
                  </div>
                </div>
              </div>
              <DivWrapper className="component-61" imgWrapperImg={LogoSec}/>
              {/* <img className="image-2" alt="Image" src={Menu} /> */}
              <Header cls='wrp2' divClassName='light'/>
            </div>
          </div>
        </div>
      );
    };
    

export default ManufacturingRepair