import React from "react";
import Photo from '../imagesGrindingTr/photo-2023-04-22-21-24-03-removebg-preview-1.png'
import PhotoSec from '../imagesGrindingTr/photo-2023-04-22-21-24-03-removebg-preview-2.png'
import Line15 from '../imagesGrindingTr/line-15.svg'
import Line16 from '../imagesGrindingTr/line-16.svg'
import Logo from '../imagesGrindingTr/3.svg'
import Rec13 from '../imagesGrindingTr/rectangle-13.svg'
import Rec88 from '../imagesGrindingTr/rectangle-88.svg'
import LogoLight from '../imagesGrindingTr/2.svg'
import Menu from '../imagesGrindingTr/2x.png'

import { DivWrapper } from "../components/DivWrapper15";
import { ImgWrapper } from "../components/ImgWrapper15";
import "./grindingTr.css";
import Footer from "./Footer";
import { Header } from "./Header";
import { useTranslation } from 'react-i18next'

const GrindingTreatment = () =>{
  const {t} = useTranslation()
  
return(
    <div className="grinding-tr">
      <div className="div">
        <div className="overlap">
          <p className="element-HRC-ra">
            {/* ТОВ ЛВК ПРОМГРУП виконує послуги кругло-шліфувальної та плоско-шліфувальної обробки деталей, як для
            серійного виробництва, так і для одиничних виробів. */}
            {t("LLC FPC PROMGROUP provides")}
            <br />
            <br />
            {/* Шліфування - це вид обробки, який здійснюється за допомогою абразивного інструменту, різальним елементом
            якого є зерна абразивних матеріалів. При обробці на шліфувальних верстатах різальним інструментом є
            абразивні шліфувальні круги, які складаються з дрібних зерен абразивних матеріалів, зцементованих сполучною
            речовиною - зв&#39;язкою. Твердість абразивних матеріалів значно вища за твердість загартованої сталі. */}
            {t("Grinding is a machining")}
            <br />
            {/* У металообробці шліфування в першу чергу застосовується для обробки плоских, конічних і циліндричних
            поверхонь і таку обробку зазвичай виконують наприкінці технологічного процесу, щоб гарантувати максимальну
            точність фінішної обробки деталей. Це необхідно, оскільки багато класичних виробничих технологій, наприклад,
            токарна обробка або фрезерування, не забезпечують необхідних значень точності або залишають на поверхні
            деталей сліди обробки (наприклад, гвинтову лінію), які потім видаляти за допомогою шліфування. */}
            {t("In metal processing")}
            <br />
            <br />
            {/* Перевагами шліфування є: */}
            {t("The advantages of grinding include")}
            <br />
            {/* 1.&nbsp;&nbsp;&nbsp;&nbsp;можливість обробляти матеріали із високою твердістю поверхні (HRC 65); */}
            1.&nbsp;&nbsp;&nbsp;&nbsp;{t("Ability to process materials with high surface hardness (HRC 65).")}
            <br />
            {/* 2.&nbsp;&nbsp; висока точність розмірів та форми оброблюваної поверхні (до 0,01 мм); */}
            2.&nbsp;&nbsp;{t("High precision in dimensions and shape of the processed surface (up to 0.01 mm).")}
            <br />
            {/* 3.&nbsp;&nbsp; мала шорсткість оброблюваної поверхні (Ra 0,4 мм). */}
            3.&nbsp;&nbsp;{t("Low roughness of the processed surface (Ra 0.4 mm).")}
          </p>
          <div className="rectangle" />
          <p className="element-l-m">
            {/* циліндричних та  */}
            {t("We offer services for cylindrical and conical surface grinding of components")}
            <br />
            {/* конусних поверхонь: */}
            {t("Konysnux")}
            <br />
            {/* ф до 250 мм  */}
            {t("Diameter up to 250 mm")}
            <br />
            {/* L до 1400 мм */}
            {t("Length up to 1400 mm")}
            <br />
            {/* m до 100 кг */}
            {t("Weight up to 100 kg")}
          </p>
          <p className="l-b-h-m">
            {/* плоских поверхонь: */}
            {t("We provide services for flat surface grinding of components")}
            <br />
            {/* L до 990 мм */}
            {t("Length up to 990 mm")}
            <br />
            {/* B до 280 мм */}
            {t("Width up to 280 mm")}
            <br />
            {/* H до 400 мм */}
            {t("Height up to 400 mm")}
            <br />
            {/* m до 220 кг */}
            {t("Weight up to 220 kg")}
          </p>
          <div className="rectangle-2" />
          <div className="rectangle-3" />
          <p className="p">
            {/* Пропонуємо послуги з кругло-шліфувальної та плоско-шліфувальної обробки деталей: */}
            {t("We provide services for both cylindrical grinding and surface grinding of components")}
          </p>
          <div className="view">
            <div className="overlap-group">
              <img className="photo" alt="Photo" src={Photo} />
              <img className="img" alt="Photo" src={PhotoSec} />
            </div>
          </div>
        </div>
        <p className="text-wrapper-2">
          {/* Кругло-шліфувальна обробка виконується кваліфікованими та досвідченими фахівцями, що забезпечує високу якість
          та швидкість виконання робіт. */}
          {t("Cylindrical grinding is performed")}
          <br />
          {/* Розрахунок вартості робіт проводиться виходячи з точності розмірів, допуску форми та необхідної шорсткості
          оброблюваної поверхні. */}
          {t("Rozraxynok")}
        </p>
        <div className="overlap-group-wrapper">
     
         
          <Footer/>
        </div>
        <div className="div-3">
          <div className="div-3">
            <div className="overlap-2">
              <img className="rectangle-6" alt="Rectangle" src={Rec13} />
              <div className="text-wrapper-7">{t("Grinding treatment")}</div>
              <div className="text-wrapper-8">{t("SERVICESS")}</div>
              <div className="rectangle-7" />
              <div className="text-wrapper-9">
                {t("LLC")} {t("FPC Promgrupp")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("SERVICESS")}
              </div>
            </div>
          </div>
          <a href="/"><DivWrapper className="component-84" imgWrapperImg={LogoLight} /></a>
          {/* <img className="image-2" alt="Image" src={Menu} /> */}
          <Header cls='wrp' divClassName='light'/>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-3">
            <img className="rectangle-8" alt="Rectangle" src={Rec88} />
            <p className="text-wrapper-10">
              {/* Заявки на прорахунок вартості шліфувальної обробки деталей Ви можете відправити нам на електронну
              адресу promgrupp.pc@gmail.com */}
              {t("You can send requests for the cost estimation of grinding treatment for parts to us at our email address promgrupp.pc@gmail.com")}
            </p>
            <div className="text-wrapper-11">{t("IMPORTANT")}!</div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default GrindingTreatment 