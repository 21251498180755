import React from "react";

import { ImgWrapper } from "../../components/ImgWrapper84";
import Line66 from '../../mobImages/imagesAboutM/line-66.svg'
import Line82 from '../../mobImages/imagesAboutM/line-82.svg'
import Line83 from '../../mobImages/imagesAboutM/line-83.svg'
import Line17 from '../../mobImages/imagesAboutM/line-17.svg'
import One from '../../mobImages/imagesAboutM/1.svg'
import Oprokid from '../../mobImages/imagesAboutM/photo-2021-11-11-10-18-40-1.png'
import Oprokid2 from '../../mobImages/imagesAboutM/2-1.png'
import RemoveBg from '../../mobImages/imagesAboutM/4-5-removebg-preview-4.png'
import dscn from '../../mobImages/imagesAboutM/dscn0460-1.png'
import barananchik from '../../mobImages/imagesAboutM/image-6487327-1.png'
import vagonetki from '../../mobImages/imagesAboutM/img-7044-6.png'
import Rec203 from '../../mobImages/imagesAboutM/rectangle-203.svg'
import Rec99 from '../../mobImages/imagesAboutM/rectangle-99.svg'
import rolik from '../../mobImages/imagesAboutM/0-02-0a-fb381f6d82274936a74f1285fe88c6241f3e6bdf7669314befd17f15.png'
import teleshka from '../../mobImages/imagesAboutM/0-02-05-0cd9e43f6bbe030808df30761fea053e6594c3feea806d5473845774.png'
import bytara from '../../mobImages/imagesAboutM/photo-2023-04-22-21-28-36-1.png'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./aboutM.css";

export const AboutM = () => {
  return (
    <div className="aboutm">
      <div className="div">
        <div className="overlap">
          <div className="overlap-group">
            {/* <div className="text-wrapper">LLC FPC</div>
            <div className="UP">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; UP</div>
            <div className="m">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; M</div>
            <img className="PR" alt="Pr" src="/img/pr.png" />
            <img className="GR" alt="Gr" src="/img/gr.png" />
            <img className="mask-group" alt="Mask group" src="/img/mask-group.png" /> */}
            <a href="/"><img className="mask-group" src={Logo}/></a>
            <a href='/about'><div className="text-wrapper-2">Про компанію</div></a>
            <a href='/'><div className="text-wrapper-3">Продукція</div></a>
            <div className="rectangle" />
            <img className="line" alt="Line" src={Line66}/>
            <div className="text-wrapper-4">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослугиВиготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
            <img className="photo" alt="Photo" src={Oprokid} />
            <img className="element" alt="Element" src={Oprokid2} />
            <div className="text-wrapper-5">Про компанію</div>
            <div className="rectangle-2" />
            <div className="text-wrapper-6">
              ТОВ ЛВК
              ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ДОСВІД
            </div>
            <div className="text-wrapper-7">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
            <div className="rectangle-3" />
          </div>
          <a href='/services'><div className="text-wrapper-8">Послуги</div></a>
         <a href='/contacts'><div className="text-wrapper-9">Контакти</div></a> 
        </div>
        <div className="text-wrapper-10">Експерт в галузі машинобудування.</div>
        <div className="text-wrapper-11">Діяльність компанії</div>
        <div className="overlap-2">
          <p className="p">
            ТОВ ЛИВАРНО-ВИРОБНИЧА КОМПАНІЯ ПРОМГРУП - підприємство, яке динамічно розвивається. Позиціонується як
            виробник запасних частин та обладнання для гірничо-рудної, гірничо-добувної та металургійної галузей
            промисловості, а також виконавець поточного та капітального ремонту цього обладнання, а саме конвеєрного,
            дробильного, кар&#39;єрних екскаваторів, СБШ, іншого технологічного та нестандартного обладнання та запасних
            частин до нього.
          </p>
          <div className="rectangle-4" />
          <img
            className="element-removebg-preview"
            alt="Element removebg preview"
            src={RemoveBg}
          />
          <p className="text-wrapper-12">
            Основні принципи <br />
            ТОВ ЛВК ПРОМГРУП:
            <br />
            <br />+
            Якість&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+
            Досвід
            <br />
            <br />+ Надійність&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; + Актуальність
          </p>
        </div>
        <div className="text-wrapper-13">Історія компанії</div>
        <div className="text-wrapper-14">Створення</div>
        <div className="text-wrapper-15">Впровадження</div>
        <div className="text-wrapper-16">Розширення</div>
        <div className="text-wrapper-17">Розвиток</div>
        <p className="text-wrapper-18">
          ТОВ ВИРОБНИЧА КОМПАНІЯ ПРОМГРУП, як виробника запчастин та обладнання для гірничодобувних підприємств
        </p>
        <p className="text-wrapper-19">системи управління якістю по вимогам міжнародного стандарту ISO 9001</p>
        <p className="text-wrapper-20">
          виробничого потенціалу підприємства шляхом введення в експлуатацію сучасного ливарного комплексу та можливістю
          використання виливків більш високої якості, реогранізація у ТОВ ЛИВАРНО ВИРОБНИЧА КОМПАНІЯ ПРОМГРУП
        </p>
        <p className="text-wrapper-21">
          при використанні досвіду і передових технологій виробництва, беззупинне підвищення якості продукції та
          кваліфікації наших спеціалістів
        </p>
        <img className="img" alt="Line" src={Line82} />
        <img className="line-2" alt="Line" src={Line82} />
        <img className="line-3" alt="Line" src={Line83} />
        <div className="overlap-3">
          <div className="rectangle-5" />
          <div className="text-wrapper-22">2012</div>
        </div>
        <div className="overlap-4">
          <div className="rectangle-5" />
          <div className="text-wrapper-23">2014</div>
        </div>
        <div className="overlap-group-2">
          <div className="rectangle-5" />
          <div className="text-wrapper-23">2016</div>
        </div>
        <div className="overlap-5">
          <div className="rectangle-5" />
          <div className="text-wrapper-24">2023</div>
        </div>
        <div className="overlap-6">
          <div className="text-wrapper-25">Технологічні можливості</div>
          <p className="element-2">
            <br />
            <br />
            Технологічні можливості компанії дозволяють здійснювати повний цикл виробництва, від етапу розробки
            технічного завдання до етапу післяпродажного обслуговування та технічного обслуговування та ремонту.
            <br />
            <br />
            Виробничу базу підприємства складають заготівельний цех, цех металоконструкцій, обробний цех,
            обробно-складальний цех і цех термообробки.
            <br />
            Техніка оснащена універсальним і спеціальним обладнанням, а також верстатами з ЧПК. Наявне обладнання
            дозволяє обробляти деталі до 20 тон.
            <br />
            <br />
            До складу машинного обладнання входять:
            <br />
            х&nbsp;&nbsp; токарно-різьбонарізний верстат;
            <br />
            х&nbsp;&nbsp; розточувальні, горизонтальні та координатні верстати;
            <br />
            х&nbsp;&nbsp; свердлильний, фрезерний, шліфувальний верстати;
            <br />
            х&nbsp;&nbsp; верстат зуборізальний, зуборізальний верстат;
            <br />
            х&nbsp;&nbsp; зварювальне та наплавлювальне обладнання;
            <br />
            х&nbsp;&nbsp; верстати стрічкові та відрізні;
            <br />
            х&nbsp;&nbsp; обладнання для термічної обробки;
            <br />
            х&nbsp;&nbsp; слюсарно-складальне обладнання.
            <br />
            <br />
            Наявне обладнання дозволяє виконувати ремонт різної складності - заміну підшипників та інших деталей,
            зменшення місць зносу, посадочних поверхонь підшипників і втулок, посилення та модернізацію вузлів.
            <br />
            <br />
            Для виконання монтажних або ремонтних робіт на території замовника використовується спеціальне пересувне та
            переносне обладнання, що дозволяє виконувати механічну обробку великогабаритних деталей без демонтажу та
            транспортування.
            <br />
            <br />
            ТОВ ЛВК ПРОМГРУП має міцні стосунки з переробними підприємствами для виконання складних відливок або кування
            заготовок з будь-яких матеріалів.
            <br />
            <br />
            Компанія активно розвивається з метою розширення технологічних можливостей, підвищення точності та якості
            продукції, а також підвищення продуктивності та конкурентоспроможності.
          </p>
        </div>
        <img className="dscn" alt="Dscn" src={dscn} />
        <img className="image" alt="Image" src={barananchik} />
        <img className="IMG" alt="Img" src={vagonetki} />
        <img className="rectangle-6" alt="Rectangle" src={Rec203} />
        <img className="rectangle-7" alt="Rectangle" src={Rec203} />
        <img
          className="element-a"
          alt="Element"
          src={rolik}
        />
        <img
          className="element-3"
          alt="Element"
          src={teleshka}
        />
        <img className="photo-2" alt="Photo" src={bytara} />
        <div className="overlap-7">
          <img className="rectangle-8" alt="Rectangle" src={Rec99} />
          <p className="element-4">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-9" />
          <div className="text-wrapper-26">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-27">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="span">Г</span>
            <span className="text-wrapper-28">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-29">promgrupp.pc@gmail.com</div>
          <p className="element-5">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line-4" alt="Line" src={Line17} />
          <ImgWrapper className="component-119" img={One} />
        </div>
      </div>
    </div>
  );
};
