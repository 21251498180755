import React from "react";
import { Component } from "../../components/Component25";
import { ImgWrapper } from "../../components/ImgWrapper25";
import Rec49 from '../../mobImages/imagesMain/rectangle-49.svg'
import Rec90 from '../../mobImages/imagesMain/rectangle-90.svg'
import Rec98 from '../../mobImages/imagesMain/rectangle-98.svg'
import Rec99 from '../../mobImages/imagesMain/rectangle-99.svg'
import Rec89 from '../../mobImages/imagesMain/rectangle-89.svg'
import Rec93 from '../../mobImages/imagesMain/rectangle-93.svg'
import Rec100 from '../../mobImages/imagesMain/rectangle-100.svg'
import Rec102 from '../../mobImages/imagesMain/rectangle-100.svg'
import Rec104 from '../../mobImages/imagesMain/rectangle-104.svg'
// import Icon4 from '../../mobImages/imagesMain/gear-icon-gear-icon-design-illustration-gear-icon-collection-gear-simple-sign-free-vector-removebg-preview-4.png'
// import Icon6 from '../../mobImages/imagesMain/gear-icon-gear-icon-design-illustration-gear-icon-collection-gear-simple-sign-free-vector-removebg-preview-6.png'
import Icon5 from '../../mobImages/imagesMain/gear-icon-gear-icon-design-illustration-gear-icon-collection-gea.png'
import Backgr from '../../mobImages/imagesMain/2023-03-15-185726172-removebg-preview-1.png'
import Logistic from '../../mobImages/imagesMain/04logistic-1.svg'
import Create from '../../mobImages/imagesMain/03create-1.svg'
import Design from '../../mobImages/imagesMain/02design-1.svg'
import line15 from '../../mobImages/imagesMain/line-15.svg'
import line16 from '../../mobImages/imagesMain/line-16.svg'
import line26 from '../../mobImages/imagesMain/line-26.svg'
import line27 from '../../mobImages/imagesMain/line-26.svg'
import line28 from '../../mobImages/imagesMain/line-28.svg'
import line29 from '../../mobImages/imagesMain/line-32.svg'
import vectror from '../../mobImages/imagesMain/vector.svg'
import oprokid from '../../mobImages/imagesMain/img-7042-3.png'
import vagonetka from '../../mobImages/imagesMain/img-7044-3.png'
import Viber from '../../mobImages/imagesMain/viber-2023-02-16-12-48-06-144-1.png'
import TeleshkaRazgr from '../../mobImages/imagesMain/photo-2022-01-17-11-16-00-3.png'
import OprokidElseOne from '../../mobImages/imagesMain/photo-2021-11-11-10-18-41-1.png'
import OprokidSecond from '../../mobImages/imagesMain/photo-2021-11-11-10-18-37-2.png'
import BackgrSec from '../../mobImages/imagesMain/3-3-900-00-00-1.png'
import OprokidThree from '../../mobImages/imagesMain/photo-2021-11-11-10-18-45-2.png'
import BackGrThree from '../../mobImages/imagesMain/3693-removebg-preview-1.png'
import image from '../../mobImages/imagesMain/image.svg'
import teleshkaRazgrSec from '../../mobImages/imagesMain/photo-2022-01-17-11-16-01-4-1.png'
import stella from '../../mobImages/imagesMain/photo-2022-01-17-11-20-08-2.png'
import OprokidFour from '../../mobImages/imagesMain/photo-2023-02-19-20-38-30-2.png'
import BackGrFour from '../../mobImages/imagesMain/1-4-5-750-01-1.png'
import OprokigFive from '../../mobImages/imagesMain/photo-2021-11-05-13-57-06-2.png'
import pitatel from '../../mobImages/imagesMain/photo-2023-02-19-20-38-41-1.png'
import korzina from '../../mobImages/imagesMain/photo-2022-01-17-11-16-00-3.png'
import pitatelSec from '../../mobImages/imagesMain/1-1.png'
import pitatelThree from '../../mobImages/imagesMain/2.png'
import sertificate from '../../mobImages//imagesMain/image-2.png'
import sertificateSec from '../../mobImages/imagesMain/14-08-1.png'
import sertificateThree from '../../mobImages/imagesMain/image-3.png'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./mainM.css";

export const MainM = () => {
  return (
    <div className="frame">
      <div className="div">
        <div className="overlap">
          <div className="overlap-2">
            <img className="rectangle" alt="Rectangle" src={Rec98} />
            <Component className="component-1" />
            <div className="text-wrapper-2">ЛВК ПРОМГРУП</div>
            <div className="text-wrapper-3">ТОВ</div>
            <p className="p">
              Виробник обладнання і запчастин
              <br />
              для гірничодобувних компаній.
            </p>
            <div className="div-2">
              <div className="rectangle-2" />
            <a href='/about'><div className="text-wrapper-4">Детальніше</div></a>  
            </div>
            <img className="img" alt="Rectangle" src={Rec99} />
        <a href='/about'><div className="text-wrapper-5">Про компанію</div></a>   
        <a href='/'> <div className="text-wrapper-6">Продукція</div></a>   
        <a href='/services'><div className="text-wrapper-7">Поcлуги</div></a>    
        <a href='/contacts'><div className="text-wrapper-8">Контакти</div></a>    
            <p className="text-wrapper-9">
              Найкращі рішення для <br />
              вашого бізнесу
            </p>
            <p className="text-wrapper-10">
              Наша компанія позиціонується як виробник запасних частин та обладнання для <br />
              гірничо-рудної, гірничо-добувної та металургійної галузей промисловості, <br />а також виконавець
              поточного та капітального ремонту цього обладнання, а саме конвеєрного, дробильного, кар&#39;єрних
              екскаваторів, СБШ, іншого технологічного та нестандартного обладнання та запасних частин до нього.
            </p>
            <div className="text-wrapper-11">
              Наша
              <br />
              спеціалізація
            </div>
            <div className="navbar">
              <p className="text">
                <span className="span">
                  ВИГОТОВЛЕННЯ ТА РЕМОНТ
                  <br />
                </span>
              </p>
              <p className="text">
                <span className="span">
                  МЕХАНООБРОБКА
                  <br />
                </span>
              </p>
              <p className="text">
                <span className="span">
                  ТЕРМООБРОБКА
                  <br />
                </span>
              </p>
              <p className="text">
                <span className="span">
                  ПРОЕКТУВАННЯ
                  <br />
                </span>
              </p>
              <p className="text">
                <span className="span">
                  <br />
                </span>
              </p>
              <p className="text">
                <span className="span">{""}</span>
              </p>
            </div>
            <img
              className="gear-icon-gear-icon"
              alt="Gear icon gear icon"
              src={Icon5}
            />
            <img
              className="gear-icon-gear-icon-2"
              alt="Gear icon gear icon"
              src={Icon5}
            />
            <img
              className="gear-icon-gear-icon-3"
              alt="Gear icon gear icon"
              src={Icon5}
            />
            <div className="overlap-wrapper">
              <div className="overlap-3">
                <div className="ellipse" />
                <div className="ellipse-2" />
                <img className="element" alt="Element" src={Backgr} />
              </div>
            </div>
            <p className="text-wrapper-12">+ |&nbsp;&nbsp;ПЕРЕВАГИ&nbsp;&nbsp; ТОВ ЛВК ПРОМГРУП</p>
            <div className="text-wrapper-13">+АКТУАЛЬНІСТЬ</div>
            <div className="text-wrapper-14">ПОВНИЙ ЦИКЛ ВИРОБНИЦТВА ВИРОБІВ</div>
            <p className="text-wrapper-15">
              Починаючи зі стадії обговорення до монтажу готових виробів у Вас на підприємстві.
            </p>
            <img className="rectangle-3" alt="Rectangle" src={Rec89}/>
            <div className="text-wrapper-16">
              Доставка та <br />
              монтаж
            </div>
            <img className="element-logistic" alt="Element" src={Logistic} />
            <div className="text-wrapper-17">
              Виготовлення
              <br />
              або ремонт
            </div>
            <img className="element-create" alt="Element" src={Create} />
            <div className="text-wrapper-18">
              Розрахунок
              <br />
              та проектування
            </div>
            <img className="element-design" alt="Element" src={Design}/>
            <img className="line" alt="Line" src={line26} />
            <img className="line-2" alt="Line" src={line27} />
            <img className="line-3" alt="Line" src={line28} />
            <div className="text-wrapper-19">
              Дзвінок
              <br />
              або лист
            </div>
            <img className="vector" alt="Vector" src={vectror} />
            <div className="text-wrapper-20">ВИСОКІ СТАНДАРТИ ВИРОБНИЦТВА</div>
            <p className="text-wrapper-21">
              Впроваджена система якості та високих виробничих вимог гарантують надійність і довговічність нашої
              продукції.
            </p>
            <div className="text-wrapper-22">+100</div>
            <div className="text-wrapper-23">задоволених клієнтів</div>
            <div className="text-wrapper-24">ДОСВІД ТА ОПЕРАТИВНІСТЬ</div>
            <p className="text-wrapper-25">
              Ми розуміємо кожного нашого клієнта та знаходимо оптимальні швидкі рішення у будь-яких Ваших запитах.
            </p>
            <div className="text-wrapper-26">+ |&nbsp;&nbsp;КАТАЛОГ ПРОДУКЦІЇ</div>
          </div>
          <div className="overlap-4">
            <div className="text-wrapper-27">+10</div>
            <p className="text-wrapper-28">
              років досвіду на ринку <br />
              виробників України
            </p>
          </div>
          <div className="text-wrapper-29">+2000</div>
          <div className="text-wrapper-30">реалізованих проектів</div>
          <div className="text-wrapper-31">+НАДІЙНІСТЬ</div>
          <div className="overlap-5">
            <img className="rectangle-4" alt="Rectangle" src={Rec93} />
            <div className="overlap-group-wrapper">
              <div className="overlap-6">
                <div className="rectangle-5" />
                <div className="text-wrapper-32"><a href="/miningbenefication">Детальніше</a></div>
              </div>
            </div>
            <div className="div-wrapper">
              <div className="overlap-6">
                <div className="rectangle-5" />
                <div className="text-wrapper-32"><a href="/metallurgical">Детальніше</a></div>
              </div>
            </div>
            <div className="overlap-wrapper-2">
              <div className="overlap-6">
                <div className="rectangle-5" />
                <div className="text-wrapper-32"><a href="/miningeq">Детальніше</a></div>
              </div>
            </div>
            <div className="overlap-wrapper-3">
              <div className="overlap-6">
                <div className="rectangle-5" />
                <div className="text-wrapper-32"><a href="/specialized">Детальніше</a></div>
              </div>
            </div>
            <img className="line-4" alt="Line" src={line29} />
            <img className="line-5" alt="Line" src={line29} />
            <img className="line-6" alt="Line" src={line29} />
            <img className="line-7" alt="Line" src={line29} />
            <img className="IMG" alt="Img" src={vagonetka} />
            <div className="text-wrapper-33">
              Гірничо-шахтне
              <br />
              обладнання
            </div>
            <img className="IMG-2" alt="Img" src={oprokid} />
            <img className="viber" alt="Viber" src={Viber} />
            <img className="photo" alt="Photo" src={TeleshkaRazgr} />
            <div className="text-wrapper-34">
              Гірничо-збагачувальне
              <br />
              обладнання
            </div>
            <div className="text-wrapper-35">
              Обладнання металургійного
              <br />
              виробництва
            </div>
            <div className="text-wrapper-36">Спеціалізоване обладнання і металоконструкції</div>
            <div className="text-wrapper-37">+ |&nbsp;&nbsp;РЕАЛІЗОВАНІ ПРОЕКТИ</div>
            <div className="text-wrapper-38">+ДОСВІД</div>
            <p className="text-wrapper-39">
              ТОВ ЛВК ПРОМГРУП не зупиняється у своєму розвитку. <br />
              Наша компанія постійно підвищує якість і надійність своєї продукції, використовуючи досвід і передові
              технології виробництва.
              <br />
              <br />
              Ознайомитись з деякими нашими реалізованими проектами можна нижче.
            </p>
          </div>
        </div>
        <div className="overlap-7">
          <div className="overlap-8">
            <div className="rectangle-6" />
            <img className="photo-2" alt="Photo" src={OprokidElseOne} />
            <img className="photo-3" alt="Photo" src={OprokidSecond} />
            <div className="element-2">
              Габаритні розміри, мм:
              <br />
              4796х4109х3926
              <br />
              Маса, т:
              <br />
              13,7
            </div>
            <p className="text-wrapper-40">
              Перекидач призначений для розвантаження вагонеток не розчепленого складу в навколоствольних дворах
              скіпових підйомів вугільних шахт або одиночних вагонеток у надшахтних будівлях підйомів клітьових
              вагонеток типу ВГ–3,3
            </p>
            <div className="text-wrapper-41">
              з вибухозахищенним <br />
              електрообладнанням
            </div>
            <p className="element-3">
              <span className="text-wrapper-42">
                ___
                <br />
                Опрокидувач
                <br />
                вагонеток
                <br />
                круговий
                <br />
              </span>
              <span className="text-wrapper-43">ОКВМ1-3,3-9</span>
            </p>
            <img className="element-4" alt="Element" src={BackgrSec} />
            <div className="text-wrapper-44">
              Замовник:
              <br />
              ПрАТ “ДТЕК <br />
              “ПАВЛОГРАДВУГІЛЛЯ”
            </div>
            <img className="photo-4" alt="Photo" src={OprokidThree} />
          </div>
          <div className="overlap-9">
            <div className="text-wrapper-45">01</div>
          </div>
        </div>
        <div className="overlap-10">
          <div className="overlap-11">
            <img className="rectangle-7" alt="Rectangle" src={Rec100} />
            <div className="element-5">
              Габаритні розміри, мм:
              <br />
              7790х4550х4328
              <br />
              Маса, т:
              <br />
              15
            </div>
            <p className="text-wrapper-46">
              Візок розвантажувальний призначений для перевантаження вантажів з стрічкового конвеєра на іншу
              транспортуючу машину, бункера, що входять до складу транспортуючого комплексу
            </p>
            <div className="bonfiglioli">
              <br />з мотор-редукторами Bonfiglioli
            </div>
            <p className="element-6">
              <span className="text-wrapper-42">
                ___
                <br />
                Візок <br />
                розвантажувальний
                <br />
              </span>
              <span className="text-wrapper-43">ОТ-3693</span>
            </p>
            <img className="element-removebg" alt="Element removebg" src={BackGrThree} />
            <div className="text-wrapper-47">
              Замовник:
              <br />
              АТ “Південний ГЗК”
            </div>
            <img className="photo-5" alt="Photo" src={korzina} />
            <img className="photo-6" alt="Photo" src={teleshkaRazgrSec} />
            <img className="photo-7" alt="Photo" src={stella} />
          </div>
          <div className="overlap-12">
            <div className="text-wrapper-48">02</div>
          </div>
        </div>
        <div className="overlap-13">
          <div className="rectangle-8" />
          <img className="rectangle-9" alt="Rectangle" src={Rec102} />
          <img className="photo-8" alt="Photo" src={OprokidFour} />
          <div className="element-7">
            Габаритні розміри, мм:
            <br />
            16000х6500х4500
            <br />
            Маса, т:
            <br />
            62
          </div>
          <div className="element-8">згідно ТУ У 28.2-40806615-002:2020</div>
          <p className="element-9">
            <span className="text-wrapper-42">
              ___
              <br />
              Перекидач <br />
              вагонеток <br />
              двохвагонний
              <br />
            </span>
            <span className="text-wrapper-49">ОКЕ2-4,5-750 </span>
            <span className="text-wrapper-42">&nbsp;</span>
          </p>
          <img className="element-10" alt="Element" src={BackGrFour} />
          <p className="text-wrapper-50">
            Перекидач призначений для розвантаження як не розчеплених складів, так і одиночних шахтних вантажних
            вагонеток з глухим кузовом типу ВГ-4,5А на гірничодобувних підприємствах, не небезпечних по газу та пилу
          </p>
          <div className="text-wrapper-51">
            Замовник:
            <br />
            АТ “КЗРК”
          </div>
          <div className="text-wrapper-52">03</div>
          <img className="photo-9" alt="Photo" src={OprokigFive} />
        </div>
        <div className="overlap-14">
          <div className="overlap-15">
            <img className="rectangle-10" alt="Rectangle" src={Rec104}/>
            <img className="photo-10" alt="Photo" src={pitatel} />
            <div className="text-wrapper-53">
              Замовник:
              <br />
              АТ “КЗРК”
            </div>
            <p className="element-11">
              Габаритні розміри, мм:
              <br />
              4760х1530х2240(без врахування приводу)
              <br />
              Маса, т:
              <br />
              17,56
            </p>
            <p className="text-wrapper-54">
              Призначений для рівномірної подачі матеріалів з бункерів, воронок та інших ємностей в робочі машини чи
              транспортуючі засоби. В ролі транспортуючого органу використовується стрічка, виконана в формі
              “гусеничного ланцюга” з конструкційної легованої сталі.
            </p>
            <p className="element-12">
              <span className="text-wrapper-42">
                ___
                <br />
                Живильник <br />
                пластинчатий
                <br />
              </span>
              <span className="text-wrapper-43">ПП2-15-30 </span>
            </p>
            <img className="element-13" alt="Element" src={pitatelSec}/>
            <img className="element-14" alt="Element" src={pitatelThree} />
            <div className="text-wrapper-55">+ |&nbsp;&nbsp;НАШІ ДОКУМЕНТИ</div>
            <div className="view">
              <div className="overlap-16">
                <img className="image" alt="Image" src={sertificate} />
                <img className="element-15" alt="Element" src={sertificateSec} />
                <div className="view-2">
                  <div className="overlap-group-2">
                    {/* <div className="ellipse-3" />
                    <div className="ellipse-4" />
                    <div className="ellipse-5" />
                    <div className="ellipse-6" />
                    <div className="ellipse-7" /> */}
                    <img style={{top:100, left:50}} src={LogoDark}></img>
                  </div>
                </div>
                <div className="rectangle-11" />
                <a href='/certification'><img className="image-2" alt="Image" src={sertificateThree} /></a>
              </div>
            </div>
            <div className="text-wrapper-56">+ЯКІСТЬ</div>
            <img className="rectangle-12" alt="Rectangle" src={Rec90} />
            <p className="text-wrapper-57">
              Оперативність та гнучкість у відносинах із замовником, розумне формування ціни, якість виконаної роботи –
              це основна стратегія розвитку підприємства, яку реалізує високий потенціал висококваліфікованого
              управлінського персоналу, інженерно-технічного та робочих спеціальностей.
              <br />
              <br />
              Переконайтесь в цьому, ознайомившись з детальною інформацієї про компанію, наші вироби та послуги, а також
              звернувшись до нас в зручний для Вас спосіб.
            </p>
            <div className="text-wrapper-58">ПРО НАС</div>
          </div>
          <div className="overlap-17">
            <div className="text-wrapper-59">04</div>
          </div>
        </div>
        <div className="overlap-18">
          <img className="rectangle-13" alt="Rectangle" src={Rec49} />
          <div className="rectangle-14" />
          <div className="text-wrapper-60">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-61">Україна, Кривий Ріг</div>
          <p className="div-3">
            <span className="text-wrapper-62">Г</span>
            <span className="text-wrapper-63">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <p className="element-16">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="text-wrapper-64">promgrupp.pc@gmail.com</div>
          <p className="element-17">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line-8" alt="Line" src={line15} />
          <img className="line-9" alt="Line" src={line16} />
          <ImgWrapper className="component-310" img="image-2.svg" />
        </div>
      </div>
    </div>
  );
};
