import React from "react";
import Zymph from  '../imagesSpecialized/wp-20170618-07-52-11-pro-1-1.png'
import Rec91 from '../imagesSpecialized/rectangle-91.svg'
import Rec911 from '../imagesSpecialized/rectangle-91-1.svg'
import Opora from '../imagesSpecialized/photo-2023-04-22-21-28-39-1.png'
import OporaSec from '../imagesSpecialized/viber-2023-02-16-12-55-42-219-1.png'
import Sbsh from '../imagesSpecialized/06012011074-1.png'
import Dscn from '../imagesSpecialized/dscn0820-1.png'
import VizkuRoz from '../imagesSpecialized/photo-2022-01-17-11-16-00-4.png'
import Line15 from '../imagesSpecialized/line-15.svg'
import Line16 from '../imagesSpecialized/line-16.svg'
import Logo from '../imagesSpecialized/2.svg'
import Menu from '../imagesSpecialized/2x.png'
import LogoLight from '../imagesSpecialized/3.svg'
import Rec13 from '../imagesSpecialized/rectangle-13.svg'

import { DivWrapper } from "../components/DivWrapper11";
import { ImgWrapper } from "../components/ImgWrapper11";
import "./specialized.css";
import Footer from "./Footer";
import {Header} from './Header/Header'
import { useTranslation } from 'react-i18next'

const SpecializedEquipment = () =>{
  const {t} = useTranslation()
    return(


            <div className="specialized">
              <div className="div">
                <div className="view">
                  <div className="overlap">
                    <div className="overlap-group">
                      <div className="rectangle" />
                      <img className="WP" alt="Wp" src={Zymph}/>
                      <div className="rectangle-2" />
                      <div className="text-wrapper-2">
                        {/* Зумпф V=85  */}
                        {t("Sump V=85")}
                      <br/>
                       {/* Дешламатор V=600 */}
                       {t("Deshlamator")}
                      </div>
                      <p className="p">
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП </span>
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП</span>
                      </p>
                    </div>
                    <p className="d-h">
                      {/* Основні характеристики Зумпфа: */}
                      {t("Main characteristics of the Sump")}
                      <br />
                      {/* Загальний розмір: d=6500 мм, H=6900 мм */}
                      {t("ObshRazmer")}
                      <br />
                      {/* Вага: 16 490 кг */}
                      {t("Weight16490")}
                      <br />
                      <br />
                      {/* Основні характеристики Дешламатора: */}
                      {t("Main characteristics of the Slugcatcher")}
                      <br />
                      {/* Загальний розмір: d=11700 мм, H=10500 мм */}
                      {t("ObshRazme")}
                      <br />
                      {/* Вага: 37 530 кг */}
                      {t("Weight37530")}
                      <br />
                      <br />
                      {/* Термін виготовлення: 180 днів */}
                      {t("Manufacturing lead time: 180 days")}
                    </p>
                  </div>
                </div>
                <div className="overlap-wrapper">
                  <div className="overlap-2">
                    <div className="overlap-3">
                      <img className="img" alt="Rectangle" src={Rec91}/>
                      <img className="photo" alt="Photo" src={Opora}/>
                      <div className="rectangle-3" />
                      <p className="div-2">
                        <span className="text-wrapper-4">
                          {/* Опори ж/д мостові */}
                          {t("Railway bridge supports")}
                          <br />
                        </span>
                        <span className="text-wrapper-5">{t("fixed")}</span>
                      </p>
                      <p className="div-3">
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП </span>
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП</span>
                      </p>
                    </div>
                    <p className="element">
                      {/* Основні характеристики: */}
                      {t("Main specifications railway")}
                      <br />
                      {/* Вага: до 1300 кг */}
                      {t("weight1300")}
                      <br />
                      {/* Довжина: 1100 мм */}
                      {t("length1100")}
                      <br />
                      {/* Ширина: 900 мм */}
                      {t("width900")}
                      <br />
                      {/* Висота: 680 мм */}
                      {t("height680")}
                      <br />
                      <br />
                      {/* Термін виготовлення: 60-90 днів */}
                      {t("Manufacturing lead time: 60-90 days")}
                    </p>
                  </div>
                </div>
                <div className="overlap-group-wrapper">
                  <div className="overlap-2">
                    <div className="overlap-3">
                      <img className="img" alt="Rectangle" src={Rec911} />
                      <img className="viber" alt="Viber" src={OporaSec}/>
                      <div className="rectangle-3" />
                      <p className="div-2">
                        <span className="text-wrapper-4">
                          {/* Опори ж/д мостові */}
                          {t("Railway bridge supports")}
                          <br />
                        </span>
                        <span className="text-wrapper-5">+ {t("mobile")}</span>
                      </p>
                      <p className="div-4">
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП </span>
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП</span>
                      </p>
                    </div>
                    <p className="element">
                      {/* Основні характеристики: */}
                      {t("Main specifications railway mobile")}
                      <br />
                      {/* Вага: до 2600 кг */}
                      {t("Weight2600")}
                      <br />
                      {/* Довжина: 1360 мм */}
                      {t("Length1360")}
                      <br />
                      {/* Ширина: 860 мм */}
                      {t("Width860")}
                      <br />
                      {/* Висота: 770 мм */}
                      {t("Height770")}
                      <br />
                      <br />
                      {/* Термін виготовлення: 60-90 днів */}
                      {t("Manufacturing lead time: 60-90 days")}
                    </p>
                  </div>
                </div>
                <div className="view-2">
                  <div className="overlap-4">
                    <div className="overlap-5">
                      <div className="rectangle" />
                      <img className="img-2" alt="Element" src={Sbsh}/>
                      <div className="rectangle-4" />
                      <div className="text-wrapper-6">{t("Repair of the crawler undercarriage of a bulldozer СБШ")}</div>
                      <p className="div-5">
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП </span>
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП</span>
                      </p>
                    </div>
                    <p className="element-2">
                      {/* Основні характеристики: */}
                      {t("Main specifications sbsh")}
                      <br />
                      {/* Вага: 21 969 кг */}
                      {t("Weight21969")}
                      <br />
                      {/* Вантажопідйомність, не більше: 50 т */}
                      {t("Gryzopodiemnost")}
                      <br />
                      {/* Швидкість, не менше: 0,77 км/год */}
                      {t("Speed")}
                      <br />
                      <br />
                      {/* Термін ремонту: 180 днів */}
                      {t("Repair duration: 180 days")}
                    </p>
                  </div>
                </div>
                <div className="view-3">
                  <div className="overlap-4">
                    <div className="overlap-5">
                      <div className="rectangle" />
                      <img className="img-2" alt="Dscn" src={Dscn} />
                      <div className="rectangle-5" />
                      <p className="text-wrapper-7">
                        {/* Ремонт ковшів ЭКГ-10, ЭКГ-8, ЭКГ-5 */}
                        {t("Repair of buckets ЭКГ-10, ЭКГ-8, ЭКГ-5")}
                        </p>
                      <p className="div-6">
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП </span>
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП</span>
                      </p>
                    </div>
                    <p className="element-3">
                      {/* Основні характеристики: */}
                      {t("Main specifications ekg")}
                      <br />
                      {/* Об&#39;єм: 8 м.куб. */}
                      {t("Objem")}
                      <br />
                      {/* Вага: 19480 кг */}
                      {t("Weight19480")}
                      <br />
                      <br />
                      {/* Термін ремонту: 40 днів */}
                      {t("Repair duration: 40 days")}
                    </p>
                  </div>
                </div>
                <div className="view-4">
                  <div className="overlap-6">
                    <div className="overlap-5">
                      <div className="rectangle" />
                      <img className="photo-2" alt="Photo" src={VizkuRoz} />
                      <div className="rectangle-6" />
                      <p className="div-7">
                        <span className="text-wrapper-4">
                          {/* Візки розвантажувальні */}
                          {t("Unloading trolleys")}
                          <br />
                        </span>
                        <span className="text-wrapper-5">+ {t("with imported electrical equipment")}</span>
                      </p>
                      <p className="div-6">
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП </span>
                        <span className="span">ПРОМГРУП </span>
                        <span className="text-wrapper-3">ПРОМГРУП</span>
                      </p>
                    </div>
                    <p className="element-4">
                      {/* Основні характеристики: */}
                      {t("Main specifications trolleys")}
                      <br />
                      {/* Вага: до 15000 кг */}
                      {t("Weight1500")}
                      <br />
                      {/* Ширина стрічки конвеєра: 1600 мм */}
                      {t("WidthStrichki")}
                      <br />
                      {/* Ширина колеї: 2300 мм */}
                      {t("WidthKolei")}
                      <br />
                      {/* Швидкість переміщення: 0,125 м/с */}
                      {t("SpeedPerem")}
                      <br />
                      {/* Довжина: 7790 мм */}
                      {t("Lenght")}
                      <br />
                      {/* Ширина: 4550 мм */}
                      {t("Widtj")}
                      <br />
                      {/* Висота: 4328 мм */}
                      {t("Heightq")}
                      <br />
                      <br />
                      {/* Термін виготовлення: 120 днів */}
                      {t("Manufacturing lead time: 120 days after complete design")}
                      <br />
                      {/* після повного проектування */}
                      {t("AfterFull")}
                    </p>
                  </div>
                </div>
                <p className="text-wrapper-8">
                  {/* Виготовлення металоконструкцій з листового, профільного металу і труб, а також литозварних і зварно-кованих
                  конструкцій з вуглецевих і низьколегованих конструкційних сталей. У виробництві використовують різні види
                  зварювання, серед яких: */}
                  {t("Manufacturing of metal structures")}
                  <br />
                  - {t("Automatic flux-cored and powder wire welding.")}
                  {/* автоматичне зварювання під флюсом повним і порошковим дротом; */}
                  <br />
                  - {t("Welding in an active")}
                  {/* зварювання в середовищі активного та інертного захисного газу плавким і неплавким електродом; */}
                  <br />
                  - {t("Manual arc welding.")}
                  {/* ручне дугове зварювання; */}
                  <br />
                  - {t("Automatic arc welding and plasma surfacing with wear-resistant materials, including composite and corrosion-resistant materials.")}
                  {/* автоматичне дугове зварювання та плазмове наплавлення зносостійкими матеріалами, у тому числі
                  композитними, і корозійностійкими матеріалами. */}
                </p>
                <div className="view-5">
                  {/* <div className="overlap-7">
                    <div className="rectangle-7" />
                    <div className="rectangle-8" />
                    <div className="text-wrapper-9">
                      ВиготовленняПроектування
                      <br />
                      МеталообробкаПослугиТермообробка
                      <br />
                      ВиготовленняРемонтПослуги
                      <br />
                      МеталообробкаТермообробкаРемонт
                      <br />
                      МеталообробкаВиготовлення
                      <br />
                      алообробкаПослугиТермообробка
                      <br />
                      ВиготовленняРемонтПослуги
                      <br />
                      МеталообробкаТермообробкаРемонт
                      <br />
                      МеталообробкаВиготовлення
                    </div>
                    <div className="text-wrapper-10">Україна, Кривий Ріг</div>
                    <p className="div-8">
                      <span className="text-wrapper-11">Г</span>
                      <span className="text-wrapper-12">
                        оловна
                        <br />
                        <br />
                        Про компанію
                        <br />
                        <br />
                        Продукція
                        <br /> */}
                        {/* <br /> */}
                        {/* Послуги
                        <br />
                        <br />
                        Контакти
                      </span>
                    </p>
                    <p className="element-5">
                      +38 067 638 40 80
                      <br /> */}
                      {/* +38 067 569 11 33
                      <br />
                      +38 056 493 80 39
                      <br />
                      +38 056 493 80 38
                    </p>
                    <div className="text-wrapper-13">promgrupp.pc@gmail.com</div>
                    <p className="element-6">
                      Всі права захищені. Використання матеріалів тільки з дозволу власника.
                      <br />© 2023
                    </p>
                    <img className="line" alt="Line" src={Line15}/>
                    <img className="line-2" alt="Line" src={Line16} />
                    <ImgWrapper className="image" img={Logo} />
                  </div> */}
                  <Footer/>
                </div>
                <div className="view-6">
                  <div className="overlap-8">
                    <p className="text-wrapper-14">
                      {/* Можливості компанії ТОВ ЛВК ПРОМГРУП не обмежуються представленим ілюстративним списком продукції
                      категорії СПЕЦІАЛІЗОВАНЕ ОБЛАДНАННЯ ТА МЕТАЛОКОНСТРУКЦІЇ. */}
                      {t("The capabilities second")}
                      <br />
                      {/* По будь-яким питанням зв’яжіться з нами в зручний для Вас спосіб. */}
                      {t("Please feel free to contact us using your")}
                    </p>
                    <div className="text-wrapper-15">{t("IMPORTANT")}!</div>
                  </div>
                </div>
                <div className="div-9">
                  <div className="div-9">
                    <div className="overlap-9">
                      <img className="rectangle-9" alt="Rectangle" src={Rec13}/>
                      <div className="text-wrapper-16">
                        {/* Спеціалізоване */}
                        {t("SPECIALIZED EQUIPMENT")}
                        <br />
                        {/* обладанання та */}
                        {t("Equipment")}
                        <br />
                        {/* металоконструкції */}
                        {t("structurs")}
                      </div>
                      <div className="text-wrapper-17">{t("PRODUCTSUPPER")}</div>
                      <div className="rectangle-10" />
                      <div className="text-wrapper-18">
                        {t("LLC")} {t("FPC Promgrupp")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("PRODUCTSUPPER")}
                      </div>
                    </div>
                    <p className="text-wrapper-19">
                      {/* Наша компанія може швидко і якісно виготовити будь-який спеціалізоване та нестандартне обладнання з повним
                      проектуванням за наявними аналогами, а також інше промислові, будівельні та побутові металоконструкції. */}
                      {t("Our company can")}
                      <br />
                      <br />
                      {/* Нижче представлений загальний ілюстративний список з основною виробляємою нами продукцією категорії
                      СПЕЦІАЛІЗОВАНЕ ОБЛАДНАННЯ ТА МЕТАЛОКОНСТРУКЦІЇ. */}
                      {t("Below is a general third")}
                    </p>
                  </div>
                 <a href="/">  <DivWrapper className="component-192" imgWrapperImg={LogoLight} /> </a> 
                  {/* <img className="image-2" alt="Image" src={Menu}/> */}
                  <Header cls='wrp2' divClassName='light'/>
                </div>
              </div>
            </div>
          );
        };


export default SpecializedEquipment