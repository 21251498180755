import React from "react";
import Line15 from '../imagesPartners/line-15.svg'
import Line16 from '../imagesPartners/line-16.svg'
import Line65 from '../imagesPartners/line-65.svg'
import Line73 from '../imagesPartners/line-73.svg'
import Logo from '../imagesPartners/2.svg'
import LogoDark from '../imagesPartners/1.svg'
import Hand from '../imagesPartners/getty-678074927-2000133320009280137-360656-1.png'
import BackGr from '../imagesPartners/getty-678074927-2000133320009280137-360656-2.png'
import Menu from '../imagesPartners/2x.png'
import DtekLogo from '../imagesPartners/dtek-logo-1.png'
import LogoYgok from '../imagesPartners/logo-removebg-preview-1.png'
import LogoKzrk from '../imagesPartners/removebg-preview-1-2x.png'
import LogoMarganec from '../imagesPartners/removebg-preview-1.png'
import LogoNfz from '../imagesPartners/nzf-logo-1.png'
import LogoFerex from '../imagesPartners/ferrexpo-1-removebg-preview-1.png'
import LogoMetinv from '../imagesPartners/metinvest-logo-1.png'
import LogoPokrov from '../imagesPartners/3-1.png'
import LogoIfcem from '../imagesPartners/ifcem-logo-main-reg-1.png'
import LogoZprk from '../imagesPartners/1.png'

import { ImgWrapper } from "../components/ImgWrapper6";
import "./partner.css";
import Footer from "./Footer";
import { Header } from "./Header/Header";
import { useTranslation } from 'react-i18next'
import HeaderLight from "./Header/HeaderLight";


const Partners = () =>{
  const {t} = useTranslation()
    return(
    <div className="partner">
      <div className="div">
        <div className="view">
                <Footer/>               
        </div>
        <div className="overlap">
          <div className="overlap">
            <img className="getty" alt="Getty" src={Hand} />
            <div className="rectangle-3" />
            <img className="getty-2" alt="Getty" src={BackGr} />
            <a href="/"><ImgWrapper className="component-135" img={LogoDark} /></a>
            <div className="text-wrapper-6">{t("Partners")}</div>
            <div className="text-wrapper-7">
              {t("LLC FPC PROMGROUP PRODUCTS")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;НАДІЙНІСТЬ
            </div>
            <div className="rectangle-4" />
            <div className="rectangle-5" />
            <img className="line-2" alt="Line" src={Line65} />
            <div className="text-wrapper-8">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослугиВиготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
          </div>
          {/* <img className="image-2" alt="Image" src={Menu} /> */}
          <HeaderLight cls='wrp2' divClassName='dark'/>
        </div>
        <p className="text-wrapper-9"></p>
        <div className="view-4">
          <img className="dtek-logo" alt="Dtek logo" src={DtekLogo} />
          <p className="i">
            {/* Найбільший приватний національний інвестор. */}
            {t("The largest private")}
             <br />
            {/* Iнвестують в розвиток української енергетичної галузі: впроваджують інноваційні технології та будують нові
            потужності, розвивають та модернізують виробництво. */}
            {t("Investing")}
          </p>
          <img className="line-3" alt="Line" src={Line73} />
          <div className="text-wrapper-10">{t("DTEK Group")}</div>
        </div>
        <div className="view-5">
          <img className="logo-removebg" alt="Logo removebg" src={LogoYgok}/>
          <p className="text-wrapper-11">
            {/* Компанія, що спеціалізується на видобутку та збагаченні залізистих магнетитових кварцитів з отриманням
            залізорудного концентрату та доменного агломерату.  */}
            {t("The company specializes")}
            <br />
            {t("This one")}
            {/* Це один з наших постійних і надійних клієнтів, для якого ми виготовляємо продукцію практично для всіх етапів
            виробництва і наша співпраця постійно розширюється. */}
          </p>
          <img className="line-4" alt="Line" src={Line73} />
          <div className="text-wrapper-12">
            {t("JSC Southern Mining")}
            <br />
            {t("mining")}
            <br />
            {t("concetrat")}
            <br />
            {t("combinat")}
          </div>
        </div>
        <div className="view-6">
          <p className="text-wrapper-13">
            {/* Найбільше підприємство України з видобутку залізної руди підземним способом. Ця компанія є одним з наших
            постійних клієнтів, для якого ми виготовляємо продукцію для різних етапів виробництва. */}
            {t("The largest underground")}
          </p>
          <img className="removebg-preview" alt="Removebg preview" src={LogoKzrk} />
          <img className="line-5" alt="Line" src={Line73}/>
          <div className="text-wrapper-14">
            {t("PJSC Kryvyi Rih Iron Ore Combine")}
            <br />
            {t("zalizorudny")}
            <br />
            {t("combinat2")}
          </div>
        </div>
        <div className="view-7">
          <div className="overlap-2">
            <div className="overlap-2">
              <img className="removebg-preview-2" alt="Removebg preview" src={LogoMarganec} />
              <img className="line-6" alt="Line" src={Line73}/>
            </div>
            <div className="text-wrapper-15">
              {/* АТ Марганецький */}
              {t("PJSC Manganese")}
              <br />
              {/* гірничо */}
              {t("mining2")}
              <br />
              {t("concentrat")}
              <br />
              {t("combinat3")}
            </div>
          </div>
          <p className="text-wrapper-16">
            {/* Підприємство з видобутку та переробки марганцевої руди, єдине підприємство в Україні, яке видобуває
            марганцеву руду комбінованим способом: підземним і відкритим. */}
            {t("The enterprise is")}
          </p>
        </div>
        <div className="view-8">
          <p className="span-wrapper">
            <span className="text-wrapper-17">
              {/* Одне з найбільших підприємств металургійного комплексу України, найбільше феросплавне підприємство в
              Європі й друге у світі за обсягом виробництва марганцевих сплавів - понад 11 % світового виробництва
              феросплавів. */}
              {t("One of the")}
            </span>
          </p>
          <div className="overlap-3">
            <div className="overlap-3">
              <img className="NZF-logo" alt="Nzf logo" src={LogoNfz} />
              <img className="line-7" alt="Line" src={Line73} />
            </div>
            <div className="text-wrapper-18">
              {/* АТ Нікопольський */}
              {t("PJSC Nikopol")}
              <br />
              {/* /* завод */ }
              {t("factory")}
              <br />
              {/* // феросплавів */}
              {t("ferosplaviv")}
            </div>
          </div>
        </div>
        <div className="view-9">
          <img className="ferrexpo-removebg" alt="Ferrexpo removebg" src={LogoFerex} />
          <p className="element-3">
            {/* Група компаній, основні активи якої —&nbsp;&nbsp;виробники залізної руди в Полтавській області
            України.&nbsp;&nbsp; Підприємства видобувають залізну руду та переробляють її на окатиші. Понад 90%
            продукції — окатиші з вмістом заліза 65%. */}
            {t("The company group's")}
          </p>
          <img className="line-8" alt="Line" src={Line73} />
          <div className="ferrexpo">
            {t("Ferrexpo Holding")}
            <br />
            {t("Holding")}
          </div>
        </div>
        <div className="view-10">
          <img className="metinvest-logo" alt="Metinvest logo" src={LogoMetinv} />
          <p className="text-wrapper-19">
            {/* Міжнародна гірничо-металургійна група компаній, один з наших лояльних і надійних партнерів. Для цього
            холдингу ми виготовляємо продукцію для всіх етапів виробництва, і наша співпраця постійно розширюється. */}
            {t("An international mining")}
          </p>
          <img className="line-9" alt="Line" src={Line73}/>
          <div className="text-wrapper-20">
            {t("Metinvest Holding")}
            <br />
            {t("Holding")}
          </div>
        </div>
        <div className="view-11">
          <p className="text-wrapper-21">
            {/* Підприємство гірничо-добувної галузі, основним напрямком якого&nbsp;&nbsp;видобуток марганцевої руди
            (окисного, карбонатного та окисно-карбонатного типів), її переробка і випуск марганцево-рудного концентрату. */}
            {t("A mining and extraction")}
          </p>
          <img className="element-4" alt="Element" src={LogoPokrov}/>
          <img className="line-10" alt="Line" src={Line73} />
          <div className="text-wrapper-22">
             {t("PJSC Pokrovsky Mining")}
            <br />
            {t("mining")}
            <br />
            {t("concentrat")}
            <br />
            {t("combinat3")}
          </div>
        </div>
        <div className="div-2">
          <img className="ifcem-logo-main-reg" alt="Ifcem logo main reg" src={LogoIfcem}/>
          <p className="text-wrapper-23">
            {/* Високотехнологічний потужний комплекс виробництв промисловості будівельних матеріалів, використовують
            найновіші технології у галузі, кожна третя тонна цементу, реалізована в Україні, виготовлена на цьому
            підприємстві. */}
          {t("High-tech and powerful")}
          </p>
          <img className="line-11" alt="Line" src={Line73}/>
          <div className="text-wrapper-24">
            {/* ПрАТ  */}
            {t("PJSC Ivano-Frankivsk")}
            <br />
            {/* Івано-Франківськ */}
            {t("Ivano")}
            <br />
            {t("Cement")}
          </div>
        </div>
        <div className="view-12">
          <p className="text-wrapper-25">
            {/* Підприємство, на якому видобувається агломераційна і мартенівська руда. У загальному обсязі продукції
            близько 95% становить агломераційна руда, яка містить 61% заліза, мартенівська руда містить 54–58% заліза. */}
            {t("The enterprise")}
          </p>
          <img className="line-12" alt="Line" src={Line73}/>
          <div className="text-wrapper-26">
            {t("PJSC Ivano-Frankivsk")}
            <br />
            {t("Zaporizhzhia")}
            <br />
            {/* залізорудний */}
            {t("Iron")}
            <br />
            {t("combinat")}
          </div>
          <img className="element-5" alt="Element" src={LogoZprk}/>
        </div>
      </div>
    </div>
  );
};



export default Partners