import React from "react";
import { Component } from "../../components/Component87";
import { ImgWrapper } from "../../components/ImgWrapper87";
import Rec13 from '../../mobImages/imagesMiningEquipM/rectangle-13.svg'
import Rec49 from '../../mobImages/imagesMiningEquipM/rectangle-49.svg'
import Rec90 from '../../mobImages/imagesMiningEquipM/rectangle-90.svg'
import vagonentka from '../../mobImages/imagesMiningEquipM/4-5-1.png'
import perevernytVagon from '../../mobImages/imagesMiningEquipM/1-6-3-2.png'
import VPSH from '../../mobImages/imagesMiningEquipM/photo-2023-02-19-21-05-34-2.png'
import oprokid from '../../mobImages/imagesMiningEquipM/1-0-600-1.png'
import oprokidSec from '../../mobImages/imagesMiningEquipM/photo-2021-11-05-13-57-06-3.png'
import vrs from '../../mobImages/imagesMiningEquipM/img-1430-1.png'
import byldozer from '../../mobImages/imagesMiningEquipM/image-19.png'
import vagonetkaKovsh from '../../mobImages/imagesMiningEquipM/image-20.png'
import traktor from '../../mobImages/imagesMiningEquipM/image-22.png'
import kovsh from '../../mobImages/imagesMiningEquipM/image-21.png'
import line15 from '../../mobImages/imagesMiningEquipM/line-15.svg'
import imag from '../../mobImages/imagesMiningEquipM/image.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./miningEquipmentM.css";

export const MiningEquipmentM = () => {
  return (
    <div className="minimqm">
      <div className="div">
        <div className="overlap">
          <img className="rectangle" alt="Rectangle" src={Rec13} />
        <a href='/contacts'></a>  <div className="text-wrapper-2">Контакти</div>
        <a href='/services'></a>  <div className="text-wrapper-3">Послуги</div>
        <a href='/'></a>  <div className="text-wrapper-4">Продукція</div>
        <a href='/about'></a>  <div className="text-wrapper-5">Про компанію</div>
          {/* <Component
            GR="GR-2.png"
            PR="image.png"
            className="component-1"
            group="group-24-2.png"
            maskGroup="mask-group-2.png"
          /> */}
          <a href='/'><img className="component-1" src={LogoDark}></img></a>
          <div className="text-wrapper-6">ПРОДУКЦІЯ</div>
          <div className="rectangle-2" />
          <div className="text-wrapper-7">
            <br />
            Гірничо-шахтне обладнання
          </div>
        </div>
        <div className="text-wrapper-8">
          ТОВ ЛВК
          ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ПРОДУКЦІЯ
        </div>
        <p className="p">
          Серед основних продуктів ТОВ ЛВК ПРОМГРУП — гірничошахтне обладнання, незамінне в багатьох галузях
          промисловості. Наш продукт зарекомендував себе як надійний, високопродуктивний і простий у використанні. З
          його допомогою можна забезпечити дуже високий показник продуктивності.
          <br />
          <br />
          Нижче представлений загальний ілюстративний список з основною виробляємою нами продукцією категорії
          ГІРНИЧО-ШАХТНЕ ОБЛАДНАННЯ.
        </p>
        <div className="overlap-2">
          <div className="overlap-3">
            <div className="rectangle-3" />
            <img className="element" alt="Element" src={vagonentka} />
            <p className="div-2">
              <span className="span">ПРОМГРУП </span>
              <span className="text-wrapper-9">ПРОМГРУП </span>
              <span className="span">ПРОМГРУП </span>
              <span className="text-wrapper-9">ПРОМГРУП</span>
            </p>
            <div className="rectangle-4" />
            <div className="text-wrapper-10">+посилена версія</div>
            <div className="text-wrapper-11">Вагонетка ВГ-4,5</div>
          </div>
          <p className="element-2">
            Основні характеристики:
            <br />
            Вага: 3770 кг
            <br />
            Максимальна несуча здатність: 13.5 т<br />
            Колія: 600-900 см
            <br />
            Довжина: 3950 мм
            <br />
            Ширина: 1350 мм
            <br />
            Виробнича версія: база - s16 мм, 526 кг, <br />
            стінка - s12 мм, 285 кг, <br />
            кут- 200 x 200 x 16 мм, 160 кг
            <br />
            <br />
            Термін виготовлення: 90 днів
          </p>
        </div>
        <div className="overlap-4">
          <div className="overlap-5">
            <div className="rectangle-5" />
            <img className="img" alt="Element" src={perevernytVagon} />
            <div className="rectangle-6" />
            <div className="element-3">Вагонетка ВГ - 1,0&nbsp;&nbsp;1,6&nbsp;&nbsp;-3,3</div>
            <p className="text-wrapper-12">+ремонтні комплекти до них у різній комплектації</p>
          </div>
          <p className="element-4">
            Основні характеристики:
            <br />
            Вага: 620 - 1270 кг
            <br />
            Максимальна несуча здатність: 1500-6000 кг
            <br />
            Колія: 600-900 мм
            <br />
            Довжина: 1500-3410 мм
            <br />
            Ширина: 860-1320 мм
            <br />
            <br />
            Термін виготовлення: 90 днів
          </p>
        </div>
        <div className="overlap-6">
          <div className="overlap-7">
            <div className="rectangle-5" />
            <img className="photo" alt="Photo" src={VPSH} />
            <div className="rectangle-6" />
            <div className="element-5">
              Вагон пасажирський
              <br />
              ВПШ-18
            </div>
          </div>
          <p className="element-6">
            Основні характеристики:
            <br />
            Кількість посадочних місць: 18 шт
            <br />
            Вага: не більше 2520 кг
            <br />
            Колія: 750/900 мм
            <br />
            Довжина: 4870 мм
            <br />
            Ширина: 1350 мм
            <br />
            Висота: 1550 мм
            <br />
            <br />
            Термін виготовлення: 120 днів
          </p>
        </div>
        <div className="overlap-8">
          <div className="overlap-7">
            <div className="rectangle-5" />
            <img className="element-7" alt="Element" src={oprokid} />
            <div className="rectangle-6" />
            <div className="element-8">
              Перекидач вагонеток
              <br />
              ОК1-1,0-600А
            </div>
          </div>
          <p className="element-l">
            Основні характеристики:
            <br />
            Вага: не більше 12 000 кг <br />
            (без електрообладнання)
            <br />
            Параметри барабану: ф2800 х L1950 мм
            <br />
            Потужність приводу: 11 кВт
            <br />
            Колія: 600 мм
            <br />
            Довжина: 6243 мм
            <br />
            Ширина: 3882 мм
            <br />
            Висота: 3493,5 мм
            <br />
            <br />
            Термін виготовлення: 150 днів
          </p>
        </div>
        <div className="overlap-9">
          <div className="overlap-10">
            <div className="rectangle-5" />
            <img className="photo-2" alt="Photo" src={oprokidSec} />
            <div className="rectangle-6" />
            <div className="text-wrapper-13">Перекидач вагонеток одновагонний ОКЕ1-4,5</div>
            <div className="text-wrapper-14">+ двогвагонний ОКЕ2-4,5</div>
          </div>
          <p className="element-9">
            Основні характеристики:
            <br />
            Вага: не більше 40 000 кг <br />
            Параметри барабану: ф4000 х L4100 мм
            <br />
            Потужність приводу: 60 кВт
            <br />
            Колія: 750 мм
            <br />
            Довжина: 12000 мм
            <br />
            Ширина: 6500 мм
            <br />
            Висота: 4500 мм
            <br />
            <br />
            Термін виготовлення: 180 днів
          </p>
        </div>
        <div className="overlap-11">
          <div className="overlap-7">
            <div className="rectangle-5" />
            <img className="IMG" alt="Img" src={vrs} />
            <div className="rectangle-7" />
            <div className="element-10">
              Вагон прохідницький
              <br />
              ВПКН-7Б
            </div>
          </div>
          <p className="element-11">
            Основні характеристики:
            <br />
            Вага: максимум 9000 кг
            <br />
            Максимальна несуча здатність: 23 т<br />
            Місткість: мінімум 7,5 м³
            <br />
            Колія: 750 мм
            <br />
            Довжина: 8900 мм
            <br />
            Ширина: 1350 мм
            <br />
            Висота: 1650 мм
            <br />
            <br />
            Термін виготовлення: 120 днів
          </p>
        </div>
        <p className="element-12">
          Ми також можемо виготовити такі деталі для кар&#39;єрних екскаваторів, грейферних кранів і поворотних систем,
          бурових установок та іншого обладнання:
          <br />
          <br />
          х&nbsp;&nbsp; балки ручок;
          <br />
          х&nbsp;&nbsp; напівмуфти;
          <br />
          х&nbsp;&nbsp; зубчасті колеса, вінці, шестірні, вал-шестірні, осі механізмів екскаваторів;
          <br />
          х&nbsp;&nbsp; блоки та напівблоки;
          <br />
          х&nbsp;&nbsp; колеса натяжні, опорні та ведучі;
          <br />
          х&nbsp;&nbsp; корпуси підшипників;
          <br />
          х&nbsp;&nbsp; роликові опорні круги;
          <br />
          х&nbsp;&nbsp; цапфи центральні, рейки кільцеві;
          <br />
          х&nbsp;&nbsp; ковші ємністю від 4,6 м3 до 15 м3. та деталі до них (зуб&#39;я, днища, стінки);
          <br />
          х&nbsp;&nbsp; храпові механізми;
          <br />
          х&nbsp;&nbsp; гуркіти вібраційні та інерційні;
          <br />
          х&nbsp;&nbsp; планки гусеничні та інші металоконструкції.
        </p>
        <div className="overlap-12">
          <div className="overlap-7">
            <div className="rectangle-5" />
            <img className="image" alt="Image" src={byldozer} />
            <div className="rectangle-8" />
            <div className="text-wrapper-15">Установка бурильна УБШ</div>
            <div className="text-wrapper-16">+різноманітні виконання</div>
          </div>
          <p className="element-13">
            Основні характеристики:
            <br />
            Вага: не більше 23 000 кг <br />
            Виробнича продуктивність: 2,5 м/хв
            <br />
            Зона буріння: 8000 х 12 000 мм
            <br />
            Довжина: 2800 мм
            <br />
            Ширина: 2500 мм
            <br />
            Висота: 14500 мм
            <br />
            <br />
            Термін виготовлення: 210 днів
          </p>
        </div>
        <div className="overlap-13">
          <div className="overlap-14">
            <div className="rectangle-5" />
            <img className="image-2" alt="Image" src={vagonetkaKovsh} />
            <div className="rectangle-9" />
            <div className="element-14">
              Машина навантажувальна
              <br />
              МПП3 (ППН3М)
            </div>
          </div>
          <p className="element-15">
            Основні характеристики:
            <br />
            Вага: не більше 6 700 кг <br />
            Продуктивність: 2,0 куб.м
            <br />
            Місткість ковша, не менше: 0,6 куб.м
            <br />
            Ширина захвату, макс: 2700 х 3200 мм
            <br />
            Розмір навантажувальних шматків, до: 600 мм
            <br />
            Довжина: 3300 мм
            <br />
            Ширина: 1500 мм
            <br />
            Висота: 1800 мм
            <br />
            <br />
            Термін виготовлення: 150 днів
          </p>
        </div>
        <div className="overlap-15">
          <div className="overlap-7">
            <div className="rectangle-5" />
            <img className="image-3" alt="Image" src={traktor} />
            <div className="rectangle-8" />
            <div className="text-wrapper-17">
              Навантажувач ковшовий
              <br />
              ПКШ-М
            </div>
          </div>
          <p className="element-16">
            Основні характеристики:
            <br />
            Вага: не більше 6 700 кг <br />
            Продуктивність робочого циклу: 32-36 сек
            <br />
            Місткість ковша: 0,115/0,05 куб.м
            <br />
            Ширина захвату, макс: 2400 х 1700 мм
            <br />
            Висота розвантаження, до: 1550 мм
            <br />
            Довжина: 4100 мм
            <br />
            Ширина: 1200 мм
            <br />
            Висота: 1650 мм
            <br />
            <br />
            Термін виготовлення: 180 днів
          </p>
        </div>
        <div className="overlap-16">
          <div className="overlap-7">
            <div className="rectangle-5" />
            <img className="image-4" alt="Image" src={kovsh} />
            <div className="rectangle-10" />
            <div className="element-17">
              Машина навантажувальна
              <br />
              НК1 (ППН1М)
            </div>
          </div>
          <p className="element-18">
            Основні характеристики:
            <br />
            Вага: не більше 3 500 кг <br />
            Продуктивність: 1,25 куб.м
            <br />
            Місткість ковша, не менше: 0,26 куб.м
            <br />
            Ширина захвату, макс: 2200 мм
            <br />
            Розмір навантажувальних шматків, до: 300 мм
            <br />
            Довжина: 2500 мм
            <br />
            Ширина: 1280 мм
            <br />
            Висота: 1580 мм
            <br />
            <br />
            Термін виготовлення: 150 днів
          </p>
        </div>
        <div className="overlap-17">
          <p className="text-wrapper-18">
            Можливості компанії ТОВ ЛВК ПРОМГРУП не обмежуються представленим ілюстративним списком продукції категорії
            ГІРНИЧО-ШАХТНЕ ОБЛАДНАННЯ.
            <br />
            Для зручності Ви можете завантажити опитувальний лист на представлене обладнання, заповнити його та
            надіслати нам на електронну пошту. Наші спеціалісти оперативно зв’яжуться з Вами і допоможуть у всіх Ваших
            запитах.
            <br />
            <br />
            По будь-яким питанням зв’яжіться з нами в зручний для Вас спосіб.
          </p>
          <div className="text-wrapper-19">ВАЖЛИВО!</div>
        </div>
        <div className="overlap-18">
          <img className="rectangle-11" alt="Rectangle" src={Rec49} />
          <p className="element-19">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-12" />
          <div className="text-wrapper-20">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-21">Україна, Кривий Ріг</div>
          <p className="div-3">
            <span className="text-wrapper-22">Г</span>
            <span className="text-wrapper-23">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-24">promgrupp.pc@gmail.com</div>
          <p className="element-20">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line" alt="Line" src={line15} />
          <ImgWrapper className="component-183" img={imag} />
        </div>
      </div>
    </div>
  );
};
