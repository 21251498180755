import React from "react";
import Baraban from '../imagesMiningBene/image-6487327-1-1.png'
import BarabanSec from '../imagesMiningBene/16400-2300-2-73103-0-1.png'
import Rec91 from '../imagesMiningBene/rectangle-91.svg'
import Hrap from '../imagesMiningBene/viber-2023-02-16-12-49-32-041-1.png'
import Val from '../imagesMiningBene/0-02-05-4b553acd14b9bf197695f62c341b0e4befadc45196fe364764730cbf.png'
import Vtulka from '../imagesMiningBene/1000x800-vtulka-cilindricheskaya-2-1.png'
import BarabanThird from '../imagesMiningBene/photo-2023-04-22-21-28-33-1.png'
import Line15 from '../imagesMiningBene/line-15.svg'
import Line16 from '../imagesMiningBene/line-16.svg'
import line3 from '../imagesMiningBene/3.svg'
import Grohot from '../imagesMiningBene/1000x800-image2-1.png'
import Jivilnik from '../imagesMiningBene/1-2.png'
import Rec13 from '../imagesMiningBene/rectangle-13.svg'
import Menu from '../imagesMiningBene/2x.png'
import Logo from '../imagesMiningBene/2.svg'
import { Component255 } from "../components/Component255";
import { DivWrapper } from "../components/DivWrapper9";
import { ImgWrapper } from "../components/ImgWrapper9";
import "./miningbe.css";
import Footer from "./Footer";
import {Header} from "./Header/Header";
import { useTranslation } from 'react-i18next'

const MiningBenefication = () =>{
  const {t} = useTranslation()
    return(
        

    <div className="mining-benefication">
      <div className="div">
        <div className="view">
          <div className="overlap">
            <div className="rectangle" />
            <img className="image" alt="Image" src={Baraban} />
            <div className="rectangle-2" />
            <p className="element">
              {/* Основні характеристики */}
              {t("Main specifications")}
              <br />
              {/* (детальніше в каталозі барабанів): */}
              {t("More info")}
              <br />
              <br />
              {/* Вага: від 250 до 16000 кг */}
              {t("Weight")}
              <br />
              {/* Ширина стрічки: від 400 до 2500 мм */}
              {t("Width strich")}
              <br />
              {/* Підшипниковий вузол: імпортного */}
              {t("Pishupnukovi yzel")}
               <br />
              {/* або власного виробництва */}
              {t("Or vlasnogo")}
              <br />
              {/* Підшипники: SKF, FAG, Timken, інші */}
              {t("Podshubnik")}
              <br />
              {/* Футеровка: методом горячої вулканізації */}
              {t("Fyterovka")}
              <br />
              <br />
              {/* Термін виготовлення: 60-90 днів */}
              {t("Manufacturing")}
            </p>
            <p className="p">
              <span className="span">
                {t("Non-driven drums various configurations")}
                 <br />
              </span>
              <span className="text-wrapper-2">+ {t("various configurations second")}</span>
            </p>
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <div className="text-wrapper-3">+ {t("Drums catalog")}</div>
              </div>
            </div>
            <p className="div-2">
              <span className="text-wrapper-4">{t("PROMGRUPP")} </span>
              <span className="text-wrapper-5">{t("PROMGRUPP")}  </span>
              <span className="text-wrapper-4">{t("PROMGRUPP")}  </span>
              <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
            </p>
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap">
            <div className="rectangle" />
            <img className="img" alt="Element" src={BarabanSec} />
            <div className="rectangle-3" />
            <p className="div-3">
              <span className="span">
                {t("Drive drums various modifications")}
                <br />
              </span>
              <span className="text-wrapper-2">+ {t("various modifications")}</span>
            </p>
            <p className="element-2">
              {/* "Основні характеристики" */}
              {t("Main specifications vg-4.5")}
              <br />
              {/* (детальніше в каталозі барабанів): */}
              {t("More info")}
              <br />
              <br />
              {/* Вага: від 250 до 16000 кг */}
              {t("Weight")}
              <br />
              {/* Ширина стрічки: від 400 до 2500 мм */}
              {t("Width strich")}
              <br />
              {/* Підшипниковий вузол: імпортного */}
              {t("Pishupnukovi yzel")}
               <br />
              {/* або власного виробництва */}
              {t("Or vlasnogo")}
              <br />
              {/* Підшипники: SKF, FAG, Timken, інші */}
              {t("Podshubnik")}
              <br />
              {/* Футеровка: методом горячої вулканізації */}
              {t("Fyterovka")}
              <br />
              <br />
              {/* Термін виготовлення: 60-90 днів */}
              {t("Manufacturing")}
            </p>
            <div className="view-2">
              <div className="overlap-group">
                <div className="text-wrapper-6">+ {t("Drums catalog")}</div>
              </div>
            </div>
            <p className="div-2">
              <span className="text-wrapper-4">{t("PROMGRUPP")}  </span>
              <span className="text-wrapper-5">{t("PROMGRUPP")}  </span>
              <span className="text-wrapper-4">{t("PROMGRUPP")}  </span>
              <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
            </p>
          </div>
        </div>
        <div className="view-3">
          <div className="overlap-2">
            <div className="overlap-3">
              <img className="rectangle-4" alt="Rectangle" src={Rec91} />
              <img className="viber" alt="Viber" src={Hrap} />
              <div className="rectangle-5" />
              <p className="div-4">
                <span className="span">
                  {t("Ratchet mechanism")}
                  <br />
                </span>
                <span className="text-wrapper-2">+ {t("various modifications")}</span>
              </p>
              <p className="div-5">
                <span className="text-wrapper-4">{t("PROMGRUPP")}  </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")}  </span>
                <span className="text-wrapper-4">{t("PROMGRUPP")}  </span>
                <span className="text-wrapper-5">{t("PROMGRUPP")} </span>
              </p>
            </div>
            <p className="element-3">
              {/* Основні характеристики: */}
              {t("Main specifications ratchet")}
              <br />
              {/* Діаметр коронки: від 1200 мм до 1600 мм */}
              {t("Diametr")}
              <br />
              {/* Розміри: від 2025 х 1720 х 320 мм  */}
              {t("Size")}
              <br />
              {/* до 2605 х 3080 х 500 мм */}
              {t("2605")}
              <br />
              {/* Вага: від 845 кг до 2900 кг */}
              {t("Weightq")}
              <br />
              <br />
              {/* Термін виготовлення: від 90 днів  */}
              {t("Manufacturing lead")}
              <br />
              {/* до 150 днів */}
            </p>
          </div>
        </div>
        <div className="view-4">
          <div className="overlap-4">
            <div className="overlap-5">
              <div className="rectangle" />
              <img
                className="img"
                alt="Element"
                src={Val}
              />
              <div className="rectangle-6" />
              <p className="div-6">
                <span className="span">
                  {/* Вали ванни мечової */}
                  {t("Mixer shaft")}
                  <br />
                </span>
                <span className="text-wrapper-2">+
                {/* вали ванни комбінової з колесом ковшовим */}
                {t("shaft with bucket wheel")}
                </span>
              </p>
              <p className="div-2">
                <span className="text-wrapper-4">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="text-wrapper-4">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span>
              </p>
            </div>
            <p className="element-4">
              {/* Основні характеристики: */}
              {t("Main specifications shaft")}
              <br />
              {/* Вага: не більше 11 200 кг */}
              {t("Weight11200")}
              <br />
              {/* Довжина: 6389,5 мм */}
              {t("Length6389")}
              <br />
              {/* Ширина: 3200 мм */}
              {t("Width3200")}
              <br />
              {/* Висота: 3200 мм */}
              {t("height3200")}
              <br />
              {/* Особливості:  */}
              {t("Osobluvosti")}
              <br />
              {/* Елементи підвищеної абразивності -  */}
              {t("Elementu")}
              <br />
              {/* матеріал Ст110Г13Л */}
              {t("Material")}
              <br />
              <br />
              {/* Термін виготовлення: 180 днів */}
              {t("Manufacturing lead time: 180 days")}
            </p>
          </div>
        </div>
        <div className="view-5">
          <div className="overlap-6">
            <div className="overlap-5">
              <div className="rectangle" />
              <img className="img" alt="Element vtulka" src={Vtulka} />
              <div className="rectangle-7" />
              <p className="element-5">
                <span className="span">
                  {/* Втулка циліндрична для&nbsp;&nbsp;КМД-2200, КСД-2200 */}
                  {t("Cylindrical bushing")}
                  <br />
                </span>
                <span className="text-wrapper-2">+ {t("all modifications")}</span>
              </p>
              <p className="div-2">
                <span className="text-wrapper-4">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="text-wrapper-4">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span>
              </p>
            </div>
            <p className="element-6">
              {/* Основні характеристики: */}
              {t("Main specifications cylindrical")}
              <br />
              {/* Вага: 481 кг */}
              {t("Weight481")}
              <br />
              {/* Габаритні розміри втулки циліндричної на КДМ - 2200 ч.1275.01.305СБ: Діаметр внутрішній - 640+0,15 мм,
              Діаметр зовнішній - 680-0,05/690+0,08/706+0,08 мм */}
              {t("Gabarutni size")}
              <br />
              {/* Довжина - 1235 мм */}
              {t("Length")}
              <br />
              {/* Матеріал: Ст3 ГОСТ 380-2005. Антифрикційне покриття - бабіт Б16 ГОСТ 1320-74. Товщина наплавлення - не
              менше 3 мм. */}
              {t("Materialw")}
              <br />
              {/* Термін виготовлення: 60 днів */}
              {t("Manufacturing lead time: 60 days")}
            </p>
          </div>
        </div>
        <div className="view-6">
          <div className="overlap-7">
            <div className="rectangle" />
            <img className="photo" alt="Photo" src={BarabanThird} />
            <div className="rectangle-8" />
            <p className="element-7">
              {/* Основні характеристики */}
              {t("Main specifications")}
              <br />
              {/* (детальніше в каталозі барабанів): */}
              {t("More info")}
              <br />
              <br />
              {/* Вага: від 250 до 16000 кг */}
              {t("Weight")}
              <br />
              {/* Ширина стрічки: від 400 до 2500 мм */}
              {t("Width strich")}
              <br />
              {/* Підшипниковий вузол: імпортного  */}
              {t("Pishupnukovi yzel")}
              <br />
              {/* або власного виробництва */}
              {t("Or vlasnogo")}
              <br />
              {/* Підшипники: SKF, FAG, Timken, інші */}
              {t("Podshubnik")}
              <br />
              {/* Футеровка: методом горячої вулканізації */}
              {t("Fyterovka")}
              <br />
              <br />
              {/* Термін виготовлення: 60-90 днів */}
              {t("Manufacturing")}
            </p>
            <p className="div-7">
              <span className="span">
                {/* Барабани натяжні */}
                {t("Drive tension drums")}
                <br />
              </span>
              <span className="text-wrapper-2">+ {t("various configurations second")}</span>
            </p>
            <div className="view-7">
              <div className="overlap-group">
                <div className="text-wrapper-7">+ {t("Drums catalog")}</div>
              </div>
            </div>
            <p className="div-2">
              <span className="text-wrapper-4">ПРОМГРУП </span>
              <span className="text-wrapper-5">ПРОМГРУП </span>
              <span className="text-wrapper-4">ПРОМГРУП </span>
              <span className="text-wrapper-5">ПРОМГРУП</span>
            </p>
          </div>
        </div>
        <p className="text-wrapper-8">
          {/* Ми також можемо виготовити наступне обладнання для механізмів:  */}
          {t("We can also manufacture the following equipment for mechanisms")}
          <br />
          {/* х&nbsp;&nbsp; \*приводний, натяжний і дефлекторний барабани; */}
          х&nbsp;&nbsp; {t("drive, tension, and deflection drums")}
          <br />
          {/* х&nbsp;&nbsp; верхній і нижній ролики; */}
          х&nbsp;&nbsp;{t("upper and lower rollers")}
          <br />
          {/* х&nbsp;&nbsp; сідла барабанні та роликові; */}
          х&nbsp;&nbsp;{t("drum and roller saddles")}
          <br />
          {/* х&nbsp;&nbsp; машини для загортання та розпакування; */}
          х&nbsp;&nbsp;{t("wrapping and unwrapping machines")}
          <br />
          {/* х&nbsp;&nbsp; машина для намотування та розмотування стрічки; */}
          х&nbsp;&nbsp;{t("strip winding and unwinding machine")}
          <br />
          {/* х&nbsp;&nbsp; муфти диференційні; */}
          х&nbsp;&nbsp;{t("differential couplings")}
          <br />
          {/* х&nbsp;&nbsp; затискний механізм та інші. */}
          х&nbsp;&nbsp;{t("clamping mechanism and others.")}
          <br />
          {/* Ми також можемо виготовляти та ремонтувати деталі та вузли кранів, самоскидів, вантажівок та ін. */}
          {t("We also")}
        </p>
        <div className="view-8">
            <Footer></Footer>
          
        </div>
        <div className="view-9">
          <div className="overlap-9">
            <p className="text-wrapper-14">
              {/* Можливості компанії ТОВ ЛВК ПРОМГРУП не обмежуються представленим ілюстративним списком продукції
              категорії ГІРНИЧО-ЗБАГАЧУВАЛЬНЕ ОБЛАДНАННЯ. */}
              {t("The capabilities")}
              <br />
              {/* Для зручності Ви можете завантажити каталог на представлене обладнання, заповнити його та надіслати нам на
              електронну пошту. Наші спеціалісти оперативно зв’яжуться з Вами і допоможуть у всіх Ваших запитах. */}
              {t("Для зручности")}
              <br />
              <br />
              {/* По будь-яким питанням зв’яжіться з нами в зручний для Вас спосіб. */}
              {t("Please feel free to contact us using your preferred method for any further questions or inquiries")}
            </p>
            <div className="text-wrapper-15">{t("IMPORTANT")}!</div>
          </div>
        </div>
        <div className="view-10">
          <div className="overlap-10">
            <div className="overlap-11">
              <div className="rectangle" />
              <img className="element-10" alt="Element" src={Grohot} />
              <div className="rectangle-2" />
              <p className="p">
                <span className="span">{t("Vibrating Screens")} </span>
                <br/>
                <span className="text-wrapper-2">{t("foreign production")}</span>
              </p>
              <p className="div-9">
                <span className="text-wrapper-4">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="text-wrapper-4">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span>
              </p>
            </div>
            <p className="element-11">
              {/* Основні характеристики екрану та вібратора: */}
              {t("Main specifications vibrating")}
              <br />
              {/* Опис екрана: 5650 х 1820 х 2000 мм, */}
              {t("Description")}
               <br />
              {/* розмір вібратора: 900 х 940 х 630 мм */}
              {t("Size vib")}
              <br />
              {/* Вага екрану: 4456 кг,  */}
              {t("Weight window")}
              <br />
              {/* вага вібратору: 1040 кг */}
              {t("Weight vib")}
              <br />
              <br />
              {/* Грохот: 5650 x 1800 мм */}
              {t("Groxot")}
              <br />
              {/* Амплітуда вібрації: 9 мм */}
              {t("Amplitude vibration")}
              <br />
              {/* Частота моргання: 1010 1/min */}
              {t("Chastota")}
              <br />
              <br />
              {/* Термін виготовлення: 180 днів */}
              {t("Manufacturing lead time: 180 days after complete design")}
              <br />
              {/* після повного проектування */}
              {t("After")}
            </p>
          </div>
        </div>
        <div className="view-11">
          <div className="overlap-12">
            <div className="overlap-13">
              <div className="rectangle" />
              <img className="element-10" alt="Element" src={Jivilnik}/>
              <div className="rectangle-2" />
              <p className="element-12">
                <span className="span">
                  {/* Живильники ПП2-15-30 */}
                  {t("Plate feeder ПП2-15-30")}
                  <br />
                </span>
                <span className="text-wrapper-2">+ {t("other types and sizes")}</span>
              </p>
              <p className="div-9">
                <span className="text-wrapper-4">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП </span>
                <span className="text-wrapper-4">ПРОМГРУП </span>
                <span className="text-wrapper-5">ПРОМГРУП</span>
              </p>
            </div>
            <p className="element-13">
              {/* Основні характеристики: */}
              {t("Main characteristics feeders")}
              <br />
              {/* Вага: 17560 кг, */}
              {t("Weight17560")}
              <br />
              {/* Ширина полотна: 1500 мм, */}
              {t("Width1500")}
              <br />
              {/* Продуктивність: 270/400/540/800 м.куб на год, */}
              {t("Productivity")}
              <br />
              {/* Довжина живильника з приводом: 4740 мм, */}
              {t("LengthJivilnik")}
              <br />
              {/* Висота живильника  */}
              {t("HeightJivilnik")}
              <br />
              {/* (до стрічки живильника): 1530 мм */}
              {t("beforeStr")}
              <br />
              {/* Редуктор: ЦТ-1450, */}
              {t("Reductor")}
              <br />
              {/* Електродвигун: АИР 200 М4, 37 кВт. */}
              {t("Electrodvug")}
              <br />
              <br />
              {/* Термін виготовлення: 180 днів */}
              {t("Manufacturing lead time: 180 days after complete design")}
            </p>
          </div>
        </div>
        <div className="div-10">
          <div className="div-10">
            <div className="overlap-14">
              <img className="rectangle-11" alt="Rectangle" src={Rec13} />
              <div className="text-wrapper-16">
                <br />
                {/* Гірничо-збагачувальне обладнання */}
                {t("Mining and beneficiation equipment")}
              </div>
              <div className="text-wrapper-17">{t("PRODUCTSUPPER")}</div>
              <div className="rectangle-12" />
              <div className="text-wrapper-18">
                {t("LLC")} {t("FPC Promgrupp")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("PRODUCTSUPPER")}
              </div>
            </div>
            <p className="element-14">
              {/* Значну частину виробленої продукції займає гірничо-збагачувальне обладнання. В основному це виготовлення
              нових вузлів або деталей для стрічкових конвеєрів та інших (з шириною стрічки від 400 мм до 2500 мм), а
              також інших передавальних і живильних пристроїв. */}
              {t("A significant portion")}
              <br />
              <br />
              {/* Нижче представлений загальний ілюстративний список з основною виробляємою нами продукцією категорії
              ГІРНИЧО-ЗБАГАЧУВАЛЬНЕ ОБЛАДНАННЯ. */}
              {t("Below is a general")}
            </p>
          </div>
          {/* <img className="image-2" alt="Image" src={Menu} /> */}
          <Header cls='wrp2' divClassName='light'/>
          {/* <DivWrapper className="component-254" divClassName="component-254-instance" />
          <DivWrapper
            className="design-component-instance-node"
            divClassName="component-254-instance"
            text="Продукція"
          />
          <DivWrapper className="view-12" divClassName="component-254-instance" text="Послуги" />
          <DivWrapper className="view-13" divClassName="component-254-instance" text="Контакти" /> */}
         <a href='/'><Component255 className="component-255-instance" imgWrapperImg={Logo} />  </a> 
        </div>
      </div>
    </div>
  );
};


export default MiningBenefication