import React from "react";
import { ImgWrapper } from "../../components/ImgWrapper84";
import Line66 from '../../mobImages/imagesPartnersM/line-66.svg'
import Line70 from '../../mobImages/imagesPartnersM/line-70.svg'
import Line17 from '../../mobImages/imagesPartnersM/line-17.svg'
import Rec99 from '../../mobImages/imagesPartnersM/rectangle-99.svg'
import Getty from '../../mobImages/imagesPartnersM/getty-678074927-2000133320009280137-360656-1.png'
import Getty2 from '../../mobImages/imagesPartnersM/getty-678074927-2000133320009280137-360656-3.png'
import dtek from '../../mobImages/imagesPartnersM/dtek-logo-1.png'
import removeBg from '../../mobImages/imagesPartnersM/removebg-preview-1.png'
import removeBg1x2 from '../../mobImages/imagesPartnersM/removebg-preview-1-2x.png'
import Pokrov from '../../mobImages/imagesPartnersM/3-1.png'
import zzrk from '../../mobImages/imagesPartnersM/1.png'
import nzf from '../../mobImages/imagesPartnersM/nzf-logo-1.png'
import ifcem from '../../mobImages/imagesPartnersM/ifcem-logo-main-reg-1.png'
import ferrexpro from '../../mobImages/imagesPartnersM/ferrexpo-1-removebg-preview-1.png'
import metinvest from '../../mobImages/imagesPartnersM/metinvest-logo-1.png'
import One from '../../mobImages/imagesPartnersM/1.svg'
import Logo from '../../imagesDesignEng/3.svg'
import LogoDark from '../../imagesDesignEng/2.svg'
import "./partnersM.css";

export const PartnesM = () => {
  return (
    <div className="partnersm">
      <div className="div">
        <div className="overlap">
          <div className="overlap-group">
            {/* <div className="text-wrapper">LLC FPC</div>
            <div className="UP">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; UP</div>
            <div className="m">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; M</div>
            <img className="PR" alt="Pr" src="/img/pr.png" />
            <img className="GR" alt="Gr" src="/img/gr.png" />
            <img className="mask-group" alt="Mask group" src="/img/mask-group.png" /> */}
            <a href='/'><img className="mask-group" src={Logo}></img></a>
           <a href='/about'><div className="text-wrapper-2">Про компанію</div></a> 
           <a href='/'><div className="text-wrapper-3">Продукція</div></a> 
            <div className="rectangle" />
            <img className="line" alt="Line" src={Line66} />
            <div className="text-wrapper-4">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослугиВиготовлення
              <br />
              Проектування
              <br />
              Металообробка
            </div>
            <img className="getty" alt="Getty" src={Getty} />
            <div className="text-wrapper-5">Про компанію</div>
            <div className="rectangle-2" />
            <div className="text-wrapper-6">
              ТОВ ЛВК
              ПРОМГРУП&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ДОСВІД
            </div>
            <div className="text-wrapper-7">
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              РемонтПослуги
              <br />
              Виготовлення
              <br />
              Проектування
              <br />
              Металообробка
              <br />
              Термообробка
              <br />
              Виготовлення
             
            </div>
            <div className="rectangle-3" />
            <img className="img" alt="Getty" src={Getty2} />
          </div>
        <a href='/services'><div className="text-wrapper-8">Послуги</div></a>  
        <a href='/contacts'><div className="text-wrapper-9">Контакти</div></a>  
        </div>
        <p className="p">Розширюємо свої можливості завдяки довірі наших партнерів.</p>
        <img className="dtek-logo" alt="Dtek logo" src={dtek} />
        <img className="logo-removebg" alt="Logo removebg" src={removeBg} />
        <div className="text-wrapper-10">Група ДТЕК</div>
        <div className="text-wrapper-11">
          АТ Південний
          <br />
          гірничо
          <br />
          збагачувальний
          <br />
          комбінат
        </div>
        <img className="line-2" alt="Line" src={Line70} />
        <img className="line-3" alt="Line" src={Line70} />
        <img className="line-4" alt="Line" src={Line70} />
        <img className="line-5" alt="Line" src={Line70} />
        <img className="line-6" alt="Line" src={Line70} />
        <img className="line-7" alt="Line" src={Line70}/>
        <img className="line-8" alt="Line" src={Line70} />
        <img className="line-9" alt="Line" src={Line70} />
        <div className="overlap-2">
          <div className="overlap-3">
            <img className="line-10" alt="Line" src={Line70} />
            <img className="element" alt="Element" src={Pokrov} />
          </div>
          <div className="text-wrapper-12">
            АТ Покровський
            <br />
            гірничо
            <br />
            збагачувальний
            <br />
            комбінат
          </div>
        </div>
        <img className="line-11" alt="Line" src={Line70} />
        <img className="removebg-preview" alt="Removebg preview" src={removeBg1x2} />
        <div className="text-wrapper-13">
          АТ Криворізький
          <br />
          залізорудний
          <br />
          комбінат
        </div>
        <img className="element-2" alt="Element" src={zzrk}/>
        <img className="removebg-preview-2" alt="Removebg preview" src={removeBg} />
        <div className="text-wrapper-14">
          АТ Марганецький
          <br />
          гірничо
          <br />
          збагачувальний
          <br />
          комбінат
        </div>
        <p className="i">
          Найбільший приватний національний інвестор. <br />
          Iнвестують в розвиток української енергетичної галузі: впроваджують інноваційні технології та будують нові
          потужності, розвивають та модернізують виробництво.
        </p>
        <p className="text-wrapper-15">
          Компанія, що спеціалізується на видобутку та збагаченні залізистих магнетитових кварцитів з отриманням
          залізорудного концентрату та доменного агломерату. <br />
          Це один з наших постійних і надійних клієнтів, для якого ми виготовляємо продукцію практично для всіх етапів
          виробництва і наша співпраця постійно розширюється.
        </p>
        <p className="text-wrapper-16">
          Найбільше підприємство України з видобутку залізної руди підземним способом. Ця компанія є одним з наших
          постійних клієнтів, для якого ми виготовляємо продукцію для різних етапів виробництва.
        </p>
        <p className="text-wrapper-17">
          Підприємство, на якому видобувається агломераційна і мартенівська руда. У загальному обсязі продукції близько
          95% становить агломераційна руда, яка містить 61% заліза, мартенівська руда містить 54–58% заліза.
        </p>
        <p className="text-wrapper-18">
          Підприємство з видобутку та переробки марганцевої руди, єдине підприємство в Україні, яке видобуває марганцеву
          руду комбінованим способом: підземним і відкритим.
        </p>
        <p className="text-wrapper-19">
          Підприємство гірничо-добувної галузі, основним напрямком якого&nbsp;&nbsp;видобуток марганцевої руди
          (окисного, карбонатного та окисно-карбонатного типів), її переробка і випуск марганцево-рудного концентрату.
        </p>
        <img className="NZF-logo" alt="Nzf logo" src={nzf} />
        <img className="ifcem-logo-main-reg" alt="Ifcem logo main reg" src={ifcem} />
        <div className="text-wrapper-20">
          АТ Нікопольський
          <br />
          завод
          <br />
          феросплавів
        </div>
        <div className="text-wrapper-21">
          ПрАТ <br />
          Івано-Франківськ
          <br />
          Цемент
        </div>
        <p className="span-wrapper">
          <span className="span">
            Одне з найбільших підприємств металургійного комплексу України, найбільше феросплавне підприємство в Європі
            й друге у світі за обсягом виробництва марганцевих сплавів - понад 11 % світового виробництва феросплавів.
          </span>
        </p>
        <p className="text-wrapper-22">
          Високотехнологічний потужний комплекс виробництв промисловості будівельних матеріалів, використовують
          найновіші технології у галузі, кожна третя тонна цементу, реалізована в Україні, виготовлена на цьому
          підприємстві.
        </p>
        <img className="ferrexpo-removebg" alt="Ferrexpo removebg" src={ferrexpro} />
        <img className="metinvest-logo" alt="Metinvest logo" src={metinvest} />
        <div className="ferrexpo">
          Ferrexpo
          <br />
          Холдинг
        </div>
        <div className="text-wrapper-23">
          Метинвест
          <br />
          Холдинг
        </div>
        <p className="element-3">
          Група компаній, основні активи якої —&nbsp;&nbsp;виробники залізної руди в Полтавській області
          України.&nbsp;&nbsp; Підприємства видобувають залізну руду та переробляють її на окатиші. Понад 90% продукції
          — окатиші з вмістом заліза 65%.
        </p>
        <div className="overlap-4">
          <img className="rectangle-4" alt="Rectangle" src={Rec99} />
          <p className="element-4">
            +38 067 638 40 80
            <br />
            +38 067 569 11 33
            <br />
            +38 056 493 80 39
            <br />
            +38 056 493 80 38
          </p>
          <div className="rectangle-5" />
          <div className="text-wrapper-24">
            ВиготовленняПроектування
            <br />
            МеталообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
            <br />
            алообробкаПослугиТермообробка
            <br />
            ВиготовленняРемонтПослуги
            <br />
            МеталообробкаТермообробкаРемонт
            <br />
            МеталообробкаВиготовлення
          </div>
          <div className="text-wrapper-25">Україна, Кривий Ріг</div>
          <p className="div-2">
            <span className="text-wrapper-26">Г</span>
            <span className="text-wrapper-27">
              оловна
              <br />
              <br />
              Про компанію
              <br />
              <br />
              Продукція
              <br />
              <br />
              Послуги
              <br />
              <br />
              Контакти
            </span>
          </p>
          <div className="text-wrapper-28">promgrupp.pc@gmail.com</div>
          <p className="element-5">
            Всі права захищені. Використання матеріалів тільки з дозволу власника.
            <br />© 2023
          </p>
          <img className="line-12" alt="Line" src={Line17}/>
          <ImgWrapper className="image" img={One} />
        </div>
        <div className="text-wrapper-29">
          ПрАТ <br />
          Запорізький
          <br />
          залізорудний
          <br />
          комбінат
        </div>
        <p className="text-wrapper-30">
          Міжнародна гірничо-металургійна група компаній, один з наших лояльних і надійних партнерів. Для цього холдингу
          ми виготовляємо продукцію для всіх етапів виробництва, і наша співпраця постійно розширюється.
        </p>
      </div>
    </div>
  );
};
