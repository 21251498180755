import React from 'react'
import {GoogleMap, Marker, InfoWindow} from '@react-google-maps/api'




const Map = () =>{
    const containerStyle = {
        top: '120px',
        width: '1440px',
        height: '500px'
      };
      
      const center = {
        lat: 47.9008955,
        lng: 33.343618
      };
    
      const markerPosition = {
        lat: 47.9008955,
        lng: 33.343618,
      };
   
      const infoWindowContent = "Свято-николаевская 55, 3 этаж"
    const mapRef = React.useRef(undefined)
    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        mapRef.current = map
      }, [])
    
      const onUnmount = React.useCallback(function callback(map) {
        mapRef.current = undefined
      }, [])
    
    return(
        <div>
              <GoogleMap
         mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
        onLoad={onLoad}
        Advance
        onUnmount={onUnmount}
      > 
      {/* {savedMarker && <Marker position={center} />} */}
      {/* <Marker position={markerPosition} onLoad={onLoad} /> */}
      <Marker position={{ lat: 47.9008955, lng: 33.343618 }} />

        {markerPosition && (
          <InfoWindow position={markerPosition}>
            <div>{infoWindowContent}</div>
          </InfoWindow>
        )}
      </GoogleMap>
        </div>
    )
}
export default Map