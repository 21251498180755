import React from "react";
import Photo from '../imagesHeatTr/photo-2023-04-24-10-53-35-removebg-preview-1.png'
import Remove from '../imagesHeatTr/removebg-preview-1.png'
import Icon from '../imagesHeatTr/gear-icon-gear-icon-design-illustration-gear-icon-collection-gea.png'
import Line15 from '../imagesHeatTr/line-15.svg'
import Line16 from '../imagesHeatTr/line-16.svg'
import Logo from '../imagesHeatTr/3.svg'
import Rec13 from '../imagesHeatTr/rectangle-13.svg'
import LogoLight from '../imagesHeatTr/2.svg'
import Menu from '../imagesHeatTr/2x.png'
import Rec88 from '../imagesHeatTr/rectangle-88.svg'
import { useTranslation } from 'react-i18next'
import { DivWrapper } from "../components/DivWrapper14";
import { ImgWrapper } from "../components/ImgWrapper14";
import "./heatTreatment.css";
import Footer from './Footer'
import { Header } from "./Header";

const HeatTreatment = () =>{
  const {t} = useTranslation()
    return(
    

    <div className="heat-treatment">
      <div className="div">
        <div className="overlap">
          <div className="rectangle" />
          <p className="p">
            {/* ТОВ ЛВК ПРОМГРУП пропонує послуги з термообробки, як для серійного виробництва, так і для одиночних виробів,
            серед яких: */}
            {t("LLC FPC PROMGROUP offers")}
          </p>
        </div>
        <div className="overlap-group">
          <div className="overlap-2">
            <div className="rectangle-2" />
            <p className="div-2">
              <span className="span">
                <br />
                <br />
                {/* Термообробка є комплексом операцій нагрівання, охолодження та витримки металевих твердих сплавів для
                отримання необхідних властивостей завдяки  */}
                {t("Heat treatment second")}
                <br />
                {/* зміні структури та внутрішньої будови. Термічна обробка застосовується як проміжна операція для того,
                щоб поліпшити оброблюваність різанням, тиском, або як остаточну операцію технологічного процесу, яка
                забезпечує необхідний рівень властивостей деталі. */}
                {t("Zmini")}
                <br />
                <br />
                {/* Досвідчені спеціалісти нашої компанії володіють всіма знаннями, які дозволяють досягти необхідних
                властивостей кожного виду сплавів, які підляють термічній обробці. Для кожного металу властива своя
                критична температура, а це означає, що термообробка повинна проводитися з урахуванням структурних та
                фізико-хімічних особливостей речовини. Зрештою це дозволить як досягти необхідних результатів, а й
                значною мірою раціоналізувати виробничі процеси. */}
                {t("Our experienced")}
                <br />
                <br />
              </span>
              <span className="text-wrapper-2">
                {/* Термічна обробка деталей (включаючи термохімічну обробку) проводиться: */}
                {t("Thermal treatment of components (including thermochemical treatment) is performed")}
              </span>
            </p>
            <img className="photo" alt="Photo" src={Photo} />
            <img className="removebg-preview" alt="Removebg preview" src={Remove} />
          </div>
          <div className="group">
            <p className="text-wrapper-3">
              {/* нагрівання до певної температури, витримки, швидкого охолодження; застосовується у поєднанні з іншим
              різновидом термообробки – відпуском */}
              {t("heating to a specific temperature, holding (soaking), rapid cooling; used in combination with another type of heat treatment - tempering.")}
            </p>
            <p className="text-wrapper-4">
              {/* фінальна термічна обробка після гарту; виконується нагрівання виробів до певної температури з подальшим
              охолодженням */}
              {t("final heat treatment after hardening; involves heating the products to a certain temperature followed by cooling.")}
            </p>
            <p className="text-wrapper-5">
              {/* нагрівання металу, витримка при певних температурах та подальше повільне охолодження */}
              {t("heating of the metal, holding at certain temperatures, and subsequent slow cooling")}
            </p>
            <p className="text-wrapper-6">
              {/* вид відпалу, полягає в нагріванні металу, витримці при певних температурах та подальшому швидкому
              охолодженні на повітрі */}
              {t("heating of the metal, holding at certain temperatures, and subsequent slow cooling")}
            </p>
            <p className="text-wrapper-7">
              {/* дифузійне насичення поверхневого шару сталевих виробів атомами вуглецю при нагріванні до певної
              температури */}
              {t("diffusion saturation of the surface layer of steel products with carbon atoms during heating to a certain temperature")}
            </p>
            <p className="text-wrapper-8">
              {t("")}
              {/* швидкий нагрів металу за допомогою індукційного впливу струмами високої частоти з послідуючим охоложенням
              у гартувальній середі */}
              {t("rapid heating of metal through induction using high-frequency currents with subsequent cooling in a hardening medium")}
            </p>
            <div className="text-wrapper-9">{t("hardening")}</div>
            <div className="text-wrapper-10">{t("tempering")}</div>
            <div className="text-wrapper-11">{t("annealing")}</div>
            <div className="text-wrapper-12">{t("carburizing")}</div>
            <div className="text-wrapper-13">{t("normalization")}</div>
            <div className="text-wrapper-14">{t("microwave hardening")}</div>
            <img
              className="gear-icon-gear-icon"
              alt="Gear icon gear icon"
              src={Icon}
            />
            <img
              className="img"
              alt="Gear icon gear icon"
              src={Icon}
            />
            <img
              className="gear-icon-gear-icon-2"
              alt="Gear icon gear icon"
              src={Icon}
            />
            <img
              className="gear-icon-gear-icon-3"
              alt="Gear icon gear icon"
              src={Icon}
            />
            <img
              className="gear-icon-gear-icon-4"
              alt="Gear icon gear icon"
              src={Icon}
            />
            <img
              className="gear-icon-gear-icon-5"
              alt="Gear icon gear icon"
              src={Icon}
            />
          </div>
        </div>
        <div className="view">

             
          <Footer/>
        </div>
        <div className="div-4">
          <div className="div-4">
            <div className="overlap-3">
              <img className="rectangle-5" alt="Rectangle" src={Rec13} />
              <div className="text-wrapper-20">
                {t("Heat treatment and microwave heat treatment")}
                <br />
                {t("СВЧ")}
              </div>
              <div className="text-wrapper-21">{t("SERVICESS")}</div>
              <div className="rectangle-6" />
              <div className="text-wrapper-22">
                {t("LLC")}{t(" ")} 
                {t("FPC Promgrupp")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("SERVICESS")}
              </div>
            </div>
          </div>
        <a href="/"> <DivWrapper className="component-139" imgWrapperImg={LogoLight} /> </a>  
          {/* <img className="image-2" alt="Image" src={Menu} /> */}
          <Header cls='wrp' divClassName='light'/>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-4">
            <img className="rectangle-7" alt="Rectangle" src={Rec88}/>
            <p className="text-wrapper-23">
              {/* Заявки на прорахунок вартості термообробки деталей Ви можете відправити нам на електронну
              адресу promgrupp.pc@gmail.com */}
              {t("You can send requests for the cost estimation of thermal treatment services for your parts to our email address.")}
            </p>
            <div className="text-wrapper-24">{t("IMPORTANT")}!</div>
          </div>
        </div>
        <p className="element-HRC">
          <br />
          {/* Особливо відзначимо обробку деталей на СВЧ, що представляє собою вид термічної обробки, головна відмінність
          якої заключається у швидкому нагріві металу за допомогою індукційного впливу струмами високої частоти з
          послідуючим охоложенням у гартувальній середі, якою можуть служити вода або інші гартувальні середовища. Такий
          вид термічної обробки дає можливість підвищити твердість поверхності до 60 HRC, при цьому залишаючи м&#39;якою
          й пластичною сердцевину, тим самим виключаючи крихкість виробу. Також використання СВЧ здатне забезпечити
          найбільш високу зносостійкість загартованого шару порівняно з об&#39;ємним загартуванням, що відбувається за
          рахунок швидкого нагріву та інтенсивного охолодження. */}
          {t("We particularly highlight")}
          <br />
          <br />
          {/* Термообробка на СВЧ виконується кваліфікованими й досвідченими фахівцями на сучасній установці, що забезпечує */}
          {/* високу якість й швидкість виконання робіт. */}
          {t("Microwave thermal treatment")}
        </p>
        <div className="view-5">
          <div className="overlap-5">
            <div className="rectangle-8" />
            <div className="text-wrapper-25">{t("In microwave installations")}</div>
          </div>
          <div className="overlap-6">
            <div className="rectangle-8" />
            <div className="text-wrapper-26">{t("In furnaces")}</div>
          </div>
        </div>
        <div className="view-6">
          <div className="element-wrapper">
            <p className="element-3">
              {/* вали діаметром до 300 мм */}
              {t("cylindrical parts with diameters up to 300 mm and lengths up to 1300 mm can be processed using the scanning method.")}
               <br />
              {/* та довжиною до 1300 мм */}
              {t("Length1300")}
              <br />
              {/* (скануючий метод) */}
              {t("scanyuchi")}
            </p>
          </div>
          <div className="overlap-7">
            <p className="element-4">
              {/* шківи й кранові колеса діаметром */}
              {t("pulleys and crane wheels with diameters up to 2000 mm and widths up to 500 mm can be processed using the continuous-sequential method.")}
              <br />
              {/* до 2000 мм і шириною до 500 мм */}
              {t("before2000mm")}
              <br />
              {/* (безперервно-послідовний метод) */}
              {t("bezperervno")}
            </p>
          </div>
          <div className="overlap-8">
            <p className="text-wrapper-27">
              {/* вироби великих габаритів й складної конфігурації  */}
              {t("Large-sized and complex-shaped products can be processed through individualized treatment.")}
              <br />
              {/* (з індивідуального опрацювання) */}
              {t("Zinduvidual")}
            </p>
          </div>
          <div className="overlap-9">
            <p className="element-5">
              {/* шестерні й зубчаті колеса діаметром */}
              {t("gears and toothed wheels with diameters up to 2000 mm processed using the tooth-by-tooth scanning method")}
               <br />
              {/* до 2000 мм */}
              {t("do2000mm")}
              <br />
              {/* (скануючий метод по зубу) */}
              {t("skanyuchi")}
            </p>
          </div>
          <p className="text-wrapper-28">
            {/* Пропонуємо послуги по термообробці на СВЧ наступних деталей: */}
            {t("We offer microwave thermal treatment services for the following parts:")}
            </p>
        </div>
        <div className="rectangle-9" />
        <div className="rectangle-10" />
      </div>
    </div>
  );
};


export default HeatTreatment