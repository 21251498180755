import React from "react";
import Backgr from '../imagesPlasma/rectangle-13.svg'
import LogoLight from '../imagesPlasma/2.svg'
import LogoDark from '../imagesPlasma/3.svg'
import Menu from '../imagesPlasma/2x.png'
import Polosa from '../imagesPlasma/rectangle-88.svg'
import Rezka from '../imagesPlasma/plazmennaya-rezka-metalla-123-1.png'
import Line15 from '../imagesPlasma/line-15.svg'
import Line16 from '../imagesPlasma/line-16.svg'
 
import { DivWrapper } from "../components/DivWrapper4";
import { ImgWrapper } from "../components/ImgWrapper4";
import "./plasma.css";
import Footer from "./Footer";
import {Header} from './Header/Header'
import { useTranslation } from 'react-i18next'


const PlasmaCutting = () => {
  const {t} = useTranslation()
    return(
    <div className="plasma">
      <div className="div">
        <div className="div-2">
          <p className="element">
            {/* ТОВ «ЛВК «ПРОМГРУП» пропонує послуги з плазмового різання металу на верстаті плазмового різання з ЧПК, як
            для серійного виробництва , так і для одиничних виробів. */}
            {t("LLC FPC PROMGRUPP")}
            <br />
            {/* Лазерне різання – це економічно ефективний метод виробництва деталей из листового металу. Без необхідності в
            оснастці він забезпечує швидке виробництво деталей відповідно до креслень з високою точністю (до 0,5 мм). */}
            {t("laser cutting")}
          </p>
          <div className="div-2">
            <div className="overlap">
              <img className="rectangle" alt="Rectangle" src={Backgr}/>
              <div className="text-wrapper-2">
                {/* Плазмове */}
                {t("Plasma cutting")}
                <br />
                {/* різання */}
                {t("cutting")}
              </div>
              <div className="rectangle-2" />
              <div className="text-wrapper-3">
                {t("LLC FPC PROMGROUP PRODUCTS")}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;х&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("SERVICESS")}
              </div>
              <div className="text-wrapper-4">{t("SERVICESS")}</div>
            </div>
            <div className="overlap-group">
              <p className="p">
                {/* плазмове різання вуглецевої сталі товщиною від 0,5 до 32 мм; */}
                {t("Plasma cutting of")}
                <br />
                {/* плазмове різання нержавіючої сталі товщиною від 0,5 до 32 мм; */}
                {t("Plasma cutting of2")}
                <br />
                {/* газову різку вуглецевої сталі товщиною від 32 мм до 50 мм. */}
                {t("Plasma cutting of3")}
                <br />
                {/* Максимальний розмір листа, що оброблюється – 2000х6000 мм. */}
                {t("Plasma cutting of4")}
              </p>
              <div className="text-wrapper-5">{t("We perform")}</div>
            </div>
          </div>
          <DivWrapper className="view" imgWrapperImg={LogoLight} />
          {/* <img className="image" alt="Image" src={Menu} /> */}
          <Header cls='wrp2' divClassName='light'/>
        </div>
        <p className="element-2">
          <span className="span">
            {t("The estimated cost")}
            <br />
            {/* Для точного прорахування вартості Ваших виробів просимо зв’язатись з нами. */}
            {t("The estimated cos")}
            <br />
            <br />
            {/* Точність деталей після порізки по 14 квалітету без врахування конусності різу. */}
            {t("The precision of the cut")}
            <br />
          </span>
          <span className="text-wrapper-7">{t("Minimal")}</span>
        </p>
        <div className="overlap-group-wrapper">
          <div className="overlap-group-2">
            <img className="img" alt="Rectangle" src={Polosa} />
            <p className="promgrupp-pc-gmail">
              {/* Вартість різання нержавіючої сталі, а також газової різки вираховується індивідуально по заявці, яку ви
              можете відправити нам на електронну адресу  */}
              {t("The cost of")}
              <br />
              promgrupp.pc@gmail.com.
            </p>
            <div className="text-wrapper-8">{t("IMPORTANT")}</div>
          </div>
        </div>
        <img className="plazmennaya-rezka" alt="Plazmennaya rezka" src={Rezka} />
        <div className="view-5">
          <div className="overlap-2">
            <div className="text-wrapper-9">
              {t("Сutting")}
              <br />
              {t("method")}
            </div>
          </div>
          <div className="overlap-3">
            <div className="text-wrapper-10">{t("Plasma cutting")}</div>
          </div>
          <div className="overlap-4">
            <div className="text-wrapper-11">{t("Gas flame cutting")}</div>
          </div>
          <div className="overlap-5">
            <div className="text-wrapper-12">
              {t("Sheet")}
              <br />
              {t("thickness mm")}
            </div>
          </div>
          <div className="overlap-6">
            <div className="text-wrapper-12">
              {t("Cutting cost")}
              <br />
              {t("UAH per meter")}
            </div>
          </div>
          <div className="overlap-7">
            <div className="text-wrapper-13">
              {t("Punching cost")}
              <br />
              {t("UAH per meter")}
            </div>
          </div>
          <div className="overlap-8">
            <div className="rectangle-3" />
            <div className="rectangle-4" />
            <div className="rectangle-5" />
            <div className="rectangle-6" />
            <div className="rectangle-7" />
            <div className="rectangle-8" />
            <div className="rectangle-9" />
            <div className="rectangle-10" />
            <div className="rectangle-11" />
            <div className="rectangle-12" />
            <div className="rectangle-13" />
            <div className="rectangle-14" />
            <div className="rectangle-15" />
            <div className="rectangle-16" />
            <div className="element-3">
              <br />1<br />2<br />3<br />4<br />5<br />6<br />8<br />
              10
              <br />
              12
              <br />
              14
              <br />
              16
              <br />
              18
              <br />
              20
              <br />
              22
            </div>
          </div>
          <div className="overlap-9">
            <div className="rectangle-17" />
            <div className="rectangle-18" />
            <div className="rectangle-19" />
            <div className="rectangle-20" />
            <div className="rectangle-21" />
            <div className="rectangle-22" />
            <div className="rectangle-23" />
            <div className="rectangle-24" />
            <div className="rectangle-25" />
            <div className="rectangle-26" />
            <div className="rectangle-27" />
            <div className="rectangle-28" />
            <div className="rectangle-29" />
            <div className="rectangle-30" />
            <div className="element-4">
              12,00
              <br />
              13,00
              <br />
              14,00
              <br />
              15,00
              <br />
              16,00
              <br />
              18,00
              <br />
              20,00
              <br />
              23,00
              <br />
              27,00
              <br />
              30,00
              <br />
              34,00
              <br />
              38,00
              <br />
              45,00
              <br />
              72,00
            </div>
          </div>
          <div className="overlap-10">
            <div className="rectangle-17" />
            <div className="rectangle-18" />
            <div className="rectangle-19" />
            <div className="rectangle-20" />
            <div className="rectangle-21" />
            <div className="rectangle-22" />
            <div className="element-5">
              85,00
              <br />
              100,00
              <br />
              105,00
              <br />
              120,00
              <br />
              135,00
              <br />
              165,00
            </div>
          </div>
          <div className="overlap-11">
            <div className="rectangle-31" />
            <div className="rectangle-32" />
            <div className="rectangle-33" />
            <div className="rectangle-34" />
            <div className="rectangle-35" />
            <div className="rectangle-36" />
            <div className="rectangle-37" />
            <div className="rectangle-38" />
            <div className="rectangle-39" />
            <div className="rectangle-40" />
            <div className="rectangle-41" />
            <div className="rectangle-42" />
            <div className="rectangle-43" />
            <div className="rectangle-44" />
            <div className="element-6">
              1,50
              <br />
              2,00
              <br />
              2,00
              <br />
              2,50
              <br />
              3,00
              <br />
              3,50
              <br />
              4,00
              <br />
              4,00
              <br />
              6,00
              <br />
              8,00
              <br />
              10,00
              <br />
              12,00
              <br />
              15,00
              <br />
              30,00
            </div>
          </div>
          <div className="overlap-12">
            <div className="rectangle-31" />
            <div className="rectangle-32" />
            <div className="rectangle-33" />
            <div className="rectangle-34" />
            <div className="rectangle-35" />
            <div className="rectangle-36" />
            <div className="element-7">
              30,95
              <br />
              37,85
              <br />
              49,40
              <br />
              62,40
              <br />
              90,90
              <br />
              113,10
            </div>
          </div>
          <div className="overlap-13">
            <div className="rectangle-3" />
            <div className="rectangle-4" />
            <div className="rectangle-5" />
            <div className="rectangle-6" />
            <div className="rectangle-7" />
            <div className="rectangle-8" />
            <div className="element-8">
              25
              <br />
              30
              <br />
              32
              <br />
              36
              <br />
              40
              <br />
              50
            </div>
          </div>
        </div>
        <div className="overlap-wrapper">
         
        <Footer/>
        </div>
        <div className="text-wrapper-19">{t("Price for cutting services")}</div>
      </div>
    </div>

    )
}

export default PlasmaCutting